import React from 'react'
import { TextField, FormControl, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './style'
import classNames from 'classnames'
import NumberFormat from 'react-number-format'

function NumberFormatCustom (props) {
  const { inputRef, onChange, defaultValue, ...other } = props

  return (
    <NumberFormat
      {...other}
      defaultValue={defaultValue}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      isNumericString
      prefix=''
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}
const useStyles = makeStyles(styles)

const NumberInput = ({
  name,
  labelText,
  value,
  top,
  success,
  defaultValue,
  error,
  onChange,
  ...props
}) => {
  const classes = useStyles()

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  })

  return (
    <FormControl
      className={`${classes.formControl}  ${top ? classes.marginTop : ''}`}
      fullWidth
    >
      <TextField
        label={labelText}
        type='text'
        fullWidth
        classes={{
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        name={name}
        {...props}
        InputProps={{
          inputComponent: NumberFormatCustom
        }}
      />
    </FormControl>
  )
}

export default NumberInput
