import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import { Style } from '../style'
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core'
import { useToasts } from 'react-toast-notifications'
import ProyectoService from '../../../services/proyecto'
import CustomInput from 'components/CustomInput'
import Buscar from 'assets/img/buscar.svg'
import Add from 'assets/img/add.svg'
import CatalogoService from 'services/catalogos/index'
import { Edit } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import {
  Backdrop,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core'
import userAccess from 'userAccess'
import withAccessGranted from 'components/withAuth/index'
import moment from 'moment'
const useStyles = makeStyles(Style)

const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const ProyectoList = ({ history, user, token }) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [tipoProyecto, setTipoProyecto] = useState([])
  const [proyectos, setProyectos] = useState([])
  const [paises, setPaises] = useState([])
  const [search, setSearch] = useState('')
  const [pais, setPais] = useState(0)
  const [tipo, setTipo] = useState(0)
  const [loading, setLoading] = useState(true)

  const handleCreate = () => {
    history.push({
      pathname: '/form-proyecto',
      state: { id: 0 }
    })
  }

  const handleSearch = async (tipo, pais) => {
    setLoading(true)
    try {
      let query = `nombre=${search}`
      if (pais !== 0) {
        query += `&pais=${pais}`
      }
      if (tipo !== 0) {
        query += `&tipo_proyecto=${tipo}`
      }
      const resp = await ProyectoService.search(query, token)
      if (resp.status === 200) {
        setProyectos(resp.data)
        setLoading(false)
      } else {
        setProyectos([])
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const handleTipo = (val) => {
    setTipo(val)
    handleSearch(val, pais)
  }

  const handlePais = (val) => {
    setPais(val)
    handleSearch(tipo, val)
  }

  useEffect(async () => {
    try {
      const respTipoProyecto = await CatalogoService.getTipoProyecto(token)
      if (respTipoProyecto.status === 200) {
        const data = respTipoProyecto.data.map((item) => {
          return { label: item.nombre, value: item.id }
        })
        setTipoProyecto(data)
      }

      const respPais = await CatalogoService.getPaises()
      if (respPais.status === 200) {
        const data = respPais.data.map((item) => {
          return { label: item.nombre, value: item.id }
        })
        setPaises(data)
      }
      const resp = await ProyectoService.getAll(token)

      if (resp.status === 200) {
        setProyectos(resp.data)
      } else {
        setProyectos([])
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }, [])

  const handleEdit = (id) => {
    history.push({
      pathname: '/form-proyecto',
      state: { id: id }
    })
  }

  return (
    <GridContainer>
      <Backdrop className={load.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridItem xs={12} sm={12} md={12}>
        <CardHeader color="success">
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <h4>Tipo de proyecto</h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="pais_label">Tipo de Proyecto</InputLabel>
                <Select
                  floatingLabelStyle={{ color: '#fff' }} // <--
                  labelId="pais_label"
                  value={tipo}
                  label="Tipo de Proyecto"
                  onChange={(e) => handleTipo(e.target.value)}
                >
                  <MenuItem value={0}>Todos</MenuItem>
                  {tipoProyecto.map((item, i) => (
                    <MenuItem
                      key={`id_tipo_${i.toString()}`}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="pais_label">País</InputLabel>
                <Select
                  labelId="pais_label"
                  value={pais}
                  label="Tipo de Proyecto"
                  onChange={(e) => handlePais(e.target.value)}
                >
                  <MenuItem value={0}>Todos</MenuItem>

                  {paises.map((item, i) => (
                    <MenuItem
                      key={`id_tipo_${i.toString()}`}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </CardHeader>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Buscar"
                  value={search}
                  top
                  onChange={(e) => setSearch(e.target.value)}
                  labelText="Buscador de proyectos"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={1}>
                <a onClick={() => handleSearch()} style={{ cursor: 'pointer' }}>
                  <img
                    src={Buscar}
                    alt="Buscar"
                    style={{ marginTop: '15px' }}
                  />
                </a>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Button color="transparent" onClick={() => handleCreate()}>
                  <img src={Add} alt="Nuevo" />
                  <div>Nuevo proyecto</div>
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell></TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Nombre del proyecto
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    ID del proyecto
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Año de Inicio
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Monto del préstamo
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Etapa Actual
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Último Registro
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {proyectos.map((p, key) => (
                  <TableRow
                    key={`key_table${key.toString()}`}
                    className={classes.tableBodyRow}
                  >
                    <TableCell className={classes.tableCell}>
                      <IconButton
                        size="small"
                        type="button"
                        color="default"
                        onClick={() => handleEdit(p.id)}
                      >
                        <Edit style={{ fill: '#15bd85' }} />
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {p.nombre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {p.codigo}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {p.anio}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {parseFloat(p.monto.toFixed(2)).toLocaleString('en')} (
                      {p.moneda.codigo})
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {p.estapa_actual.nombre}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {p.ultimo_registro
                        ? moment(p.ultimo_registro).format('DD/MM/YYYY')
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
export default withAccessGranted(ProyectoList, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
