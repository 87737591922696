import API from './../Api'

class ProyectoService {
  static getAll(token) {
    return API({
      method: 'GET',
      url: '/api/v1/proyecto/proyecto/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static getAnioProyectos(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/anios_registro/?proyecto=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static proyectoRegistro(id, fase, token) {
    return API({
      method: 'GET',
      url: `/api/v1/proyecto/proyecto_registro/${id}/?fase=${fase}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static getProyecto(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/proyecto/proyecto/${id}/`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static search(query, token) {
    return API({
      method: 'GET',
      url: `/api/v1/proyecto/proyecto/?${query}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static save(data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/proyecto/proyecto/',
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static listRegistro(fase, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/registro/registro/?fase=${fase}&anio=${anio}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static openInvitation(token) {
    return API({
      method: 'GET',
      url: `/api/v1/registro/invitation_open?token=${token}`
    })
  }

  static saveInvitation(data, token) {
    return API({
      method: 'PUT',
      url: `/api/v1/registro/invitation_open/${token}/`,
      data: data
    })
  }

  static sendInvitation(data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/registro/invitation/',
      data: data,
      headers: {
        Authorization: 'Bearer ' + token
      },  
    })
  }

  static saveRegistro(data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/registro/registro/',
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static updateRegistro(id, data, token) {
    return API({
      method: 'PUT',
      url: `/api/v1/registro/registro/${id}/`,
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static edit(id, data, token) {
    return API({
      method: 'PUT',
      url: `/api/v1/proyecto/proyecto/${id}/`,
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static template(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/proyecto/template/?tipo_proyecto=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static nuevoAnio(fase, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/registro/nuevo_anio/?fase=${fase}&anio=${anio}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static deleteRegistro(id, token) {
    return API({
      method: 'DELETE',
      url: `/api/v1/registro/registro/${id}/`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static registroHistorico(fase, token) {
    return API({
      method: 'GET',
      url: `/api/v1/registro/historico/?fase=${fase}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static getProyectoReferencia(fase, token) {
    return API({
      method: 'GET',
      url: `api/v1/proyecto/proyecto_referencia/${fase}/`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static upload(data, token) {
    return API({
      method: 'POST',
      url: `api/v1/registro/upload/`,
      data: data,
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default ProyectoService
