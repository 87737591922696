import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { TextField } from '@material-ui/core'
import styles from './style'

const useStyles = makeStyles(styles)

const CustomInput = ({
  formControlProps,
  labelText,
  id,
  labelProps,
  inputProps,
  top,
  error,
  value,
  defaultValue,
  onChange,
  success,
  required,
  ...props
}) => {
  const classes = useStyles()
  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  })
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  })
  const marginTop = classNames({
    [classes.marginTop]: top === undefined
  })
  return (
    <FormControl
      {...formControlProps}
      className={classes.formControl + ' ' + marginTop}
    >
      <TextField
        value={value}
        label={labelText}
        onChange={onChange}
        required={required}
        defaultValue={defaultValue}
        classes={{
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        {...props}
        {...inputProps}
      />
    </FormControl>
  )
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  top: PropTypes.bool,
  success: PropTypes.bool,
  defaultValue: PropTypes.string
}

export default CustomInput
