import React, { useState, useEffect } from 'react'
import CardHeader from 'components/Card/Header/index'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'

import style from './style'
Highcharts.setOptions({
  chart: {
    backgroundColor: {
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(255, 255, 255)']
      ]
    },
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    showTable: false,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG'
  }
})

const useStyles = makeStyles(style)

const Etapas = ({ data, title, datosinfo, general }) => {
  const classes = useStyles()
  const [planeacion, setPlaneacion] = useState(0)
  const [implementacion, setImplementacion] = useState(0)
  const [planeacionOptions, setPlaneacionOptions] = useState({})
  const [implementacionOptions, setImplementacionOptions] = useState({})
  const [porc, setPorc] = useState(0)
  useEffect(() => {
    const { anio } = datosinfo
    let porc =
      (((general.referencia - general.proyecto) / anio) * 100) /
      (general.referencia / anio)
    if (general.referencia - general.proyecto > 0) {
      porc *= -1
    }
    setPorc(porc)
    const { referencia, proyecto } = data
    const plane =
      parseFloat(referencia.construccion) + parseFloat(referencia.operativa)
    setPlaneacion(plane)
    const imp =
      parseFloat(proyecto.operativa) + parseFloat(proyecto.construccion)
    setImplementacion(imp)
    const plan = {
      chart: {
        type: 'column'
      },
      title: {
        text: `${title}: Situación de Referencia`
      },
      subtitle: {
        text: `Situación de Referencia: (tCO2e)`
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      xAxis: {
        categories: ['Construcción', 'Operativa']
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },
      yAxis: {
        title: {
          text: 'tCO2e'
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value)
          }
        }
      },
      series: [
        {
          name: 'tCO2e',
          color: '#A3A3A3',
          data: [
            parseFloat(referencia?.construccion.toFixed(2)),
            parseFloat(referencia?.operativa.toFixed(2))
          ]
        }
      ]
    }
    setPlaneacionOptions(plan)
    const implementacionOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: `${title}: Proyecto`
      },
      subtitle: {
        text: `Proyecto (tCO2e)`
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      xAxis: {
        categories: ['Construcción', 'Operativa']
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },
      yAxis: {
        title: {
          text: 'tCO2e'
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value)
          }
        }
      },
      series: [
        {
          name: 'tCO2e',
          color: '#A3A3A3',
          data: [
            parseFloat(proyecto.construccion.toFixed(2)),
            parseFloat(proyecto.operativa.toFixed(2))
          ]
        }
      ]
    }
    setImplementacionOptions(implementacionOptions)
  }, [data, general])
  const { diferencia, proyecto, referencia } = general
  const { anio } = datosinfo
  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                  E) Emisiones por Etapa (Situación de Referencia / Proyecto) -
                  tCO<small>2e</small>
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se muestran las emisiones de GEI por alcance I, II y III,  comparando las fases de construcción y de operaciones del proyecto en la situación de referencia y un año supuesto de operaciones. Las fases de construcción únicamente se verán reflejadas para las consultas en los primeros años de actividad del proyecto."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ minHeight: '200px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={planeacionOptions}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ minHeight: '200px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={implementacionOptions}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.info}>
                  a) Situación de Referencia:{' '}
                  <span>
                    {parseFloat(planeacion.toFixed(2)).toLocaleString('en')} tCO
                    <small>2e</small>
                  </span>
                </div>
                <div className={classes.info}>
                  b) Emisiones del Proyecto:{' '}
                  <span>
                    {parseFloat(implementacion.toFixed(2)).toLocaleString('en')}{' '}
                    tCO<small>2e</small>
                  </span>
                </div>
                <div className={classes.info}>
                  c) Situacion de Referencia ({anio} años):{' '}
                  <span>
                    {referencia
                      ? parseFloat(referencia.toFixed(2)).toLocaleString('en')
                      : 0}{' '}
                    tCO<small>2e</small>
                  </span>
                </div>
                <div className={classes.info}>
                  d) Emisiones del Proyecto ({anio} años):{' '}
                  <span>
                    {proyecto
                      ? parseFloat(proyecto.toFixed(2)).toLocaleString('en')
                      : 0}{' '}
                    tCO<small>2e</small>{' '}
                  </span>
                </div>

                <div className={classes.info}>
                  e) Diferencia ({anio} años) :{' '}
                  <span className={classes.green}>
                    {diferencia
                      ? parseFloat(diferencia.toFixed(2)).toLocaleString('en')
                      : 0}{' '}
                    tCO<small>2e</small>
                  </span>
                </div>
                <div className={classes.info}>
                  f) Diferencia anual :{' '}
                  <span className={classes.green}>
                    {parseFloat((diferencia / anio).toFixed(2)).toLocaleString(
                      'en'
                    )}{' '}
                    tCO<small>2e</small> ({porc.toFixed(2)} %)
                  </span>
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default Etapas
