import React from 'react'
import styles from 'assets/jss/components/typographyStyle.js'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(styles)



const Error = ({ children }) => {
  const classes = useStyles()
  return <span className={classes.dangerText}>{children}</span>
}

export default Error
