import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Error from 'components/Error'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import { useToasts } from 'react-toast-notifications'
import { Backdrop, CircularProgress } from '@material-ui/core'
import AuthService from 'services/auth/index'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const LoginAdmin = () => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [hasError, setHasError] = useState(false)

  const [errors, setErrors] = useState({
    email: '',
    password: ''
  })
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    if (!email) {
      setErrors({
        email: 'Usuario es requerido'
      })
      setHasError(true)
      return
    } else {
      setHasError(false)
    }
    if (!password) {
      setErrors({
        password: 'Contraseña es requerida'
      })
      setHasError(true)
      return
    } else {
      setHasError(false)
    }

    if (hasError) {
      return
    }
    try {
      setLoading(true)
      const data = {
        username: email,
        password: password
      }
      const user = await AuthService.login(data)
      if (user.status === 201) {
        localStorage.setItem('user', JSON.stringify(user.data))
        setTimeout(function () {
          window.location = '/dashboard'
          setLoading(false)
        }, 2000)
      }
    } catch (e) {
      setLoading(false)
      try {
        addToast(e.response.data.error, { appearance: 'error' })
      } catch (e) {
        addToast('Error en el proceso', { appearance: 'error' })
      }
    }
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={4} style={{ margin: 'auto' }}>
          <Card className={classes.login}>
            <CardHeader />
            <CardHeader color="primary">
              <h4>Iniciar sesión</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Usuario"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  {hasError && errors.email && <Error>{errors.email}</Error>}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Contraseña"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  {hasError && errors.password && (
                    <Error>{errors.password}</Error>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="button" onClick={() => onSubmit()} color="success">
                Ingresar
              </Button>
            </CardFooter>
            <CardFooter />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default LoginAdmin
