import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import AdminNavbarLinks from 'components/Header/NavbarLinks.js'
import styles from './style'

const useStyles = makeStyles(styles)

const Menu = ({
  color,
  location,
  history,
  logo,
  rtlActive,
  handleDrawerToggle,
  routes,
  open
}) => {
  const classes = useStyles()
  const [user, setUser] = useState({})
  const [menuSelected, setMenuSelected] = useState({ base: '' })

  useEffect(() => {
    const { pathname } = location
    const men = routes.find((route) => {
      return pathname === route.path
    })
    setMenuSelected(men)
    const data = window.localStorage.getItem('user')
    if (data) {
      const dat = JSON.parse(data)
      const { user } = dat
      setUser(user)
    }
  }, [location])

  const handleCreate = () => {
    history.push({
      pathname: '/form-proyecto',
      state: { id: 0 }
    })
  }

  function activeRoute(routeName) {
    return menuSelected.base === routeName ? true : false
  }

  const getType = (tipo) => {
    let type = 'Administrador'
    if (tipo === 2) {
      type = 'Administrador Usuarios'
    } else if (tipo === 3) {
      type = 'Registro'
    } else if (tipo === 4) {
      type = 'Consulta'
    }
    return type
  }

  var linksMobile = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var listItemClasses = classNames({
          [' ' + classes[color]]: activeRoute(prop.base)
        })
        return (
          <>
            {menuSelected && (
              <span key={`menu_${key.toString()}`}>
                {prop.menu && (
                  <NavLink to={prop.path} activeClassName="active">
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      <ListItemText
                        primary={prop.name}
                        className={classNames(classes.itemText)}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                )}
              </span>
            )}
          </>
        )
      })}
    </List>
  )

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var listItemClasses = classNames({
          [' ' + classes[color]]: activeRoute(prop.base)
        })
        return (
          <>
            {menuSelected && prop.rol.indexOf(user.tipo_usuario) !== -1 && (
              <span key={`menu_${key.toString()}`}>
                {prop.menu && prop.type === menuSelected.type && (
                  <NavLink to={prop.path} activeClassName="active">
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      <ListItemText
                        primary={prop.name}
                        className={classNames(classes.itemText)}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                )}
              </span>
            )}
          </>
        )
      })}
    </List>
  )
  var brand = (
    <NavLink to="/dashboard" activeClassName="active">
      <div className={classes.logo}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </div>
    </NavLink>
  )
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={rtlActive ? 'left' : 'right'}
          open={open}
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {linksMobile}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={rtlActive ? 'right' : 'left'}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <List className={classes.list}>
              <ListItem button className={classes.itemLink}>
                <ListItemText
                  primary="Nombre de usuario:"
                  className={classNames(classes.itemText)}
                  disableTypography={true}
                />
              </ListItem>
              <div
                style={{
                  width: 'calc(100% - 30px)',
                  margin: 'auto',
                  height: '1px',
                  backgroundColor: '#fff'
                }}
              />
              <ListItem button className={classes.itemLink}>
                <NavLink to="/profile" activeClassName="active">
                  <ListItemText
                    primary={`> ${user.name}`}
                    classes={classNames(classes.itemText)}
                    disableTypography={true}
                  />
                </NavLink>
              </ListItem>
            </List>
            <List className={classes.list}>
              <ListItem
                button
                className={classes.itemLink}
                style={{ cursor: 'text' }}
              >
                <ListItemText
                  primary="Tipo de usuario:"
                  className={classNames(classes.itemText)}
                  disableTypography={true}
                />
              </ListItem>
              <div
                style={{
                  width: 'calc(100% - 30px)',
                  margin: 'auto',
                  height: '1px',
                  backgroundColor: '#fff'
                }}
              />
              <ListItem button className={classes.itemLink}>
                <a activeClassName="active">
                  <ListItemText
                    style={{ cursor: 'text' }}
                    primary={`> ${getType(user.tipo_usuario)}`}
                    classes={classNames(classes.itemText)}
                    disableTypography={true}
                  />
                </a>
              </ListItem>
            </List>
            <List className={classes.list}>
              {menuSelected && (
                <ListItem
                  button
                  style={{ cursor: 'text' }}
                  className={classes.itemLink}
                >
                  <ListItemText
                    primary={menuSelected.nameBase}
                    className={classNames(classes.itemText)}
                    disableTypography={true}
                  />
                </ListItem>
              )}
              <div
                style={{
                  width: 'calc(100% - 30px)',
                  margin: 'auto',
                  height: '1px',
                  backgroundColor: '#fff'
                }}
              />
            </List>
            <List className={classes.list}>{links}</List>
            {user.tipo_usuario !== 2 &&
              user.tipo_usuario !== 4 &&
              menuSelected &&
              menuSelected.type === 'dash' && (
                <List className={classes.list}>
                  <NavLink to="lista-registro" activeClassName="active">
                    <ListItem button className={classes.itemLink}>
                      <ListItemText
                        primary="+ Gestión de proyectos"
                        className={classNames(classes.itemText)}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                </List>
              )}
            {user.tipo_usuario !== 2 &&
              user.tipo_usuario !== 4 &&
              menuSelected &&
              menuSelected.type === 'dash' && (
                <List className={classes.list}>
                  <NavLink to="form-proyecto" activeClassName="active">
                    <ListItem button className={classes.itemLink}>
                      <ListItemText
                        primary="+ Nuevo proyecto"
                        className={classNames(classes.itemText)}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                </List>
              )}
            {menuSelected && menuSelected.type === 'reg' && (
              <List className={classes.list}>
                <ListItem
                  button
                  className={classes.itemLink}
                  onClick={handleCreate}
                >
                  <ListItemText
                    primary="+ Nuevo proyecto"
                    className={classNames(classes.itemText)}
                    disableTypography={true}
                  />
                </ListItem>
                <div
                  style={{
                    width: 'calc(100% - 30px)',
                    margin: 'auto',
                    height: '1px',
                    backgroundColor: '#fff'
                  }}
                />
              </List>
            )}
          </div>
        </Drawer>
      </Hidden>
    </div>
  )
}

Menu.propTypes = {
  handleDrawerToggle: PropTypes.func,
  logo: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  color: PropTypes.string,
  rtlActive: PropTypes.bool
}

export default Menu
