import React, { useState } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Hidden from '@material-ui/core/Hidden'
import Poppers from '@material-ui/core/Popper'
import Divider from '@material-ui/core/Divider'
import Person from '@material-ui/icons/Person'
import Button from 'components/CustomButtons'

import styles from 'assets/jss/components/headerLinksStyle.js'

const useStyles = makeStyles(styles)
import AuthService from 'services/auth'

export default function AdminNavbarLinks () {
  const classes = useStyles()
  const [openProfile, setOpenProfile] = useState(null)
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }
  const handleCloseProfile = () => {
    setOpenProfile(null)
  }

  const handleProfile = () => {
    window.location.href = '/profile'
  }
  const handleLogaut = async () => {
    setOpenProfile(null)
    const data = await JSON.parse(localStorage.getItem('user'))
    localStorage.setItem('user', '')
    localStorage.removeItem('user')
    window.location = '/logout'
    try {
      const response = AuthService.logaut(data.token)
      const content = await response
      if (content.status === 200) {
        localStorage.setItem('user', '')
        window.location.reload()
      }
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? 'profile-menu-list-grow' : null}
          aria-haspopup='true'
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation='css'>
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            ' ' +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='profile-menu-list-grow'
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role='menu'>
                    <MenuItem
                      onClick={handleProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                 
                    <Divider light />
                    <MenuItem
                      onClick={handleLogaut}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  )
}
