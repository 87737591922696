import API from './../Api'

class HuellaService {
  static getHuella (id, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/huella/?proyecto=${id}&anio=${anio}&token=${token}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static getHuellaReferencia (id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/huellareferencia/?proyecto=${id}&token=${token}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }


  static getReferencia (id, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/referencia/?proyecto=${id}&anio=${anio}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static getAnios (id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/anios/?proyecto=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export default HuellaService
