const userAccess = {
    ADMIN: {
        NAME: 'Administrador',
        ACCESS_LEVEL: 1,
    },
    ADMINUSER: {
        NAME: 'Administrador Usuarios',
        ACCESS_LEVEL: 2,
    },
    REGISTRO: {
        NAME: 'Registro',
        ACCESS_LEVEL: 3,
    },
    CONSULTA: {
        NAME: 'Consulta',
        ACCESS_LEVEL: 4,
    },
}

Object.freeze(userAccess)

export default userAccess
