/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import { useToasts } from 'react-toast-notifications'
import UserService from 'services/user'
import { Edit, Delete } from '@material-ui/icons'
import Button from 'components/CustomButtons'
import Add from 'assets/img/add.svg'
import Buscar from 'assets/img/buscar.svg'
import CustomInput from 'components/CustomInput'

import {
  Backdrop,
  CircularProgress,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Link,
  DialogActions,
  Slide
} from '@material-ui/core'

import Table from '@material-ui/core/Table'

import {
  Dialog,
  TableCell,
  TableBody,
  TableRow,
  TableHead
} from '@material-ui/core/index'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import { Description } from '@material-ui/icons/index'

const styles = {
  table: {
    marginBottom: '0',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  }
}
const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(styles)

const UserList = ({ history, token }) => {
  const classes = useStyles()

  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [usaurios, setUsuarios] = useState([])
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const handleEdit = (id) => {
    history.push({
      pathname: '/user',
      state: { id: id }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCreate = () => {
    history.push(`user`)
  }

  useEffect(async () => {
    try {
      const resp = await UserService.getAll(token)
      if (resp.status === 200) {
        setUsuarios(resp.data)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  const handleDelete = async (p) => {
    try {
      const resp = await UserService.delete(p.id, token)
      handleClose()
      if (resp.status === 200) {
        addToast('Usuario eliminado', {
          appearance: 'success',
          autoDismiss: true
        })
        setUsuarios(usaurios.filter((u) => u.id !== p.id))
      } else {
        addToast('Error al eliminar el usuario', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    } catch (e) {
      handleClose()
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }
  const handleSearch = async () => {
    try {
      setLoading(true)
      const resp = await UserService.search(search, token)
      setLoading(false)

      if (resp.status === 200) {
        setUsuarios(resp.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleDownload = async () => {
    window.open(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/usuario/reporte/?token=${token}`
    )
  }

  return (
    <div>
      <Backdrop className={load.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Card>
        <CardHeader color="success">
          <div>
            <h4>Usuarios</h4>
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={8} sm={8} md={8}>
              <CustomInput
                labelText="Buscar"
                value={search}
                top
                onChange={(e) => setSearch(e.target.value)}
                labelText="Buscador de usuarios"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem md={1}>
              <Link
                onClick={() => handleSearch()}
                style={{ cursor: 'pointer' }}
              >
                <img src={Buscar} alt="Buscar" style={{ marginTop: '15px' }} />
              </Link>
            </GridItem>
            <GridItem md={2}>
              <div style={{ marginTop: '25px' }}>
                <Link
                  onClick={() => handleDownload()}
                  variant="contained"
                  style={{ paddingTop: '10px', cursor: 'pointer' }}
                >
                  <Description />{' '}
                  <span style={{ position: 'absolute', marginTop: '-2px' }}>
                    {' '}
                    Descargar log de usuarios
                  </span>
                </Link>
              </div>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Button color="transparent" onClick={() => handleCreate()}>
                <img src={Add} alt="Nuevo" />
                <div>Nuevo Usuario</div>
              </Button>
            </GridItem>
          </GridContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell
                  colspan="2"
                  className={classes.tableCell + ' ' + classes.tableHeadCell}
                >
                  Nombre
                </TableCell>
                <TableCell
                  className={classes.tableCell + ' ' + classes.tableHeadCell}
                >
                  Usuario
                </TableCell>
                <TableCell
                  className={classes.tableCell + ' ' + classes.tableHeadCell}
                >
                  Tipo de usuario
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {usaurios.map((p, key) => (
                <TableRow
                  key={`key_table${key.toString()}`}
                  className={classes.tableBodyRow}
                >
                  <TableCell className={classes.tableCell}>
                    <IconButton
                      size="small"
                      type="button"
                      color="default"
                      onClick={() => handleEdit(p.id)}
                    >
                      <Edit style={{ fill: '#15bd85' }} />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.tableCell}>{p.name}</TableCell>
                  <TableCell className={classes.tableCell}>{p.email}</TableCell>

                  <TableCell className={classes.tableCell}>
                    {p.tipo_usuario && <span>{p.tipo_usuario.nombre}</span>}
                  </TableCell>
                  <TableCell className={classes.tableCell}></TableCell>

                  <TableCell className={classes.tableCell}>
                    <IconButton
                      size="small"
                      type="button"
                      onClick={() => setOpen(true)}
                      color="default"
                    >
                      <Delete />
                    </IconButton>

                    <Dialog
                      open={open}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        Elimiar Usuario
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          Desea elimiar el usuario {p.name}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="primary">
                          Cancelar
                        </Button>
                        <Button onClick={() => handleDelete(p)} color="success">
                          Aceptar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

export default withAccessGranted(UserList, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.ADMINUSER.ACCESS_LEVEL
])
