import API from '../Api'

class UserService {
  static create(data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/usuario/user/',
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static edit(id, data, token) {
    return API({
      method: 'put',
      url: '/api/v1/usuario/user/' + id + '/',
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static getAll(token) {
    return API({
      method: 'GET',
      url: '/api/v1/usuario/user/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static getById(id, token) {
    return API({
      method: 'GET',
      url: `api/v1/usuario/user/${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static delete(id, token) {
    return API({
      method: 'DELETE',
      url: `/api/v1/usuario/user/${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static tipoUsuario(token) {
    return API({
      method: 'GET',
      url: '/api/v1/usuario/tipo_usuario/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static search(search, token) {
    return API({
      method: 'GET',
      url: '/api/v1/usuario/user/?search=' + search,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static downloadLogById(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/usuario/reporte/?user=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static downloadLog(token) {
    return API({
      method: 'GET',
      url: `/api/v1/usuario/reporte/`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export default UserService
