import React, { useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import Buscar from 'assets/img/buscar.svg'
import CustomSelect from 'components/CustomSelect/index'
import CatalogoService from 'services/catalogos/index'
import FactorService from 'services/factor/index'
import ProyectoService from 'services/proyecto/index'
import {
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Backdrop,
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  FormControl,
  FormLabel,
  RadioGroup,
  Tooltip,
  Radio
} from '@material-ui/core'
import ListFuentes from 'components/ListFuentes'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import { useToasts } from 'react-toast-notifications'
import { IconButton } from '@material-ui/core/index'
import { Delete, Check } from '@material-ui/icons/index'
import CustomInput from 'components/CustomInput/index'

const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  table: {
    minWidth: 650
  }
}))

const GestionFuentes = ({ token }) => {
  const classes = useStyles()
  const { addToast } = useToasts()
  const [familiaID, setFamiliaID] = useState()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('factor')
  const [tipoProyecto, setTipoProyecto] = useState([])
  const [familia, setFamilia] = useState([])
  const [factor, setFactor] = useState([])
  const [factorAll, setFactorAll] = useState([])
  
  const [factorTiene, setFactorTiene] = useState([])
  const [ratiosTiene, setRatiosTiene] = useState([])
  const [ratios, setRatios] = useState([])
  const [categorias, setCategorias] = useState([])
  const [categoria, setCategoria] = useState(undefined)
  const [checkedB, setCheckedB] = useState(false)
  const [search, setSearch] = useState('')
  const [teplateProyecto, setTemplateProyecto] = useState(undefined)

  useEffect(async () => {
    const resulRipop = await CatalogoService.getTipoProyecto(token)
    if (resulRipop.status === 200) {
      const tp = resulRipop.data.map((item) => {
        return {
          value: item.id,
          label: item.nombre
        }
      })
      setTipoProyecto(tp)
    }

    const familiaResul = await CatalogoService.getFamilia(token)
    if (familiaResul.status === 200) {
      const cat = familiaResul.data.map((item) => {
        return {
          value: item.id,
          label: item.nombre
        }
      })
      setFamilia(cat)
    }
  }, [])

  const handleTipoProyecto = async (tipo) => {
    const proyectoTemp = await ProyectoService.template(tipo, token)
    if (proyectoTemp.status === 200) {
      // origen de emision
      const cat = proyectoTemp.data.origen_emision.map((item) => {
        return {
          value: item.id,
          label: item.nombre
        }
      })
      setCategorias(cat)
      setTemplateProyecto(proyectoTemp.data)
    }
  }

  const handleFamilia = async (id) => {
    setFamiliaID(id)
    if (!teplateProyecto) {
      addToast('Seleccione el tipo de proyecto', { appearance: 'error' })
      return
    }
    if (!categoria) {
      addToast('Seleccione la categoría', { appearance: 'error' })
      return
    }
    try {
      setLoading(true)
      const fuentesResult = await FactorService.getGestionFuente(
        token,
        teplateProyecto.tipo_proyecto.id,
        categoria,
        id,
        value,
        search
      )
      if (fuentesResult.status === 200) {
        if (value === 'factor') {
          setFactor(fuentesResult.data.results.no)
          setFactorAll(fuentesResult.data.results.no)
          setFactorTiene(fuentesResult.data.results.tiene)
        } else {
          setRatios(fuentesResult.data.results.no)
          setRatiosTiene(fuentesResult.data.results.tiene)
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      data = {
        tipo_proyecto: teplateProyecto.tipo_proyecto.id,
        categoria,
        id:data,
        tipo: value
      }
      const resul = await FactorService.saveGestionFuente(token, data)
      addToast('Operación realizada con exito', { appearance: 'success' })
      setLoading(false)
      handleFamilia(familiaID)
    } catch (e) {
      addToast('Error en el proceso', { appearance: 'error' })
      setLoading(false)
    }
  }

  const handleSearch = () => {
    
    handleFamilia(familiaID)
  }

  const deleteRegistro = async (id) => {
    
      const data = {
      tipo_proyecto: teplateProyecto.tipo_proyecto.id,
      categoria,
      tipo: value
    }
    setLoading(true)
    try {
    const resul = await FactorService.deleteFactorRatio(token, id,data)
    if (resul.status === 200) {
      addToast('Operación realizada con exito', { appearance: 'success' })
      handleFamilia(familiaID)
      setLoading(false)
    }
    } catch (e) {
      addToast('Error en el proceso', { appearance: 'error' })
      setLoading(false)
    }
  }

  const handleRadioChange = (value) => {
    setValue(value)
    if (familiaID) {
      handleFamilia(familiaID)
    }
  }

  const handleChange = (id) => {
    if (value === 'factor') {
      const fact = factor.map((item) => {
        if (item.id === id) {
          item.selected = !item.selected
        }
        return item
      })
      setFactor(fact)
    } else {
      const ratio = ratios.map((item) => {
        if (item.id === id) {
          item.selected = !item.selected
        }
        return item
      })
      setRatios(ratio)
    }
  }

  return (
    <div>
      <GridContainer>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} xl={12}>
                  <h4>
                    Configura las fuentes y ratios de GEI que va utilizar por
                    tipo de proyecto
                  </h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Tipo de proyecto"
                      data={tipoProyecto}
                      required
                      name="tipoProyecto"
                      onChange={(e) => handleTipoProyecto(e.target.value)}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Categoría"
                      data={categorias}
                      required
                      name="categoria"
                      onChange={(e) => setCategoria(e.target.value)}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </CardBody>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                {teplateProyecto ? (
                  <ListFuentes data={teplateProyecto.familia} />
                ) : (
                  <ListFuentes />
                )}
              </GridItem>
            </CardBody>
            <CardBody>
              <Card>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomSelect
                            labelText="Actividad Emisora"
                            required
                            top
                            name="tipoProyecto"
                            data={familia}
                            onChange={(e) => {
                              handleFamilia(e.target.value)
                            }}
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                          <FormControl
                            row
                            component="fieldset"
                            className={classes.formControl}
                          >
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup
                              row
                              fullWidth
                              aria-label="position"
                              name="position"
                              defaultValue="top"
                              value={value}
                              onChange={(e) =>
                                handleRadioChange(e.target.value)
                              }
                            >
                              <FormControlLabel
                                value="factor"
                                control={<Radio />}
                                label="Factor"
                              />
                              <FormControlLabel
                                value="ratio"
                                control={<Radio />}
                                label="Ratio"
                              />
                            </RadioGroup>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="Buscar"
                            value={search}
                            top
                            onChange={(e) => setSearch(e.target.value)}
                            labelText="Buscador de factor o ratio"
                            formControlProps={{
                              fullWidth: true
                            }}
                          />
                        </GridItem>
                        <GridItem md={1}>
                          <a
                            onClick={() => handleSearch()}
                            style={{ cursor: 'pointer' }}
                          >
                            <img
                              src={Buscar}
                              alt="Buscar"
                              style={{ marginTop: '15px' }}
                            />
                          </a>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Cod. fuente o ratio de GEI</TableCell>
                            <TableCell>Actividad emisora de GEI</TableCell>
                            <TableCell>Nombre de fuente o ratio</TableCell>
                            <TableCell>Origen de las emisiones</TableCell>
                            <TableCell>Factor (Kg. CO<small>2e</small>)</TableCell>
                            <TableCell>Unidad de medida</TableCell>
                            <TableCell/>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {value === 'factor'
                            ? factor.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell>{row.codigo}</TableCell>
                                  <TableCell>
                                    {row.fuente.familia.nombre}
                                  </TableCell>
                                  <TableCell>{row.fuente.nombre}</TableCell>
                                  <TableCell>
                                    {row.fuente.categoria.nombre}
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title={row.fco2}>
                                      <p>
                                        {parseFloat(
                                          row.fco2.toFixed(2)
                                        ).toLocaleString('en')}
                                      </p>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <p>{row.fuente.unidad_medida.codigo}</p>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() => onSubmit(row.id)}
                                      aria-label="upload picture"
                                      component="span"
                                      color="default"
                                      color="primary"
                                    >
                                       <Check color="#454545" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))
                            : ratios.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell>{row.codigo}</TableCell>
                                  <TableCell>{row.familia.nombre}</TableCell>
                                  <TableCell>{row.nombre}</TableCell>
                                  <TableCell>{row.categoria.nombre}</TableCell>
                                  <TableCell>
                                    <Tooltip title={row.fco2}>
                                      <p>
                                        {parseFloat(
                                          row.fco2.toFixed(2)
                                        ).toLocaleString('en')}
                                      </p>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <p>{row.unidad_medida.codigo}</p>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() => onSubmit(row.id)}
                                      aria-label="upload picture"
                                      component="span"
                                      color="default"
                                      color="primary"
                                    >
                                       <Check color="#454545" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CardHeader color="default">
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} xl={12}>
                          <h4>Opciones habilitadas</h4>
                        </GridItem>
                      </GridContainer>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Cod. fuente o ratio de GEI</TableCell>
                            <TableCell>Actividad emisora de GEI</TableCell>
                            <TableCell>Nombre de fuente o ratio</TableCell>
                            <TableCell>Origen de las emisiones</TableCell>
                            <TableCell>Factor (Kg. CO<small>2e</small>)</TableCell>
                            <TableCell>Unidad de medida</TableCell>

                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {value === 'factor'
                            ? factorTiene.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell>{row.codigo}</TableCell>
                                  <TableCell>
                                    {row.fuente.familia.nombre}
                                  </TableCell>
                                  <TableCell>{row.fuente.nombre}</TableCell>
                                  <TableCell>
                                    {row.fuente.categoria.nombre}
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title={row.fco2}>
                                      <p>
                                        {parseFloat(
                                          row.fco2.toFixed(2)
                                        ).toLocaleString('en')}
                                      </p>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <p>{row.fuente.unidad_medida.codigo}</p>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() => deleteRegistro(row.id)}
                                      aria-label="upload picture"
                                      component="span"
                                      color="default"
                                      color="primary"
                                    >
                                      <Delete color="#A3A3A3" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))
                            : ratiosTiene.map((row) => (
                                <TableRow key={row.id}>
                                  <TableCell>{row.codigo}</TableCell>
                                  <TableCell>{row.familia.nombre}</TableCell>
                                  <TableCell>{row.nombre}</TableCell>
                                  <TableCell>{row.categoria.nombre}</TableCell>
                                  <TableCell>
                                    <Tooltip title={row.fco2}>
                                      <p>
                                        {parseFloat(
                                          row.fco2.toFixed(2)
                                        ).toLocaleString('en')}
                                      </p>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell>
                                    <p>{row.unidad_medida.codigo}</p>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() => deleteRegistro(row.id)}
                                      aria-label="upload picture"
                                      component="span"
                                      color="default"
                                      color="primary"
                                    >
                                      <Delete color="#A3A3A3" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                </GridContainer>
              </Card>
            </CardBody>
            
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default withAccessGranted(GestionFuentes, [
  userAccess.ADMIN.ACCESS_LEVEL
])
