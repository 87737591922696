import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import { useToasts } from 'react-toast-notifications'
import { Backdrop, CircularProgress } from '@material-ui/core'
import AuthService from 'services/auth/index'
import MicrosoftLogin from 'react-microsoft-login'
import logo from 'assets/img/neutral_logo.png'
import logoBCIE from 'assets/img/bcie_logo.png'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  img: {
    width: '200px',
    margin: 'auto',
    verticalAlign: 'middle',
    border: '0',
    [theme.breakpoints.down('xs')]: {
      width: '100px'
    }
  },
  container: {
    width: '621px',
    margin: 'auto',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  separator: {
    width: '1px',
    height: '90px',
    marginTop: '25px',
    backgroundColor: '#e0e0e0'
  }
}))

const Login = () => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [msalInstance, onMsalInstanceChange] = useState()
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState('')
  const [login, setLogin] = useState(true)

  const loginHandler = async (err, data, msal) => {
    setMsg('Ingresando al NeutralBank')
    if(!login){
      setLoading(false)
      return
    }
    setLogin(false)
    try {
      if (!err && data) {
        setLoading(true)
        onMsalInstanceChange(msal)
        try {
          const dat = {
            accessToken: data.accessToken
          }
          const us = await AuthService.callback(dat)
          if (us.data.user.email) {
            localStorage.setItem('user', JSON.stringify(us.data))
            localStorage.setItem('login', msalInstance)
            window.location = '/dashboard'
          } else {
            addToast(
              'Estimado cliente usted no tiene usuarios registrado en este sitio',
              { appearance: 'error' }
            )
            setLoading(false)
          }
        } catch (e) {
          if (e.response.status === 401) {
            addToast(
              'Estimado cliente usted no tiene usuarios registrado en este sitio',
              { appearance: 'error' }
            )
            setLoading(false)
          } else {
            addToast('Error en el proceso', { appearance: 'error' })
            setLoading(false)
          }
        }
      } else {
        setLoading(false)
      }
    } catch (e) {
      setMsg('Estimado usted no tiene acceso a este sistema')
      setLoading(false)
      addToast('Error en el proceso', { appearance: 'error' })
      setLoading(false)
    }
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer>
        <div className={classes.container}>
          <img src={logoBCIE} alt="logo" className={classes.img} />
          <div className={classes.separator} />
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={4} style={{ margin: 'auto' }}>
          <Card>
            <CardHeader color="primary">
              <h4>Iniciar sesión</h4>
            </CardHeader>
            <CardBody className={classes.login}>
              <GridContainer>
                <p style={{ marginLeft: '30px' }}>
                  Ingresa con tu cuenta en Microsoft del BCIE.
                </p>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ margin: '10px auto 40px auto' }}>
              {msalInstance ? (
                <div>{msg}</div>
              ) : (
                <MicrosoftLogin
                  redirectUri="https://www.neutralbank.app/login"
                  graphScopes={['user.read']}
                  clientId="5726af55-94b5-4538-9b0f-573f68a810d6"
                  tenantUrl="https://login.microsoftonline.com/bcie2014.onmicrosoft.com"
                  REACT_APP_TENANT_NAME
                  buttonTheme="dark"
                  authCallback={loginHandler}
                />
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default Login
