import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import Layouts from 'layouts'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import Ligin from './views/Login'
import { createBrowserHistory } from 'history'
import AuthContext from './context/AuthContext'
import 'assets/css/neutral.css'

// import AuthService from 'services/auth/index'
const hist = createBrowserHistory()

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checkedLogin: false,
      token: '',
      user: {},
      idleCounter: 0
    }
  }
  componentDidMount() {
    const data = window.localStorage.getItem('user')
    if (data) {
      const dat = JSON.parse(data)
      const { token, user } = dat
      this.setState({ token, user, checkedLogin: true })
    } else {
      this.setState({ token: '', user: {}, checkedLogin: true })
    }
    this.clearTimeOut()
  }

  clearTimeOut = () => {
    var minutes = true
    var interval = minutes ? 60000 : 1000
    var IDLE_TIMEOUT = 45
    this.setState({ idleCounter: 0 })

    setInterval(() => {
      let { idleCounter } = this.state
      this.setState({ idleCounter: idleCounter + 1 })
      if (++idleCounter >= IDLE_TIMEOUT) {
        window.location = '/logout'
      }
    }, interval)
  }
  handleActivity = () => {
    this.setState({ idleCounter: 0 })
  }

  setLoggedUser = async () => {
    const data = window.localStorage.getItem('user')
    if (data) {
      const dat = JSON.parse(data)
      setToken(dat.token)
      setUser(dat.user)
    }
  }
  render() {
    const { checkedLogin, token, user } = this.state
    if (!checkedLogin) return null
    return (
      <div onMouseMove={() => this.handleActivity()}>
        <AuthContext.Provider
          value={{
            user: user,
            token: token,
            updateToken: this.setLoggedUser
          }}
        >
          <ToastProvider autoDismiss autoDismissTimeout={6000}>
            <Router history={hist}>
              <Switch>
                <Route path="/" component={Layouts} />
                <Route path="*" component={Ligin} />
              </Switch>
            </Router>
          </ToastProvider>
        </AuthContext.Provider>
      </div>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
unregisterServiceWorker()
