import API from '../Api'

class AuthService {
  static login (data) {
    return API({
      method: 'POST',
      url: '/login/',
      data
    })
  }
  static refresh_token (token) {
    return API({
      method: 'GET',
      url: '/refresh-token/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static logaut (token) {
    return API({
      method: 'GET',
      url: '/logout/?token=' + token
    })
  }
  static resetPasswor (email) {
    return API({
      method: 'GET',
      url: '/api/v1/usuario/recuperar_contrasena/?email=' + email
    })
  }
  static auth2 () {
    return API({
      method: 'GET',
      url: '/auth2/'
    })
  }
  static callback (data) {
    return API({
      method: 'POST',
      url: '/callback',
      data: data
    })
  }
}

export default AuthService
