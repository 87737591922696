import React, { useEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/Header/index'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'

Highcharts.setOptions({
  chart: {
    backgroundColor: {
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(255, 255, 255)']
      ]
    },
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    showTable: false,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG'
  }
})

const EmisionesActividad = ({ data, title }) => {
  const [planeacionOptions, setPlaneacionOptions] = useState({})

  useEffect(() => {
    const { anio } = data
    let categories = []
    let referencia = []
    let proyecto = []
    try {
      categories = data.proyecto.map((item) => {
        return { id: item.id, name: item.nombre }
      })
      data.referencia.map((item) => {
        const cat = { id: item.id, name: item.nombre }
        if (!categories.find((c) => c.id === cat.id)) {
          categories.push(cat)
        }
      })
    } catch (e) {}

    const catLabel = categories.map((item) => {
      try {
        const proy = data.proyecto.find((d) => d.id === item.id)
        if (proy) {
          proyecto.push(parseFloat(proy.huella.toFixed(2)))
        } else {
          proyecto.push(0)
        }
      } catch (e) {
        proyecto.push(0)
      }
      try {
        const ref = data.referencia.find((d) => d.id === item.id)
        if (ref) {
          referencia.push(parseFloat(ref.huella.toFixed(2)))
        } else {
          referencia.push(0)
        }
      } catch (e) {
        referencia.push(0)
      }
      return item.name
    })
    const planeacion = {
      chart: {
        type: 'bar'
      },
      title: {
        text: `${title}: - Actividades emisoras de GEI (tCO2e)`
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      subtitle: {
        text: `Emisiones de GEI Durante el ciclo del proyecto`
      },

      yAxis: {
        title: {
          text: 'tCO2e',
          align: 'high'
        }
      },
      xAxis: [
        {
          categories: catLabel,
          reversed: false
        }
      ],
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },

      series: [
        {
          color: '#26A2BD',
          name: `Situación de refencia: (${anio} años)`,
          data: referencia
        },
        {
          color: '#15BD85',
          name: `Proyecto: (${anio} años)`,
          data: proyecto
        }
      ]
    }
    setPlaneacionOptions(planeacion)
  }, [data])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                  F) Emisiones por Actividad Emisora de GEI (Durante el ciclo
                  del proyecto) - tCO2e
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se consolidan las emisiones de GEI de la etapa de situación de referencia de un proyecto que está siendo evaluado. El año de situación de referencia es un supuesto del impacto que tendría el entorno económico en ausencia del proyecto en fases de construcción y operaciones."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div style={{ minHeight: '500px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={planeacionOptions}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardHeader>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default EmisionesActividad
