import React, { useState, useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  DialogActions,
  Chip,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  ListItem,
  FormControlLabel,
  Button
} from '@material-ui/core/index'
import ListFuentes from 'components/ListFuentes'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import userAccess from 'userAccess'
import withAccessGranted from 'components/withAuth/index'
import CatalogoService from 'services/catalogos/index'
import ProyectoService from 'services/proyecto/index'
import DashboardService from 'services/Dashboard/index'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import Huella from './Huella'
import Adicionalidad from './Adicionalidad'
import AdicionalidadMedia from './AdicionalidadMedia'

import Origen from './Origen'
import ConsolidadaAlcance from './ConsolidadaAlcance'
import { useToasts } from 'react-toast-notifications'
import { OpenInNew } from '@material-ui/icons/index'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  separator: {
    marginTop: '25px'
  },
  dialog: {
    width: '500px',
    [theme.breakpoints.up("md")]: {
      width: '90%',
    }
  }
}))

const ProjectsDialog = ({ open, data, active, onClose, handleProyectos }) => {
  const classes = useStyles()

  const handleCancel = () => {
    onClose()
  }

  const [proyectos, setProyectos] = useState([])
  const [selected, setSelected] = useState([])

  useEffect(() => {
    const dat = data.map((item) => {
      const exist = active.find((i) => i.id === item.id)
      item.selected = exist ? true : false
      return item
    })
    setProyectos(dat)
    setSelected(active)
  }, [open, data, active])

  const handleOk = () => {
    handleProyectos(selected)
    onClose()
  }

  const handleChange = (data) => {
    if (selected && selected.indexOf(data) === -1) {
      selected.push(data)
      setSelected(selected)
    } else {
      selected.splice(selected.indexOf(data), 1)
    }
    setSelected(selected)
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        <GridContainer>
          <GridItem xs={9} sm={9} md={9}>
            Elige uno o varios proyectos
          </GridItem>
        </GridContainer>
      </DialogTitle>
      <DialogContent dividers>
        {proyectos.map((option, i) => (
          <div key={`id_data_${i.toString()}`} className={classes.dialog}>
            <FormControlLabel
              value={option.id}
              control={<Checkbox defaultChecked={option.selected} />}
              label={option.nombre}
              onChange={(e) => handleChange(option)}
              labelPlacement="end"
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleOk}>Agregar</Button>
      </DialogActions>
    </Dialog>
  )
}

const Dashboard = ({ token }) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [familia, setFamilia] = useState([])
  const [loading, setLoading] = useState(false)
  const [tipoProyecto, setTipoProyecto] = useState([])
  const [paises, setPaiese] = useState([])
  const [tipo, setTipo] = useState(0)
  const [pais, setPais] = useState(0)
  const [proyectos, setProyectos] = useState([])
  const [proyecto, setProyecto] = useState([])
  const [acumulado, setAcumulado] = useState()
  const [anio, setAnio] = useState()
  const [anios, setAnios] = useState([])
  const [categories, setCategories] = useState([])
  const [data, setData] = useState([])
  const [dataAdicionalidad, setDataAdicionalidad] = useState([])
  const [dataMitigacion, setDataMitigacion] = useState([])
  const [fases, setFases] = useState([])
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async (val) => {
    setLoading(true)
    const newProyectos = proyecto.filter((p) => p.id !== val.id)
    setProyecto(newProyectos)
    setTimeout(() => {
      const currentYear = new Date().getFullYear()
      handleData(anio ? anio : currentYear, newProyectos)
      setLoading(false)
    }, 1000)
  }

  useEffect(async () => {
    try {
      setLoading(true)
      try {
        const respTipoProyecto = await CatalogoService.getTipoProyecto(token)
        console.log(respTipoProyecto)

        if (respTipoProyecto.status === 200) {
          setTipoProyecto(respTipoProyecto.data)
        }
        const paises = await CatalogoService.getPaises(token)
        if (paises.status === 200) {
          setPaiese(paises.data)
        }
        const resp = await ProyectoService.getAll(token)
        if (resp.status === 200) {
          setProyectos(resp.data)
          const ids = resp.data.map((item) => item.id)
          const anios = await ProyectoService.getAnioProyectos(ids, token)
          if (anios.status === 200) {
            setAnios(anios.data)
          }
        }

        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    } catch (error) {}
  }, [token])

  const handleTipo = async (event) => {
    setTipo(event)
    setLoading(true)
    setAnios([])
    try {
      const resp = await ProyectoService.getAll(token)
      if (resp.status === 200) {
        if (event === 0) {
          if (pais > 0) {
            const proy = resp.data.filter((p) => p.pais.id === pais)
            setProyectos(proy)
          } else {
            setProyectos(resp.data)
          }
        } else {
          const proyectos = resp.data.filter((item) => {
            return item.tipo_proyecto.id === event
          })
          if (pais > 0) {
            const proy = proyectos.filter((p) => p.pais.id === pais)
            setProyectos(proy)
          } else {
            setProyectos(proyectos)
          }
        }
        setLoading(false)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handlePais = async (event) => {
    setPais(event)
    setLoading(true)
    try {
      const resp = await ProyectoService.getAll(token)
      if (resp.status === 200) {
        if (event === 0) {
          if (tipo > 0) {
            const proyectos = resp.data.filter((item) => {
              return item.tipo_proyecto.id === tipo
            })
            setProyectos(proyectos)
          } else {
            setProyectos(resp.data)
          }
        } else {
          const proyectos = resp.data.filter((item) => {
            return item.pais.id === event
          })
          if (tipo > 0) {
            const proy = proyectos.filter((item) => {
              return item.tipo_proyecto.id === tipo
            })
            setProyectos(proy)
          } else {
            setProyectos(proyectos)
          }
        }
        setLoading(false)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleData = async (anio, proyecto) => {
    setLoading(true)
    let props = ''
    if (!proyecto || proyecto.length === 0) {
      addToast('Seleccione al menos un proyecto', { appearance: 'warning' })
      setLoading(false)
      return
    } else {
      proyecto.map((item) => {
        props += props === '' ? item.id : `,${item.id}`
      })
      setAnio(anio)
      const data = await DashboardService.emisionesConsolidada(
        anio,
        props,
        token
      )
      if (data.status === 200) {
        setData(data.data.proyectos)
        setFamilia(data.data.familia)
      }
      const fases = await DashboardService.emisionesFaseTipo(anio, props, token)
      if (fases.status === 200) {
        setFases(fases.data)
      }
      const acum = await DashboardService.acumunadoAmbito(anio, props, token)
      if (acum.status === 200) {
        setAcumulado(acum.data)
      }

      const adic = await DashboardService.proyectoAdicionalidad(anio, props, token)
      if (adic.status === 200) {
        setDataAdicionalidad(adic.data)
      }

      const mitig = await DashboardService.proyectoMitigacion(anio, props, token)
      if (adic.status === 200) {
        setDataMitigacion(mitig.data)
      }
    }
    setLoading(false)
  }

  const handleCategories = (cat) => {
    setCategories(cat)
    if (anios[0].valor) {
      setAnio(anios[0].valor)
      handleData(anios[0].valor, proyecto)
    }
  }

  const openProjects = () => {
    if (proyectos.length > 0) {
      setOpen(true)
    } else {
      addToast('El filtro no contiene proyectos', { appearance: 'warning' })
    }
  }

  const handleProyectos = (p) => {
    setProyecto(p)
    try {
      handleData(anios[0].valor, p)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardHeader color="success">
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <h4> Tipo de proyecto</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="tipo_proyecto">Tipo de Proyecto</InputLabel>
              <Select
                labelId="tipo_proyecto"
                id="demo-simple-select"
                value={tipo}
                label="Tipo de Proyecto"
                onChange={(e) => handleTipo(e.target.value)}
              >
                <MenuItem value={0}>Todos</MenuItem>
                {tipoProyecto.map((item, i) => (
                  <MenuItem key={`tipo_id_${i.toString()}`} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="tipo_proyecto">País</InputLabel>
              <Select
                labelId="pais"
                value={pais}
                label="País"
                onChange={(e) => handlePais(e.target.value)}
              >
                <MenuItem value={0}>Todos</MenuItem>
                {paises.map((item, i) => (
                  <MenuItem key={`paise_id_${i.toString()}`} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <h5>Actividades emisoras de Gases de Efecto Invernadero (GEI)</h5>
        <ListFuentes data={familia} />
      </CardBody>
      <CardHeader>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <List component="div" role="group">
              <ListItem
                button
                divider
                aria-haspopup="true"
                aria-controls="ringtone-menu"
                aria-label="Proyectos"
                onClick={() => openProjects()}
              >
                <ListItemText primary="Elige uno o varios proyectos" />
                <OpenInNew />
              </ListItem>
              {proyectos && (
                <ProjectsDialog
                  open={open}
                  onClose={handleClose}
                  data={proyectos}
                  active={proyecto}
                  handleProyectos={handleProyectos}
                />
              )}
            </List>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <InputLabel id="tipo_proyecto">Etapa</InputLabel>
              <Select
                labelId="tipo_proyecto"
                id="demo-simple-select"
                value={categories}
                label="Tipo de Proyecto"
                onChange={(e) => handleCategories(e.target.value)}
              >
                <MenuItem value={0}>Situación de Referencia</MenuItem>
                <MenuItem value={1}>Planeación</MenuItem>
                <MenuItem value={2}>Implementación</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          {categories !== 0 && anios.length > 0 && (
            <GridItem xs={12} sm={12} md={3}>
              <FormControl fullWidth style={{ marginTop: '10px' }}>
                <InputLabel id="tipo_proyecto">Año</InputLabel>
                <Select
                  labelId="tipo_proyecto"
                  id="demo-simple-select"
                  value={anio}
                  label="Año"
                  onChange={(e) => handleData(e.target.value, proyecto)}
                >
                  {anios.map((item, i) => (
                    <MenuItem
                      key={`id_anio_${i.toString()}`}
                      value={item.valor}
                    >
                      {item.valor}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          )}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              {proyecto && (
                <div className={classes.separator}>
                  {proyecto.map((item, i) => (
                    <Chip
                      key={`ship_id_${i.toString()}`}
                      label={item.nombre}
                      variant="outlined"
                      onDelete={() => handleDelete(item)}
                    />
                  ))}
                </div>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <Huella data={data} anio={anio} categories={categories} />
        <Adicionalidad data={dataMitigacion} anio={anio} categories={categories} />  
        <AdicionalidadMedia data={dataAdicionalidad} anio={anio} categories={categories} />  

        <Origen data={fases} categories={categories} anio={anio} />
        <ConsolidadaAlcance
          data={acumulado}
          categories={categories}
          anio={anio}
        />
      </CardBody>
    </div>
  )
}

export default withAccessGranted(Dashboard, [
  userAccess.CONSULTA.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL,
  userAccess.ADMINUSER.ACCESS_LEVEL,
  userAccess.ADMIN.ACCESS_LEVEL
])
