import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import { NavLink, useLocation } from 'react-router-dom'
import Menu from '@material-ui/icons/Menu'
import NavbarLinks from './NavbarLinks.js'

import usuarios from 'assets/img/header/usuario.png'
import usuariosOn from 'assets/img/header/usuarioOn.png'
import dashOff from 'assets/img/header/dash.png'
import dashOn from 'assets/img/header/dashOn.png'
import registroOn from 'assets/img/header/registroOn.png'
import registroOff from 'assets/img/header/registro.png'
import adminOff from 'assets/img/header/admin.png'
import adminOn from 'assets/img/header/adminOn.png'

import styles from './style.js'
import { Tooltip } from '@material-ui/core/index.js'

const useStyles = makeStyles(styles)

const Header = ({ routes, color, ...props }) => {
  const location = useLocation()
  const [admin, setAdmin] = useState(false)
  const [user, setUser] = useState(false)
  const [userLogin, setUserLogin] = useState(false)
  const [registro, setRegistro] = useState(false)
  const [dash, setDash] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    try {
      const menu = routes.find((item) => item.path === location.pathname)
      setAdmin(menu.type === 'admin')
      setUser(menu.type === 'user')
      setDash(menu.type === 'dash')
      setRegistro(menu.type === 'reg')
    } catch (err) {}
  })
  useEffect(() => {
    const data = window.localStorage.getItem('user')
    if (data) {
      const dat = JSON.parse(data)
      setUserLogin(dat.user)
    }
  }, [])

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <div
            style={{ display: 'flex', margin: 'auto', width: 'max-content' }}
          >
            <Tooltip title="Dashboard">
              <NavLink to="/dashboard" activeClassName="active">
                {dash ? (
                  <img src={dashOn} alt="dash" className={classes.img} />
                ) : (
                  <img src={dashOff} alt="dash" className={classes.img} />
                )}
              </NavLink>
            </Tooltip>
            <div className={classes.separator} />
            <Tooltip title="Gestión de Usuarios">
              <NavLink to="/profile" activeClassName="active">
                {user ? (
                  <img
                    src={usuariosOn}
                    alt="usuarios"
                    className={classes.img}
                  />
                ) : (
                  <img src={usuarios} alt="usuarios" className={classes.img} />
                )}
              </NavLink>
            </Tooltip>
            {userLogin.tipo_usuario !== 2 && userLogin.tipo_usuario !== 4 && (
              <>
                <div className={classes.separator} />
                <Tooltip title="Proyectos">
                  <NavLink to="/registro-referencia" activeClassName="active">
                    {registro ? (
                      <img
                        src={registroOn}
                        alt="registro"
                        className={classes.img}
                      />
                    ) : (
                      <img
                        src={registroOff}
                        alt="registro"
                        className={classes.img}
                      />
                    )}
                  </NavLink>
                </Tooltip>
              </>
            )}
            {userLogin.tipo_usuario === 1 && (
              <>
                <div className={classes.separator} />
                <Tooltip title="Gestión de la cuenta">
                  <NavLink to="/organizacion" activeClassName="active">
                    {admin ? (
                      <img src={adminOn} alt="admin" className={classes.img} />
                    ) : (
                      <img src={adminOff} alt="admin" className={classes.img} />
                    )}
                  </NavLink>
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <Hidden smDown implementation="css">
          <NavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
}
export default Header
