import React, { useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Buscar from 'assets/img/buscar.svg'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import { useToasts } from 'react-toast-notifications'
import ProyectoService from '../../../services/proyecto'
import { Edit } from '@material-ui/icons'

import CatalogoService from 'services/catalogos/index'
import {
  CircularProgress,
  makeStyles,
  TableContainer,
  Backdrop,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Tooltip
} from '@material-ui/core'
import CardFooter from 'components/Card/Footer/index'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import CustomInput from 'components/CustomInput/index'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  tableHeadCell: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'normal'
    }
  }
}))

const RegistroHuella = ({ history, token }) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [tipoProyecto, setTipoProyecto] = useState([])
  const [proyectos, setProyectos] = useState([])
  const [paises, setPaises] = useState([])
  const [search, setSearch] = useState('')
  const [pais, setPais] = useState(0)
  const [tipo, setTipo] = useState(0)
  const [loading, setLoading] = useState(true)

  const handleSearch = async (tipo, pais) => {
    setLoading(true)
    try {
      let query = `nombre=${search}`
      if (pais !== 0) {
        query += `&pais=${pais}`
      }
      if (tipo !== 0) {
        query += `&tipo_proyecto=${tipo}`
      }
      const resp = await ProyectoService.search(query, token)
      if (resp.status === 200) {
        setProyectos(resp.data)
        setLoading(false)
      } else {
        setProyectos([])
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const handleTipo = (val) => {
    setTipo(val)
    handleSearch(val, pais)
  }

  const handlePais = (val) => {
    setPais(val)
    handleSearch(tipo, val)
  }

  const loadData = async () => {
    try {
      const respTipoProyecto = await CatalogoService.getTipoProyecto(token)
      if (respTipoProyecto.status === 200) {
        const data = await respTipoProyecto.data.map((item) => {
          return { label: item.nombre, value: item.id }
        })

        setTipoProyecto(data)
      }
      const paises = await CatalogoService.getPaises(token)
      if (paises.status === 200) {
        setPaises(paises.data)
      }

      const resp = await ProyectoService.getAll(token)

      if (resp.status === 200) {
        setProyectos(resp.data)
      } else {
        setProyectos([])
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  const handleEdit = (p) => {
    history.push({
      pathname: '/referencia-item',
      state: { id: p.id }
    })
  }

  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridItem xs={12} sm={12} md={12}>
        <CardHeader color="success">
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <h4>Tipo de proyecto</h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              {tipoProyecto && (
                <FormControl fullWidth>
                  <InputLabel id="tipo_proyecto">Tipo de Proyecto</InputLabel>
                  <Select
                    labelId="tipo_proyecto"
                    id="demo-simple-select"
                    value={tipo}
                    defaultValue={tipo}
                    label="Tipo de Proyecto"
                    onChange={(e) => handleTipo(e.target.value)}
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    {tipoProyecto.map((item, i) => (
                      <MenuItem
                        key={`id_tipo_${i.toString()}`}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="pais_label">País</InputLabel>
                <Select
                  labelId="pais_label"
                  value={pais}
                  label="Tipo de Proyecto"
                  onChange={(e) => handlePais(e.target.value)}
                >
                  <MenuItem value={0}>Todos</MenuItem>
                  {paises.map((item, i) => (
                    <MenuItem key={`id_tipo_${i.toString()}`} value={item.id}>
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </CardHeader>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Buscar"
                  value={search}
                  top
                  onChange={(e) => setSearch(e.target.value)}
                  labelText="Buscador de proyectos"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={1}>
                <a
                  onClick={() => handleSearch(tipo, pais)}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={Buscar}
                    alt="Buscar"
                    style={{ marginTop: '15px' }}
                  />
                </a>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell></TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Nombre del proyecto
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        ID del proyecto
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Tipo de Proyecto
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        País
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Año de Inicio
                      </TableCell>
                      <TableCell
                        className={classes.tableHeadCell}
                        scope="row"
                        align="right"
                      >
                        Monto del préstamo
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {proyectos.map((p, key) => (
                      <TableRow
                        key={`key_table${key.toString()}`}
                        className={classes.tableBodyRow}
                      >
                        <TableCell className={classes.tableCell}>
                          <IconButton
                            size="small"
                            type="button"
                            color="#15bd85"
                            onClick={() => handleEdit(p)}
                          >
                            <Edit style={{ fill: '#15bd85' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {p.nombre}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {p.codigo}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {p.tipo_proyecto ? p.tipo_proyecto.nombre : ''}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {p.pais ? p.pais.nombre : ''}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {p.anio}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          scope="row"
                          align="right"
                        >
                          <Tooltip title={p.monto}>
                            <span>{p.monto.toLocaleString('en')} </span>
                          </Tooltip>
                          ({p.moneda.codigo})
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </CardBody>
          <CardFooter></CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withAccessGranted(RegistroHuella, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
