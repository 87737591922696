import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CardHeader from 'components/Card/Header/index'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
import { IconButton, Tooltip } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'

Highcharts.setOptions({
  chart: {
    backgroundColor: {
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(255, 255, 255)']
      ]
    },
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    showTable: false,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG'
  }
})

const Comparativa = ({ data, general, title }) => {
  const [planeacionOptions, setPlaneacionOptions] = useState({})
  const [implementacionOptions, setImplementacionOptions] = useState({})
  useEffect(() => {
    const { proyecto, referencia } = general
    const { anio } = data
    const plan = {
      chart: {
        type: 'column'
      },
      title: {
        text: `${title}: Emisiones de GEI (${anio} años)`
      },
      yAxis: {
        title: {
          text: 'tCO2e'
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value)
          }
        }
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },
      subtitle: {
        text: `Emisiones de las fases operativas del proyecto`
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      xAxis: {
        categories: ['Situación de Referencia', 'Emisiones del proyecto']
      },
      series: [
        {
          name: 'tCO2e',
          color: '#A3A3A3',
          data: [
            referencia ? parseFloat(referencia.toFixed(2)) : 0,
            proyecto ? parseFloat(proyecto.toFixed(2)) : 0
          ]
        }
      ]
    }
    setPlaneacionOptions(plan)

    const categories = [
      'Situación de Referencia',
      'Emisiones del proyecto',
      'Adicionalidad'
    ]
    let adicionalidad = [0, 0, 0]
    let proyectoAdicional = [0, 0, 0]

    if (general) {
      adicionalidad = [
        0,
        0,
        general.diferencia ? parseFloat(general.diferencia.toFixed(2)) : 0
      ]
      proyectoAdicional = [
        general.referencia ? parseFloat(general.referencia.toFixed(2)) : 0,
        general.proyecto ? parseFloat(general.proyecto.toFixed(2)) : 0,
        0
      ]
    }
    const implementacionOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: `${title}: Emisión diferencial a lo largo de la duración del proyecto`
      },
      subtitle: {
        text: `Análisis de la Situación de Referencia (${anio} años) `
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      xAxis: [
        {
          categories: categories,
          reversed: false
        }
      ],
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },
      yAxis: {
        title: {
          text: 'tCO2e'
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value)
          }
        }
      },
      series: [
        {
          name: 'Adicionalidad',
          data: adicionalidad
        },
        {
          name: 'Proyecto',
          data: proyectoAdicional
        }
      ]
    }
    setImplementacionOptions(implementacionOptions)
  }, [data, general])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                  G) Emisiones por Etapa (Situación de Referencia / Proyecto) -
                  tCO2e
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se muestran las emisiones de GEI por fases de construcción y operativas, comparando las emisiones supuestas para la etapa de planeación con las emisiones reales de la etapa de implementación para un año específico de actividades. Si las emisiones de la etapa de implementación son menores a las emisiones de la etapa de planeación, entonces el proyecto demuestra mitigar emisiones de GEI."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ minHeight: '200px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={planeacionOptions}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <div style={{ minHeight: '200px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={implementacionOptions}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </CardBody>
  )
}

Comparativa.propTypes = {
  data: PropTypes.objectOf({
    proyecto: PropTypes.number,
    referencia: PropTypes.number,
    anio: PropTypes.number
  }),
  general: PropTypes.objectOf({}),
  title: PropTypes.string
}
Comparativa.defaultProps = {
  data: {
    proyecto: 0,
    referencia: 0,
    anio: 0
  },
  general: {
    diferencia: 0,
    proyecto: 0,
    referencia: 0
  },
  anio: 0
}

export default Comparativa
