import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { getUnixTime } from 'date-fns'

import AuthContext from '../../context/AuthContext'

function withAccessGranted(WrappedComponent, privilege) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        accessLevelRequired: privilege
      }
    }

    render() {
      const { accessLevelRequired } = this.state
      return (
        <AuthContext.Consumer>
          {({ user, token }) => {
            const isvalidToken = user.expires > getUnixTime(new Date())
            if (!isvalidToken) {
              //localStorage.clear()
              //sessionStorage.clear()
              //return <Redirect push to="/login" />
            }

            const role = accessLevelRequired.find(
              (item) => item === user.tipo_usuario
            )
            const isGranted = !!role
            const props = {
              user,
              token
            }

            return (
              <div>
                {isGranted ? (
                  <WrappedComponent {...this.props} {...props} />
                ) : (
                  <Redirect push to="/login" />
                )}
              </div>
            )
          }}
        </AuthContext.Consumer>
      )
    }
  }
}

export default withAccessGranted
