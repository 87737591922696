/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import Snackbar from 'components/Snackbar'
import { Style } from './style'
import FactorService from 'services/factor/index'

const useStyles = makeStyles(Style)

const FactorTree = () => {
  const classes = useStyles()
  const [notif, setNotif] = useState({ open: false, color: '', message: '' })
  const [tree, setTree] = useState([])

  useEffect(async () => {
    try {
      const factor = await FactorService.getAll()
      if (factor.status === 200) {
        setTree(factor.data)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  return (
    <div>
      <Snackbar
        {...notif}
        closeNotification={() => setNotif({ open: false })}
        close
      />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4>Factores</h4>
            </CardHeader>
            <ul>
              {tree.map((data, i) => (
                <li key={i}>
                  {data.nombre}
                  <ul key={i}>
                    {data.factores.map((factor, ii) => (
                      <li key={ii}>{factor.nombre}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default FactorTree
