import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardFooter from 'components/Card/Footer'
import { useToasts } from 'react-toast-notifications'
import { Backdrop } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import MicrosoftLogin from 'react-microsoft-login'
import { set } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Login = () => {
  const { addToast } = useToasts()
  const classes = useStyles()

  const [msalInstance, onMsalInstanceChange] = useState()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
      setTimeout(() => {
        console.log('test')
        window.location = '/'
      }, 100)
  },[]) 

  const loginHandler = async (err, data, msal) => {
    if (!err && data) {
      setLoading(true)
      msal.logout()
    } else {
      setLoading(false)
    }
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={4} style={{ margin: 'auto' }}>
          <Card className={classes.login}>
            <CardHeader color="primary">
              <h4>Cerrar sesión</h4>
            </CardHeader>

            <CardFooter style={{ margin: '10px auto 40px auto' }}>
              {msalInstance ? (
                <div>Login</div>
              ) : (
                <div>
                  <a href='login'>
                    <Button type="button" color="success">
                      Login
                    </Button>
                  </a>

                  <div style={{ display: 'none' }}>
                    <MicrosoftLogin
                      redirectUri="https://www.neutralbank.app/login"
                      graphScopes={['user.read']}
                      clientId="5726af55-94b5-4538-9b0f-573f68a810d6"
                      tenantUrl="https://login.microsoftonline.com/bcie2014.onmicrosoft.com"
                      REACT_APP_TENANT_NAME
                      authCallback={loginHandler}
                    ></MicrosoftLogin>
                  </div>
                </div>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default Login
