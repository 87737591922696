import React, { useEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/Header/index'
import { IconButton, Tooltip } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'

Highcharts.setOptions({
  chart: {
    backgroundColor: {
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(255, 255, 255)']
      ]
    },
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    showTable: false,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG'
  }
})

const Factor = ({ data, anio, title }) => {
  const [planeacionOptions, setPlaneacionOptions] = useState({})
  const dat = data.map((d) => {
    return parseFloat(d.huella.toFixed(2))
  })
  const categories = data.map((item) => {
    return item.nombre
  })
  useEffect(() => {
    const planeacion = {
      chart: {
        type: 'bar'
      },
      title: {
        text: `${title}: - Actividades emisoras de GEI (tCO2e)`
      },
      credits: {
        href: 'https://www.neutralbank.io',
        text: 'www.neutralbank.app'
      },
      subtitle: {
        text: `Emisiones de GEI sin el proyecto`
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          text: 'tCO2e',
          align: 'high'
        }
      },
      credits: {
        href: 'https://www.neutralbank.io',
        text: 'www.neutralbank.app'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },

      series: [
        {
          name: `tCO2e`,
          color: '#A3A3A3',
          data: dat
        }
      ]
    }
    setPlaneacionOptions(planeacion)
  }, [data])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                  B) Emisiones por Actividad Emisora de GEI (Situación de
                  Referencia) - tCO2e
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se consolidan las emisiones de GEI de la etapa de situación de referencia de un proyecto que está siendo evaluado. El año de situación de referencia es un supuesto del impacto que tendría el entorno económico en ausencia del proyecto en fases de construcción y operaciones."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div style={{ minHeight: '500px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={planeacionOptions}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardHeader>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default Factor
