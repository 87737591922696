import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import { Style } from '../style'
import CustomInput from 'components/CustomInput'
import CardFooter from 'components/Card/Footer/index'
import ListFuentes from 'components/ListFuentes'
import ProyectoService from '../../../services/proyecto'
import { useToasts } from 'react-toast-notifications'
import {
  Backdrop,
  MenuItem,
  Select,
  InputLabel,
  Table,
  CircularProgress,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  FormControl,
  TextField,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Paper,
  DialogContent,
  IconButton
} from '@material-ui/core'
import Draggable from 'react-draggable';
import ButtonModal from '@material-ui/core/Button'
import Error from 'components/Error'
import CatalogoService from 'services/catalogos'
import { Close, Delete, Add } from '@material-ui/icons'
import { useLocation } from 'react-router'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import NumberInput from 'components/NumberInput/index'

const useStyles = makeStyles(Style)
const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const ProyectoForm = ({ user, token, history }) => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const { addToast } = useToasts()
  const [tipo, setTipo] = useState()
  const [proyecto, setProyecto] = useState({})
  const [pais, setPais] = useState()
  const [id, setId] = useState(0)
  const [fases, setFases] = useState([])
  const [catFases, setCatFases] = useState([])
  const [familia, setFamilia] = useState([])
  const [tipos, setTipos] = useState([])
  const [paises, setPaises] = useState([])
  const [observaciones, setObservaciones] = useState('')
  const [descripcion, setDescription] = useState('')
  const [analisis, setAnalisis] = useState('')
  // datos del proyecto
  const [nombre, setNombre] = useState('')
  const [anio, setAnio] = useState('')
  const [monto, setMonto] = useState('')
  const [etapaActual, setEtapaActual] = useState('')
  const [fechaInicio, setFechaInicio] = useState('')
  const [duracionFaseConstrucion, setDuracionFaseConstrucion] = useState(0)
  const [duracionFaseOperativa, setDuracionFaseOperativa] = useState(0)
  const [finProyecto, setFinProyecto] = useState(0)
  const [fechaInicioMedicion, setFechaInicioMedicion] = useState('')
  const [lineaCredito, setLineaCredito] = useState([])
  const [lcredito, setLcredito] = useState('')
  const [errors, setErrors] = useState([])
  const location = useLocation()
  const [listMoneda, setListMoneda] = useState([])
  const [moneda, setMoneda] = useState('')
  const [noOperacion, setNoOperacion] = useState('')
  const [open, setOpen] = useState(false)
  useEffect(async () => {
    setLoading(true)
    try {
      const { id } = location.state
      if (id !== undefined && id !== 0) {
        setId(id)
        await loadData(id)
      } else {
        setLoading(false)
      }
    } catch (e) {
      history.push(`lista-proyecto`)
    }
    try {
      const respTipoProyecto = await CatalogoService.getTipoProyecto(token)
      if (respTipoProyecto.status === 200) {
        const data = respTipoProyecto.data.map((item) => {
          return { label: item.nombre, value: item.id }
        })
        setTipos(data)
      }
    } catch (e) {
      history.push(`lista-proyecto`)
    }
    const paises = await CatalogoService.getPaises(token)
    if (paises.status === 200) {
      const paisesData = paises.data.map((item) => {
        return { label: item.nombre, value: item.id }
      })
      setPaises(paisesData)
    }
    const moneda = await CatalogoService.getMoneda(token)
    if (moneda.status === 200) {
      const monedaData = moneda.data.map((item) => {
        return { label: item.nombre, value: item.id }
      })
      setListMoneda(monedaData)
    }

    try {
      const { id } = location.state
      const catfase = await CatalogoService.getFaseProyecto(token)
      if (catfase.status === 200) {
        const catFaseData = catfase.data.map((item) => {
          return { label: item.nombre, value: item.id }
        })
        if (id !== undefined && id !== 0) {
          catFaseData.splice(2, 1)
          setCatFases(catFaseData)
          setEtapaActual(catFaseData[0].value)
          handleEtapa(catFaseData[0].value)
        } else {
          setCatFases(catFaseData)
        }
      }
    } catch (e) {}
  }, [])

  const loadData = async (id) => {
    try {
      const resp = await ProyectoService.getProyecto(id, token)
      if (resp.status === 200) {
        const data = resp.data
        setProyecto(data)
        setEtapaActual(data.estapa_actual.id)
        const etapa = data.fases[0].cat_fase.id
        setEtapaActual(etapa)
        const catFaseData = data.fases.filter((item) => {
          return item.cat_fase.id !== etapa
        })

        setFases(catFaseData)
        setPais(data.pais.id)
        setTipo(data.tipo_proyecto.id)
        setFamilia(data.familia)
        setNombre(data.nombre)
        setAnio(data.anio)
        const val = data.monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        setMonto(val)
        setFechaInicio(data.fecha_inicio)
        setDuracionFaseConstrucion(data.duracion_fase_construccion)
        setDuracionFaseOperativa(data.duracion_fase_operativa)
        setFinProyecto(data.fin_proyecto)
        setFechaInicioMedicion(data.fecha_inicio_medicion)
        setObservaciones(data.observaciones)
        setAnalisis(data.analisis)
        setMoneda(data.moneda.id)
        setLoading(false)
        setNoOperacion(data.no_operacion)
        setLineaCredito(data.linea_credito)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleType = async (tipo) => {
    setTipo(tipo)
    if (id === 0) {
      try {
        const resul = await ProyectoService.template(tipo, token)
        if (resul.status === 200) {
          setFases(resul.data.fases)
          setFamilia(resul.data.familia)
          setObservaciones(resul.data.observaciones)
          setDescription(resul.data.descripcion)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const onSubmit = async () => {
    let error = false
    if (!tipo) {
      addToast('Seleccione el tipo de proyecto', { appearance: 'error' })
      return
    }
    if (!pais) {
      addToast('Seleccione el país', { appearance: 'error' })
      return
    }
    if (!nombre) {
      addToast('Nombre del proyecto es requerido', { appearance: 'error' })
      error = true
    }
    if (!anio) {
      addToast('Año del proyecto es requerido', { appearance: 'error' })
      error = true
    }
    if (!monto) {
      addToast('Monto del proyecto es requerido', { appearance: 'error' })
      error = true
    }
    if (!moneda) {
      addToast('Moneda del proyecto es requerido', { appearance: 'error' })
      error = true
    }
    if (!etapaActual) {
      addToast('Etapa actual del proyecto es requerido', {
        appearance: 'error'
      })
      error = true
    }
    if (!analisis) {
      addToast('Descripción del proyecto es requerido', { appearance: 'error' })
      error = true
    }
    if (error) return

    try {
      const data = {
        nombre: nombre,
        anio: anio,
        monto: monto.replace(/,/g, ''),
        fecha_inicio: fechaInicio,
        duracion_fase_construccion: duracionFaseConstrucion,
        duracion_fase_operativa: duracionFaseOperativa,
        fin_proyecto: finProyecto,
        fecha_inicio_medicion: fechaInicioMedicion,
        observaciones: observaciones,
        tipo_proyecto: tipo,
        observaciones: observaciones,
        estapa_actual: etapaActual,
        pais: pais,
        analisis: analisis,
        fases: fases,
        moneda: moneda,
        no_operacion: noOperacion
      }

      if (id !== 0) {
        const resul = await ProyectoService.edit(id, data, token)
        addToast('Operación realizada con éxito', {
          appearance: 'success'
        })
      } else {
        const resul = await ProyectoService.save(data, token)
        addToast('Operación realizada con éxito', {
          appearance: 'success'
        })
      }
      history.push(`lista-proyecto`)
    } catch (e) {
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }

  const handleDelete = (id) => {
    const deleteFase = fases.filter((item) => {
      return item.id !== id
    })
    setFases(deleteFase)
  }

  const handleMonto = (value) => {
    const val = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    setMonto(val)
  }

  const updateFinProyecto = () => {
    const fin =
      parseFloat(duracionFaseConstrucion) + parseFloat(duracionFaseOperativa)
    setFinProyecto(fin)
  }

  const handleEtapa = (value) => {
    setEtapaActual(value)
    try {
      if (proyecto.fases) {
        const fase = proyecto.fases.filter((item) => {
          return item.cat_fase.id === value
        })
        setFases(fase)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleLineaCredito = async () => {
    const { id } = location.state
    const data = await CatalogoService.lineaCredito(token, {
      nombre: lcredito,
      proyecto: id
    })
    setLcredito('')
    lineaCredito.push(data.data)
    setLineaCredito(lineaCredito)
  }

  const deleteLineaCredito = async (p) => {
    handleClose()
    try {
      const data = await CatalogoService.deleteLineaCredito(
        token,
        p
      )
      const deleteLinea = lineaCredito.find((item) => {
        item.id === p
      })
      lineaCredito.splice(lineaCredito.indexOf(deleteLinea), 1)
      setLineaCredito(lineaCredito)
    } catch (e) {}
  }

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    )
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <GridContainer>
      <Backdrop className={load.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <h4>Tipo de proyecto</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  {id !== 0 ? (
                    <FormControl fullWidth>
                      <InputLabel id="label_proy"> Tipo de proyecto</InputLabel>
                      {tipo && (
                        <Select
                          labelId="label_proy"
                          value={tipo}
                          disabled
                          defaultValue={tipo}
                          label="Tipo de proyecto"
                          onChange={(e) => handleType(e.target.value)}
                        >
                          {tipos.map((item, i) => (
                            <MenuItem
                              key={`id_tipo_${i.toString()}`}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel id="label_proy"> Tipo de proyecto</InputLabel>
                      <Select
                        labelId="label_proy"
                        value={tipo}
                        defaultValue={tipo}
                        label="Tipo de proyecto"
                        onChange={(e) => handleType(e.target.value)}
                      >
                        {tipos.map((item, i) => (
                          <MenuItem
                            key={`id_tipo_${i.toString()}`}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  {id !== 0 ? (
                    <FormControl fullWidth>
                      <InputLabel id="label_anio">Pais</InputLabel>
                      {pais && (
                        <Select
                          labelId="label_anio"
                          value={pais}
                          defaultValue={pais}
                          label="Pais"
                          disabled
                          onChange={(e) => setPais(e.target.value)}
                        >
                          {paises.map((item, i) => (
                            <MenuItem
                              key={`id_tipo_${i.toString()}`}
                              value={item.value}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <InputLabel id="label_anio">Pais</InputLabel>
                      <Select
                        labelId="label_anio"
                        value={pais}
                        defaultValue={pais}
                        label="Pais"
                        onChange={(e) => setPais(e.target.value)}
                      >
                        {paises.map((item, i) => (
                          <MenuItem
                            key={`id_tipo_${i.toString()}`}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <p>
                Actividades emisoras de Gases de Efecto Invernadero (GEI) -
                (*Categorías por defecto)
              </p>
              <div>
                <ListFuentes data={familia} />
              </div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Nombre del Proyecto"
                    top
                    required
                    id="name"
                    name="name"
                    value={nombre}
                    defaultValue={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />

                  {errors.name && <Error>Campo es requerido</Error>}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <NumberInput
                    labelText="Año"
                    required
                    id="anio"
                    name="anio"
                    top={false}
                    value={anio}
                    defaultValue={anio}
                    onChange={(e) => setAnio(e.target.value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />

                  {errors.name && <Error>Campo es requerido</Error>}
                </GridItem>

                <GridItem xs={12} sm={12} md={2}>
                  <NumberInput
                    labelText="Monto"
                    id="monto"
                    required
                    name="monto"
                    value={monto}
                    defaultValue={monto}
                    onChange={(e) => handleMonto(e.target.value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  {errors.description && <Error>Campo es requerido</Error>}
                </GridItem>

                <GridItem xs={12} sm={12} md={1}>
                  <FormControl fullWidth required>
                    <InputLabel id="label_moneda"> Moneda</InputLabel>
                    {listMoneda && (
                      <Select
                        labelId="label_moneda"
                        value={moneda}
                        defaultValue={moneda}
                        label="Moneda"
                        onChange={(e) => setMoneda(e.target.value)}
                      >
                        {listMoneda.map((item, i) => (
                          <MenuItem
                            key={`id_tipo_${i.toString()}`}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <FormControl fullWidth required>
                    <InputLabel id="label_etapa">
                      Etapas del Proyecto
                    </InputLabel>
                    {catFases && (
                      <Select
                        labelId="label_etapa"
                        value={etapaActual}
                        defaultValue={etapaActual}
                        label="Etapa Actual"
                        onChange={(e) => handleEtapa(e.target.value)}
                      >
                        {catFases.map((item, i) => (
                          <MenuItem
                            key={`id_tipo_${i.toString()}`}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                      <TableCell className={classes.tableHeadCell}>
                        Fase
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Actividad de las emisiones
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Origen de las emisiones
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Clasificación de las emisiones
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Datos de entrada
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Alcance de fuente de GEI
                      </TableCell>
                      <TableCell className={classes.tableHeadCell}>
                        Emisión actual (tCO<small>2e</small>)
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fases.map((p, key) => (
                      <TableRow
                        key={`key_table${key.toString()}`}
                        className={classes.tableBodyRow}
                      >
                        <TableCell className={classes.tableCell}>
                          {p.nombre}
                          {p.obligatorio && (
                            <span
                              style={{ color: '#15BD85', fontSize: '14px' }}
                            >
                              *
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {p.familia.nombre}
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {p.origen_emision.nombre}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {p.calificacion.nombre}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {p.datos_recomplilar}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          Alcance {p.alcance}
                        </TableCell>
                        <TableCell className={classes.tableCell}>0.0</TableCell>
                        <TableCell className={classes.tableCell}>
                          <IconButton onClick={() => handleDelete(p.id)}>
                            <Close
                              className={
                                classes.tableActionButtonIcon +
                                ' ' +
                                classes.edit
                              }
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </GridContainer>
            </CardBody>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <div style={{ marginTop: '15px' }}>
                    <TextField
                      fullWidth
                      onChange={(e) => setFechaInicio(e.target.value)}
                      type="date"
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      defaultValue={fechaInicio}
                      value={fechaInicio}
                      label="Fecha de Inicio del proyecto"
                      variant="standard"
                    />
                  </div>
                  {errors.inicio && <Error>Campo es requerido</Error>}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <NumberInput
                    labelText="Duración Fase Construcción (Años)"
                    required
                    top
                    value={duracionFaseConstrucion}
                    defaultValue={duracionFaseConstrucion}
                    onChange={(e) => setDuracionFaseConstrucion(e.target.value)}
                    onBlur={updateFinProyecto}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  {errors.anios_fase && <Error>Campo es requerido</Error>}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <NumberInput
                    labelText="Duración Fase Operativa (Años)"
                    required
                    top
                    value={duracionFaseOperativa}
                    defaultValue={duracionFaseOperativa}
                    onBlur={updateFinProyecto}
                    onChange={(e) => setDuracionFaseOperativa(e.target.value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />

                  {errors.duracion && <Error>Campo es requerido</Error>}
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <NumberInput
                    labelText="Fin del proyecto (Años)"
                    required
                    disabled
                    top
                    value={finProyecto}
                    defaultValue={finProyecto}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  {errors.fin && <Error>Campo es requerido</Error>}
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <div style={{ marginTop: '15px' }}>
                    <TextField
                      fullWidth
                      onChange={(e) => setFechaInicioMedicion(e.target.value)}
                      type="date"
                      InputLabelProps={{
                        shrink: true
                      }}
                      required
                      value={fechaInicioMedicion}
                      label="Fecha de inicio de medición"
                      variant="standard"
                    />
                  </div>

                  {errors.inicio_medicion && <Error>Campo es requerido</Error>}
                </GridItem>
                {id > 0 && (
                  <GridItem xs={12} sm={12} md={4}>
                    <div style={{ marginTop: '15px' }}>
                      <CustomInput
                        labelText="Número de operación"
                        top
                        required
                        id="name"
                        name="name"
                        value={noOperacion}
                        defaultValue={noOperacion}
                        onChange={(e) => setNoOperacion(e.target.value)}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </div>

                    {errors.inicio_medicion && (
                      <Error>Campo es requerido</Error>
                    )}
                  </GridItem>
                )}
                {id > 0 && (
                  <GridItem xs={12} sm={12} md={4}>
                    <div style={{ marginTop: '15px', display: 'flex' }}>
                      <CustomInput
                        labelText="Líneas de Crédito"
                        top
                        required
                        id="lcredito"
                        name="lcredito"
                        value={lcredito}
                        onChange={(e) => setLcredito(e.target.value)}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleLineaCredito()}
                        className={classes.margin}
                      >
                        <Add fontSize="small" />
                      </IconButton>
                    </div>

                    <div>
                      <table style={{ width: '100%' }}>
                        <tr>
                          <th style={{ width: '90%', textAlign: 'left' }}>
                            {' '}
                            Nombre
                          </th>
                          <th></th>
                        </tr>
                        {lineaCredito.map((p) => (
                          <tr>
                            <td>{p.nombre}</td>
                            <td>
                              <div>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleClickOpen(p.id)}
                                  className={classes.margin}
                                >
                                  <Delete fontSize="small" />
                                </IconButton>
                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  PaperComponent={PaperComponent}
                                  aria-labelledby="draggable-dialog-title"
                                >
                                  <DialogTitle
                                    style={{ cursor: 'move' }}
                                    id="draggable-dialog-title"
                                  >
                                    Eliminar
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>
                                      Seguro que desea eliminar la linea {p.nombre}
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <ButtonModal autoFocus onClick={handleClose}>
                                      Cancelar
                                    </ButtonModal>
                                    <ButtonModal color="error" style={{color: 'red'}} onClick={()=>deleteLineaCredito(p.id)}>
                                      Eliminar
                                    </ButtonModal>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h5> Observaciones importantes (*AFD)</h5>
                  <p>{observaciones}</p>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Descripción del proyecto"
                    top
                    multiline
                    rows={4}
                    id="descripcion"
                    required
                    name="descripcion"
                    value={analisis}
                    defaultValue={analisis}
                    onChange={(e) => setAnalisis(e.target.value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  {errors.description && <Error>Campo es requerido</Error>}
                </GridItem>
              </GridContainer>
            </CardBody>

            <CardFooter>
              <GridItem xs={12} sm={12} md={3}>
                <Button type="button" onClick={onSubmit} color="success">
                  Guardar
                </Button>
              </GridItem>
            </CardFooter>
          </Card>
        </GridItem>
      )}
    </GridContainer>
  )
}

export default withAccessGranted(ProyectoForm, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
