import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AguasResiduales from 'assets/img/fuentes/AguasResidualesOff.png'
import AguasResidualesOn from 'assets/img/fuentes/AguasResidualesOn.png'
import ConstruccionIcon from 'assets/img/fuentes/ConstruccionOff.png'
import ConstruccionOn from 'assets/img/fuentes/ConstruccionOn.png'
import EmisionesProcesos from 'assets/img/fuentes/EmisionesProcesosOff.png'
import EmisionesProcesosOn from 'assets/img/fuentes/EmisionesProcesosOn.png'
import EnergiaIcon from 'assets/img/fuentes/EnergiaOff.png'
import EnergiaOn from 'assets/img/fuentes/EnergiaOn.png'
import EntradaBienesServicios from 'assets/img/fuentes/EntradaBienesServiciosOff.png'
import EntradaBienesServiciosOn from 'assets/img/fuentes/EntradaBienesServiciosOn.png'
import FinVida from 'assets/img/fuentes/FinVidaOff.png'
import FinVidaOn from 'assets/img/fuentes/FinVidaOn.png'
import ResiduosSolidos from 'assets/img/fuentes/ResiduosSolidosOff.png'
import ResiduosSolidosOn from 'assets/img/fuentes/ResiduossolidosOn.png'
import TransporteMercancias from 'assets/img/fuentes/TransporteMercanciasOff.png'
import TransporteMercanciasOn from 'assets/img/fuentes/TransporteMercanciasOn.png'

import TransportePasajeros from 'assets/img/fuentes/TransportePasajerosOff.png'
import TransportePasajerosOn from 'assets/img/fuentes/TransportePasajerosOn.png'

import Uso from 'assets/img/fuentes/UsoOff.png'
import UsoOn from 'assets/img/fuentes/UsoOn.png'

import UsoSuelo from 'assets/img/fuentes/UsoSueloOff.png'
import UsoSueloOn from 'assets/img/fuentes/UsoSueloOn.png'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import styles from './style'
const useStyles = makeStyles(styles)

const ListFuentes = ({ data }) => {
  const classes = useStyles()
  const [aguasResiduales, setAguasResiduales] = useState(false)
  const [construccion, setConstruccion] = useState(false)
  const [emisionesProcesos, setEmisionesProcesos] = useState(false)
  const [energia, setEnergia] = useState(false)
  const [finVida, setFinVida] = useState(false)
  const [transporteMercancias, setTransporteMercancias] = useState(false)
  const [transportePasajeros, setTransportePasajeros] = useState(false)
  const [uso, setUso] = useState(false)
  const [usoSuelos, setUsoSuelos] = useState(false)
  const [entradaBienesServicios, setEntradaBienesServicios] = useState(false)
  const [residuosSolidos, setResiduosSolidos] = useState(false)

  useEffect(() => {
    data.map((item) => {
      if (item.id === 1) {
        if (item.estado) {
          setAguasResiduales(true)
        } else {
          setAguasResiduales(false)
        }
      }
      if (item.id === 2) {
        if (item.estado) {
          setConstruccion(true)
        } else {
          setConstruccion(false)
        }
      }
      if (item.id === 3) {
        if (item.estado) {
          setEmisionesProcesos(true)
        } else {
          setEmisionesProcesos(false)
        }
      }
      if (item.id === 4) {
        if (item.estado) {
          setEnergia(true)
        } else {
          setEnergia(false)
        }
      }
      if (item.id === 5) {
        if (item.estado) {
          setEntradaBienesServicios(true)
        } else {
          setEntradaBienesServicios(false)
        }
      }
      if (item.id === 6) {
        if (item.estado) {
          setFinVida(true)
        } else {
          setFinVida(false)
        }
      }
      if (item.id === 7) {
        if (item.estado) {
          setResiduosSolidos(true)
        } else {
          setResiduosSolidos(false)
        }
      }

      if (item.id === 8) {
        if (item.estado) {
          setTransporteMercancias(true)
        } else {
          setTransporteMercancias(false)
        }
      }
      if (item.id === 9) {
        if (item.estado) {
          setTransportePasajeros(true)
        } else {
          setTransportePasajeros(false)
        }
      }
      if (item.id === 10) {
        if (item.estado) {
          setUso(true)
        } else {
          setUso(false)
        }
      }
      if (item.id === 11) {
        if (item.estado) {
          setUsoSuelos(true)
        } else {
          setUsoSuelos(false)
        }
      }
    })
  }, [data])
  return (
    <div className={classes.container}>
      {!aguasResiduales && (
        <Tooltip title="Aguas residuales">
          <img src={AguasResiduales} />
        </Tooltip>
      )}
      {aguasResiduales && (
        <Tooltip title="Aguas residuales">
          <img src={AguasResidualesOn} />
        </Tooltip>
      )}
      {!construccion ? (
        <Tooltip title="Construcción">
          <img src={ConstruccionIcon} />
        </Tooltip>
      ) : (
        <Tooltip title="Construcción">
          <img src={ConstruccionOn} />
        </Tooltip>
      )}
      {!emisionesProcesos && (
        <Tooltip title="Emisiones de procesos">
          <img src={EmisionesProcesos} />
        </Tooltip>
      )}
      {emisionesProcesos && (
        <Tooltip title="Emisiones de procesos">
          <img src={EmisionesProcesosOn} />
        </Tooltip>
      )}
      {!energia && (
        <Tooltip title="Energía">
          <img src={EnergiaIcon} />
        </Tooltip>
      )}
      {energia && (
        <Tooltip title="Energía">
          <img src={EnergiaOn} />
        </Tooltip>
      )}
      {!entradaBienesServicios && (
        <Tooltip title="Compra de bienes y servicios">
          <img src={EntradaBienesServicios} />
        </Tooltip>
      )}
      {entradaBienesServicios && (
        <Tooltip title="Compra de bienes y servicios">
          <img src={EntradaBienesServiciosOn} />
        </Tooltip>
      )}
      {!finVida && (
        <Tooltip title="Fin de vida">
          <img src={FinVida} />
        </Tooltip>
      )}
      {finVida && (
        <Tooltip title="Fin de vida">
          <img src={FinVidaOn} />
        </Tooltip>
      )}
      {!residuosSolidos && (
        <Tooltip title="Residuos sólidos">
          <img src={ResiduosSolidos} />
        </Tooltip>
      )}
      {residuosSolidos && (
        <Tooltip title="Residuos sólidos">
          <img src={ResiduosSolidosOn} />
        </Tooltip>
      )}
      {!transporteMercancias && (
        <Tooltip title="Trasporte de mercancias">
          <img src={TransporteMercancias} />
        </Tooltip>
      )}
      {transporteMercancias && (
        <Tooltip title="Trasporte de mercancias">
          <img src={TransporteMercanciasOn} />
        </Tooltip>
      )}
      {!transportePasajeros && (
        <Tooltip title="Trasporte de pasajeros">
          <img src={TransportePasajeros} />
        </Tooltip>
      )}
      {transportePasajeros && (
        <Tooltip title="Trasporte de pasajeros">
          <img src={TransportePasajerosOn} />
        </Tooltip>
      )}
      {!uso && (
        <Tooltip title="Uso">
          <img src={Uso} />
        </Tooltip>
      )}
      {uso && (
        <Tooltip title="Uso">
          <img src={UsoOn} />
        </Tooltip>
      )}
      {!usoSuelos && (
        <Tooltip title="Uso del suelo">
          <img src={UsoSuelo} />
        </Tooltip>
      )}
      {usoSuelos && (
        <Tooltip title="Uso del suelo">
          <img src={UsoSueloOn} />
        </Tooltip>
      )}
    </div>
  )
}

ListFuentes.propTypes = {
  data: PropTypes.array
}

ListFuentes.defaultProps = {
  data: []
}

export default ListFuentes
