import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardFooter from 'components/Card/Footer'
import logo from 'assets/img/neutral_logo.png'
import logoBCIE from 'assets/img/bcie_logo.png'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  img: {
    width: '200px',
    margin: 'auto',
    verticalAlign: 'middle',
    border: '0',
    [theme.breakpoints.down('xs')]: {
      width: '100px'
    }
  },
  container: {
    width: '621px',
    margin: 'auto',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  separator: {
    width: '1px',
    height: '90px',
    marginTop: '25px',
    backgroundColor: '#e0e0e0'
  }
}))

const Invitacion = () => {
  const classes = useStyles()
  const [name, setName] = useState('')
  useEffect(() => {
    const name = location.search.split('=')[1]
    setName(decodeURI(name))
  }, [])

  return (
    <div>
      <GridContainer>
        <div className={classes.container}>
          <img src={logoBCIE} alt="logo" className={classes.img} />
          <div className={classes.separator} />
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={6} style={{ margin: 'auto' }}>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'center', marginTop:'50px' }}>
              <h6 style={{textAlign: 'center', textDecoration: 'none'}}>
                Gracias por completar la información solicitada.
              </h6>
            </CardHeader>
            <CardFooter style={{ margin: '10px auto 0px auto' }}></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default Invitacion
