import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import { Style } from '../style'
import CustomInput from 'components/CustomInput'
import CardFooter from 'components/Card/Footer/index'
import ListFuentes from 'components/ListFuentes'
import ProyectoService from '../../../services/proyecto'
import { useToasts } from 'react-toast-notifications'
import {
  MenuItem,
  Select,
  InputLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  TextField
} from '@material-ui/core'

import Error from 'components/Error'
import CatalogoService from 'services/catalogos'
import { Close } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { useLocation } from 'react-router'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import NumberInput from 'components/NumberInput/index'
import { ArrowBackIos } from '@material-ui/icons/index'

const useStyles = makeStyles(Style)
const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Preview = ({ user, token, history }) => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const { addToast } = useToasts()
  const [tipo, setTipo] = useState()
  const [pais, setPais] = useState()
  const [id, setId] = useState(0)
  const [fases, setFases] = useState([])
  const [catFases, setCatFases] = useState([])
  const [familia, setFamilia] = useState([])
  const [tipos, setTipos] = useState([])
  const [paises, setPaises] = useState([])
  const [observaciones, setObservaciones] = useState('')
  const [descripcion, setDescription] = useState('')
  const [analisis, setAnalisis] = useState('')
  // datos del proyecto
  const [nombre, setNombre] = useState('')
  const [anio, setAnio] = useState('')
  const [monto, setMonto] = useState('')
  const [etapaActual, setEtapaActual] = useState('')
  const [fechaInicio, setFechaInicio] = useState('')
  const [duracionFaseConstrucion, setDuracionFaseConstrucion] = useState(0)
  const [duracionFaseOperativa, setDuracionFaseOperativa] = useState(0)
  const [finProyecto, setFinProyecto] = useState(0)
  const [fechaInicioMedicion, setFechaInicioMedicion] = useState('')

  const [errors, setErrors] = useState([])
  const location = useLocation()
  const [listMoneda, setListMoneda] = useState([])
  const [moneda, setMoneda] = useState('')

  useEffect(async () => {
    setLoading(true)
    try {
      const { id } = location.state
      if (id !== undefined && id !== 0) {
        setId(id)
        loadData(id)
      }
    } catch (e) {
      history.push(`lista-proyecto`)
    }
    try {
      const respTipoProyecto = await CatalogoService.getTipoProyecto(token)
      if (respTipoProyecto.status === 200) {
        const data = respTipoProyecto.data.map((item) => {
          return { label: item.nombre, value: item.id }
        })
        setTipos(data)
      }
    } catch (e) {
      history.push(`lista-proyecto`)
    }
    const paises = await CatalogoService.getPaises(token)
    if (paises.status === 200) {
      const paisesData = paises.data.map((item) => {
        return { label: item.nombre, value: item.id }
      })
      setPaises(paisesData)
    }
    const moneda = await CatalogoService.getMoneda(token)
    if (moneda.status === 200) {
      const monedaData = moneda.data.map((item) => {
        return { label: item.nombre, value: item.id }
      })
      setListMoneda(monedaData)
    }

    try {
      const catfase = await CatalogoService.getFaseProyecto(token)
      if (catfase.status === 200) {
        const catFaseData = catfase.data.map((item) => {
          return { label: item.nombre, value: item.id }
        })
        setCatFases(catFaseData)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }, [])

  const loadData = async (id) => {
    try {
      const resp = await ProyectoService.getProyecto(id, token)
      if (resp.status === 200) {
        const data = resp.data
        setFases(data.fases)
        setPais(data.pais.id)
        setTipo(data.tipo_proyecto.id)
        setFamilia(data.familia)
        setNombre(data.nombre)
        setAnio(data.anio)
        setMonto(data.monto)
        setEtapaActual(data.estapa_actual.id)
        setFechaInicio(data.fecha_inicio)
        setDuracionFaseConstrucion(data.duracion_fase_construccion)
        setDuracionFaseOperativa(data.duracion_fase_operativa)
        setFinProyecto(data.fin_proyecto)
        setFechaInicioMedicion(data.fecha_inicio_medicion)
        setObservaciones(data.observaciones)
        setAnalisis(data.analisis)
        setMoneda(data.moneda.id)
      }
    } catch (e) {}
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <h4>Tipo de proyecto</h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="label_proy"> Tipo de proyecto</InputLabel>
                  {tipo && (
                    <Select
                      labelId="label_proy"
                      value={tipo}
                      disabled
                      defaultValue={tipo}
                      label="Tipo de proyecto"
                    >
                      {tipos.map((item, i) => (
                        <MenuItem
                          key={`id_tipo_${i.toString()}`}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="label_anio">Pais</InputLabel>
                  {pais && (
                    <Select
                      labelId="label_anio"
                      value={pais}
                      defaultValue={pais}
                      label="Pais"
                      disabled
                      onChange={(e) => setPais(e.target.value)}
                    >
                      {paises.map((item, i) => (
                        <MenuItem
                          key={`id_tipo_${i.toString()}`}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div
              style={{ cursor: 'pointer', marginTop: '10px' }}
              onClick={() => {
                history.goBack()
              }}
            >
              <IconButton aria-label="atras" size="small">
                <ArrowBackIos fontSize="inherit" />
              </IconButton>
              <label> Atras</label>
            </div>
            <p>
              Actividades emisoras de Gases de Efecto Invernadero (GEI) -
              (*Categorías por defecto)
            </p>
            <div>
              <ListFuentes data={familia} />
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Nombre del Proyecto"
                  top
                  required
                  id="name"
                  disabled={true}
                  name="name"
                  value={nombre}
                  defaultValue={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />

                {errors.name && <Error>Campo es requerido</Error>}
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <NumberInput
                  labelText="Año"
                  disabled={true}
                  id="anio"
                  name="anio"
                  top={false}
                  value={anio}
                  defaultValue={anio}
                  onChange={(e) => setAnio(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />

                {errors.name && <Error>Campo es requerido</Error>}
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <NumberInput
                  labelText="Monto"
                  id="monto"
                  disabled={true}
                  name="monto"
                  value={monto}
                  defaultValue={monto}
                  onChange={(e) => setMonto(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {errors.description && <Error>Campo es requerido</Error>}
              </GridItem>

              <GridItem xs={12} sm={12} md={1}>
                <FormControl fullWidth required>
                  <InputLabel id="label_moneda"> Moneda</InputLabel>
                  {listMoneda && catFases && (
                    <Select
                      labelId="label_moneda"
                      value={moneda}
                      disabled={true}
                      defaultValue={moneda}
                      label="Moneda"
                      onChange={(e) => setMoneda(e.target.value)}
                    >
                      {listMoneda.map((item, i) => (
                        <MenuItem
                          key={`id_tipo_${i.toString()}`}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <FormControl fullWidth required>
                  <InputLabel id="label_etapa">Etapas del Proyecto</InputLabel>
                  {catFases && (
                    <Select
                      labelId="label_etapa"
                      value={etapaActual}
                      disabled={true}
                      defaultValue={etapaActual}
                      label="Etapa Actual"
                      onChange={(e) => setEtapaActual(e.target.value)}
                    >
                      {catFases.map((item, i) => (
                        <MenuItem
                          key={`id_tipo_${i.toString()}`}
                          value={item.value}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell className={classes.tableHeadCell}>
                      Fase
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Actividad de las emisiones
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Origen de las emisiones
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Clasificación de las emisiones
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Datos de entrada
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Alcance de fuente de GEI
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Emisión actual (tCO<small>2e</small>)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fases.map((p, key) => (
                    <TableRow
                      key={`key_table${key.toString()}`}
                      className={classes.tableBodyRow}
                    >
                      <TableCell className={classes.tableCell}>
                        {p.nombre}
                        {p.obligatorio && (
                          <span style={{ color: '#15BD85', fontSize: '14px' }}>
                            *
                          </span>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {p.familia.nombre}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {p.origen_emision.nombre}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {p.calificacion.nombre}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {p.datos_recomplilar}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        Alcance {p.alcance}
                      </TableCell>
                      <TableCell className={classes.tableCell}>0.0</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GridContainer>
          </CardBody>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ marginTop: '15px' }}>
                  <TextField
                    fullWidth
                    onChange={(e) => setFechaInicio(e.target.value)}
                    type="date"
                    disabled={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                    defaultValue={fechaInicio}
                    value={fechaInicio}
                    label="Fecha de Inicio del proyecto"
                    variant="standard"
                  />
                </div>
                {errors.inicio && <Error>Campo es requerido</Error>}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <NumberInput
                  labelText="Duración Fase Construcción (Años)"
                  disabled={true}
                  top
                  value={duracionFaseConstrucion}
                  defaultValue={duracionFaseConstrucion}
                  onChange={(e) => setDuracionFaseConstrucion(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {errors.anios_fase && <Error>Campo es requerido</Error>}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <NumberInput
                  labelText="Duración Fase Operativa (Años)"
                  disabled={true}
                  top
                  value={duracionFaseOperativa}
                  defaultValue={duracionFaseOperativa}
                  onChange={(e) => setDuracionFaseOperativa(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />

                {errors.duracion && <Error>Campo es requerido</Error>}
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <NumberInput
                  labelText="Fin del proyecto (Años)"
                  disabled={true}
                  top
                  value={finProyecto}
                  defaultValue={finProyecto}
                  onChange={(e) => setFinProyecto(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {errors.fin && <Error>Campo es requerido</Error>}
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                <div style={{ marginTop: '15px' }}>
                  <TextField
                    fullWidth
                    onChange={(e) => setFechaInicioMedicion(e.target.value)}
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled
                    value={fechaInicioMedicion}
                    label="Fecha de inicio de medición"
                    variant="standard"
                  />
                </div>

                {errors.inicio_medicion && <Error>Campo es requerido</Error>}
              </GridItem>
            </GridContainer>
          </CardBody>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h5> Observaciones importantes (*AFD)</h5>
                <p>{observaciones}</p>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Descripción del proyecto"
                  top
                  id="descripcion"
                  disabled={true}
                  name="descripcion"
                  value={analisis}
                  defaultValue={analisis}
                  onChange={(e) => setAnalisis(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {errors.description && <Error>Campo es requerido</Error>}
              </GridItem>
            </GridContainer>
          </CardBody>

          <CardFooter>
            <GridItem xs={12} sm={12} md={3}></GridItem>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withAccessGranted(Preview, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
