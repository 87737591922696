import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Clear, Check } from '@material-ui/icons/Clear'
import styles from './style'

const useStyles = makeStyles(styles)

const CustomSelect = ({
  data,
  formControlProps,
  labelText,
  id,
  labelProps,
  inputProps,
  defaultValue,
  error,
  top,
  name,
  value,
  success,
  required,
  ...props
}) => {
  const classes = useStyles()
  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  })
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + ' ' + classes.formControl}
    >
      <InputLabel
        className={classes.labelRoot + labelClasses}
        htmlFor={id}
        required={required}
        {...labelProps}
      >
        {labelText}
      </InputLabel>
      <Select
        classes={{
          disabled: classes.disabled
        }}
        value={value}
        required={required}
        defaultValue={defaultValue}
        {...props}
        {...inputProps}
      >
        {data.map((item, index) => (
          <MenuItem
            key={`${name}_index_${index.toString()}`}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {error ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  )
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  data: PropTypes.array,
  success: PropTypes.bool
}

CustomSelect.defaultProps = {
  data: []
}

export default CustomSelect
