import API from './../Api'

class FactorService {
  static getAll () {
    return API({
      method: 'GET',
      url: '/api/v1/factor/factor_tree/'
    })
  }
  static factoresFamilia (id, token) {
    return API({
      method: 'GET',
      url: `api/v1/factor/factor/?familia=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static factoresOrigenEmision (id, token) {
    return API({
      method: 'GET',
      url: `api/v1/factor/factor/?familia=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static ratiosFamilia (id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/factor/ratio/?familia=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static getFactores (token) {
    return API({
      method: 'GET',
      url: '/api/v1/factor/factor/',
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }
  static getRatios (token) {
    return API({
      method: 'GET',
      url: '/api/v1/factor/ratio/',
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

  static getGestionFuente (token, tipo_proyecto, categoria, familia, tipo, search) {
    return API({
       method: 'GET',
       url: `/api/v1/proyecto/gestion_fuente/?tipo_proyecto=${tipo_proyecto}&categoria=${categoria}&familia=${familia}&tipo=${tipo}&buscar=${search} `,
       headers: {
         Authorization: `Token ${token}`
       }
     })   
  }
  static saveGestionFuente (token, data) {
    return API({
       method: 'POST',
       url: `/api/v1/proyecto/gestion_fuente/`,
       data,
       headers: {
         Authorization: `Token ${token}`
       }
     })   
  }
  static deleteFactorRatio (token, id, data) {

    return API({
       method: 'PUT',
       url: `/api/v1/proyecto/gestion_fuente/${id}/`,
        data,
       headers: {
         Authorization: `Token ${token}`
       }
     })   
  }
  static buscarFactores(search,proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/factor/factor/?buscar=${search}&proyecto=${proyecto}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }
  static buscarRatios(search,proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/factor/ratio/?buscar=${search}&proyecto=${proyecto}`,
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }

}

export default FactorService
