import API from './../Api'

class CatalogoService {
  static getPaises() {
    return API({
      method: 'GET',
      url: '/api/v1/catalogo/pais/'
    })
  } 

  static getTipoBanca() {
    return API({
      method: 'GET',
      url: '/api/v1/catalogo/tipobanca/'
    })
  } 
  static getTipoProyecto(token) {
    return API({
      method: 'GET',
      url: '/api/v1/catalogo/tipoproyecto/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  } 
  static getFaseProyecto(token) {
    return API({
      method: 'GET',
      url: '/api/v1/catalogo/fase/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  } 
  static getCategoria(token) {
    return API({
      method: 'GET',
      url: 'api/v1/catalogo/categoria/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  } 
  static getFamilia(token) { 
    return API({
      method: 'GET',
      url: 'api/v1/catalogo/familia/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static getMoneda(token) {
    return API({
      method: 'GET',
      url: 'api/v1/catalogo/moneda/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static lineaCredito(token, data) {
    return API({
      method: 'POST',
      url: 'api/v1/proyecto/linea_credito/',
      data: data,
      headers: {
        Authorization: 'Bearer ' + token
      } 
    })
  }

  static deleteLineaCredito(token, id) {
    return API({
      method: 'DELETE',
      url: `api/v1/proyecto/linea_credito/${id}/`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export default CatalogoService
