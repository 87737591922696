export const Style = (theme) => ({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },

  tableHeadCell: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#454545',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'normal'
    }
  },
  tableCell: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#999999'
  },
  tableActionButtonIcon: {
    width: '17px',
    height: '17px'
  }
})
