import React, { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card/index'
import CardHeader from 'components/Card/Header/index'
import CardBody from 'components/Card/Body/index'
import { useLocation } from 'react-router'
import Logo from 'assets/img/neutral_logo.png'

import {
  CircularProgress,
  makeStyles,
  Backdrop,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'
import CardFooter from 'components/Card/Footer/index'
import HuellaService from 'services/Huella/index'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import { IconButton } from '@material-ui/core/index'
import { CloudDownload } from '@material-ui/icons/index'

const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Huella = ({ history, token }) => {
  const classes = useStyles()
  const [anio, setAnio] = useState('')
  const location = useLocation()
  const [total, setTotal] = useState(0)
  const [proyectoID, setProyectoID] = useState('')
  const [proyecto, setProyecto] = useState('')
  const [loading, setLoading] = useState(false)
  const [huella, setHuella] = useState([])
  const [huella2, setHuella2] = useState([])
  const [huella3, setHuella3] = useState([])
  const [fase, setFase] = useState('')

  const loadHuella = async () => {
    try {
      const { id, anio } = location.state
      const huella = await HuellaService.getHuella(id, anio, token)
      setHuella([])
      setHuella2([])
      setHuella3([])
      if (huella.status === 200) {
        setTotal(huella.data.data.total)
        setProyecto(huella.data.data.nombre)
        if (huella.data.data.huella.alcance1) {
          setHuella(huella.data.data.huella.alcance1.valor)
        }

        if (huella.data.data.huella.alcance2) {
          setHuella2(huella.data.data.huella.alcance2.valor)
        }
        setProyecto([])
        if (huella.data.data.huella.alcance3) {
          setHuella3(huella.data.data.huella.alcance3.valor)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(async () => {
    try {
      setLoading(true)

      const { id, fase, anio } = location.state
      setProyectoID(id)
      await loadHuella()
      setAnio(anio)
      setFase(fase)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      history.push(`huella`)
    }
  }, [])

  return (
    <GridContainer>
      <Backdrop className={load.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader style={{ textAlign: 'center' }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                <img src={Logo} alt="logo" width="100%" />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h3> Inventario de GEI del proyecto {proyecto}</h3>
                <p>del 1 de enero al 31 de diciembre del año {anio}</p>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <GridItem xs={12} sm={12} md={12}>
            <p style={{ marginLeft: '30px' }}>
              Etapa de {fase === 1 ? 'Implementación' : 'Planeación'}
            </p>
          </GridItem>
          {huella.length > 0 && (
            <>
              <CardHeader style={{ textAlign: 'center' }}>
                <h3> Alcance 1</h3>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                          <TableCell className={classes.tableHeadCell}>
                            Fase
                          </TableCell>
                          <TableCell className={classes.tableHeadCell}>
                            Actividad emisora de GEI
                          </TableCell>
                          <TableCell className={classes.tableHeadCell}>
                            Origen de las emisiones
                          </TableCell>
                          <TableCell className={classes.tableHeadCell}>
                            Categoría
                          </TableCell>
                          <TableCell className={classes.tableHeadCell}>
                            Fuente de emisión de GEI
                          </TableCell>

                          <TableCell className={classes.tableHeadCell}>
                            Alcance de fuente de GEI
                          </TableCell>
                          <TableCell className={classes.tableHeadCell}>
                            Emisión actual (tCO<small>2e</small>)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {huella.map((p, key) => (
                          <TableRow
                            key={`key_table${key.toString()}`}
                            className={classes.tableBodyRow}
                          >
                            <TableCell className={classes.tableCell}>
                              {p.fase}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.origen_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.fuente_emisora}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Alcance {p.ambito}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {parseFloat(p.huella.toFixed(2)).toLocaleString(
                                'en'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </GridItem>
              </CardBody>
            </>
          )}
          {huella2.length > 0 && (
            <CardBody>
              <CardHeader style={{ textAlign: 'center' }}>
                <h3> Alcance 2</h3>
              </CardHeader>
              <GridItem xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell}>
                          Fase
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Actividad emisora de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Origen de las emisiones
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Categoría
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Fuente de emisión de GEI
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Alcance de fuente de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Emisión actual (tCO<small>2e</small>)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {huella2.map((p, key) => (
                        <TableRow
                          key={`key_table${key.toString()}`}
                          className={classes.tableBodyRow}
                        >
                          <TableCell className={classes.tableCell}>
                            {p.fase}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.actividad_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.origen_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.actividad_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.fuente_emisora}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Alcance {p.ambito}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {parseFloat(p.huella.toFixed(2)).toLocaleString(
                              'en'
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>
            </CardBody>
          )}
          {huella3.length !== 0 && (
            <CardBody>
              <CardHeader style={{ textAlign: 'center' }}>
                <h3> Alcance 3</h3>
              </CardHeader>
              <GridItem xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell}>
                          Fase
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Actividad emisora de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Origen de las emisiones
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Categoría
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Fuente de emisión de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Alcance de fuente de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Emisión actual (tCO<small>2e</small>)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {huella3.map((p, key) => (
                        <TableRow
                          key={`key_table${key.toString()}`}
                          className={classes.tableBodyRow}
                        >
                          <TableCell className={classes.tableCell}>
                            {p.fase}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.actividad_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.origen_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.actividad_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.fuente_emisora}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Alcance {p.ambito}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {parseFloat(p.huella.toFixed(2)).toLocaleString(
                              'en'
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridItem>
            </CardBody>
          )}

          <CardFooter>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <h6 style={{ textTransform: 'none' }}>
                Total de emisiones (tCO<small>2e</small>):{' '}
                {parseFloat(total.toFixed(2)).toLocaleString('en')}
              </h6>
            </div>
          </CardFooter>
          <CardFooter>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <a
                target="_blank"
                href={`${process.env.REACT_APP_SERVER_URL}api/v1/huella/huella/?pdf=1&proyecto=${proyectoID}&fase=${fase}&anio=${anio}&token=${token}`}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: 'right'
                  }}
                >
                  + Descargar huella consolidada
                  <IconButton
                    color="success"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloudDownload />
                  </IconButton>
                </div>
              </a>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
export default withAccessGranted(Huella, [
  userAccess.CONSULTA.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL,
  userAccess.ADMINUSER.ACCESS_LEVEL,
  userAccess.ADMIN.ACCESS_LEVEL
])
