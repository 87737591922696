import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Error from 'components/Error'
import CustomSelect from 'components/CustomSelect'
import Checkbox from '@material-ui/core/Checkbox'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import { Style } from '../Login/style'
import Check from '@material-ui/icons/Check'
import CatalogoService from 'services/catalogos'
import { useToasts } from 'react-toast-notifications'
import CompanyService from 'services/company/index'

const useStyles = makeStyles(Style)

const Registro = () => {
  const classes = useStyles()
  const [checked, setChecked] = useState(false)
  const [paises, setPaises] = useState([])
  const [tipoBanca, setTipoBanca] = useState([])
  const { addToast } = useToasts()
  const [nombre, setNombre] = useState('')
  const [nombreContacto, setNombreContacto] = useState('')
  const [pais, setPais] = useState('')
  const handleTerm = () => {
    setChecked(!checked)
  }

  useEffect(async () => {
    const setChecked = await CatalogoService.getPaises()
    const data = setChecked.data.map(item => {
      return { label: item.nombre, value: item.id }
    })
    setPaises(data)

    const tipoBanca = await CatalogoService.getTipoBanca()

    const responseBaca = tipoBanca.data.map(item => {
      return { label: item.nombre, value: item.id }
    })
    setTipoBanca(responseBaca)
  }, [])

  const onSubmit = async data => {
    if (!checked) {
      addToast('Acepte términos y condiciones', { appearance: 'error' })
      return
    }
    try {
      const user = await CompanyService.save(data)
      if (user.status === 201) {
        localStorage.setItem('user', JSON.stringify(user.data))
        window.location.reload()
      }
      addToast('Cuenta creada con exito', { appearance: 'success' })
      setTimeout(function () {
        setNotif({ open: false })
      }, 6000)
    } catch (error) {
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={7} style={{ margin: 'auto' }}>
          <Card>
            <Card className={classes.login}>
              <CardHeader color='primary'>
                <h4>Regístrate</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Nombre de la Organización'
                      value={nombre}
                      onChange={e => setNombre(e.target.value)}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Nombre del Contacto'
                      value={nombreContacto}
                      onChange={e => setNombreContacto(e.target.value)}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText='País'
                      value={pais}
                      onChange={e => setPais(e.target.value)}
                      data={paises}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Email de usuario'
                      type='email'
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText='Tipo de Banca'
                      data={tipoBanca}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='number'
                      labelText='Licencias'
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onClick={() => handleTerm()}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.root
                          }}
                        />
                      }
                      label='Terminos y condiciones'
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button onClick={onSubmit} type='submit' color='success'>
                  Guardar
                </Button>
              </CardFooter>
            </Card>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default Registro
