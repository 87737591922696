import React, { useEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
import { IconButton, Tooltip } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'
import CardHeader from 'components/Card/Header/index'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

Highcharts.setOptions({
  chart: {
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG',
    downloadCSV: 'Descargar CSV',
    downloadXLS: 'Descargar XLS'
  }
})

const Origen = ({ data, categories, anio }) => {
  const [origen, setOrigen] = useState({})
  const [empty, setEmpty] = useState(true)
  useEffect(() => {
    const res = []
    let subtitle =
      categories === 0 ? ': Año base' : `${anio ? ': ' + anio : ''}`

    data.map((item) => {
      let h2 = 0
      if (categories === 0) {
        h2 =
          item.etapa.referencia.ambito1 +
          item.etapa.referencia.ambito2 +
          item.etapa.referencia.ambito3
      } else if (categories === 1) {
        h2 =
          item.etapa.construccion.ambito1 +
          item.etapa.construccion.ambito2 +
          item.etapa.construccion.ambito3
      } else if (categories === 2) {
        h2 =
          item.etapa.operativa.ambito1 +
          item.etapa.operativa.ambito2 +
          item.etapa.operativa.ambito3
      }

      res.push([item.nombre, parseFloat(h2), false])
    })

    const origen = {
      chart: {
        styledMode: true
      },

      title: {
        text: 'Huella por tipo de proyecto'
      },
      subtitle: {
        text: `tCO2e${subtitle}`
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      series: [
        {
          type: 'pie',
          allowPointSelect: true,
          keys: ['name', 'y', 'selected', 'sliced'],
          data: res,
          showInLegend: true
        }
      ]
    }
    setOrigen(origen)
    if (res.length === 0) {
      setEmpty(true)
    } else {
      setEmpty(false)
    }
  }, [data, categories, anio])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                D) Emisiones consolidadas por tipo de proyecto (GHG Protocol)
                  - tCO<small>2e</small>
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se muestran las emisiones consolidadas de GEI por tipo de proyecto, y en función del año de consulta. Para la evaluación consolidada sobre la situación referencia se desactiva para el año de consulta."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'chart'}
                  options={origen}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default Origen
