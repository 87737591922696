import React, { useState, useEffect } from 'react'
import CardHeader from 'components/Card/Header/index'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'
import { makeStyles } from '@material-ui/core/index'

import style from './style'
import { IconButton, Tooltip } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'

const useStyles = makeStyles(style)

Highcharts.setOptions({
  chart: {
    backgroundColor: {
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(255, 255, 255)']
      ]
    },
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    showTable: false,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG'
  }
})

const Etapas = ({ data, title, anio, monto }) => {
  const classes = useStyles()
  const [planeacion, setPlaneacion] = useState(0)
  const [implementacion, setImplementacion] = useState(0)
  const [dif, setDif] = useState(0)
  const [planeacionOptions, setPlaneacionOptions] = useState({})
  const [implementacionOptions, setImplementacionOptions] = useState({})
  useEffect(() => {
    const { planeacion, implementacion } = data
    const plane =
      parseFloat(planeacion.construccion) + parseFloat(planeacion.operativa)
    setPlaneacion(plane)
    const imp =
      parseFloat(implementacion.construccion) +
      parseFloat(implementacion.operativa)
    setImplementacion(imp)
    const dif = imp - plane
    setDif(dif)
    const plan = {
      chart: {
        type: 'column'
      },
      title: {
        text: `${title}: Planeación`
      },
      subtitle: {
        text: `tCO2e Planeación: ${anio}`
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      xAxis: {
        categories: ['Construcción', 'Operativa']
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },
      yAxis: {
        title: {
          text: 'tCO2e'
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value)
          }
        }
      },
      credits: {
        enabled: false
      },
      colorAxis: [
        {
          maxColor: '#000fb0',
          minColor: '#e3e5ff',
          labels: {
            format: '{value}%'
          },
          reversed: true
        },
        {
          minColor: '#ffece8',
          maxColor: '#8a1900',
          labels: {
            format: '{value}%'
          }
        }
      ],
      series: [
        {
          name: 'tCO2e',
          color: '#A3A3A3',
          data: [
            parseFloat(planeacion.construccion.toFixed(2)),
            parseFloat(planeacion.operativa.toFixed(2))
          ]
        }
      ]
    }
    setPlaneacionOptions(plan)
    const implementacionOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: `${title}: Implementación`
      },
      subtitle: {
        text: `tCO2e Implementación: ${anio}`
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      xAxis: {
        categories: ['Construcción', 'Operativa']
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}'
          }
        }
      },
      yAxis: {
        title: {
          text: 'tCO2e'
        },
        labels: {
          formatter: function () {
            return Math.abs(this.value)
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'tCO2e',
          color: '#A3A3A3',
          data: [
            parseFloat(implementacion.construccion.toFixed(2)),
            parseFloat(implementacion.operativa.toFixed(2))
          ]
        }
      ]
    }
    setImplementacionOptions(implementacionOptions)
  }, [data])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                  E) Emisiones por Etapa (Planeación / Implementación) - tCO2e
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se muestran las emisiones de GEI por fases de construcción y operativas, comparando las emisiones supuestas para la etapa de planeación con las emisiones reales de la etapa de implementación para un año específico de actividades. Si las emisiones de la etapa de implementación son menores a las emisiones de la etapa de planeación, entonces el proyecto demuestra mitigar emisiones de GEI."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ minHeight: '200px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={planeacionOptions}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div style={{ minHeight: '200px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={implementacionOptions}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.info}>
                  &gt; Planeación:{' '}
                  <span>
                    {parseFloat(planeacion.toFixed(2)).toLocaleString('en')}{' '}
                    tCO2e
                  </span>
                </div>
                <div className={classes.info}>
                  &gt; Implementación:{' '}
                  <span>
                    {parseFloat(implementacion.toFixed(2)).toLocaleString('en')}{' '}
                    tCO2e
                  </span>
                </div>
                <div className={classes.info}>
                  &gt; Diferencia:{' '}
                  {planeacion !== dif ? (
                    <span className={classes.green}>
                      {parseFloat(dif.toFixed(2)).toLocaleString('en')} tCO2e (
                      {((dif / planeacion) * 100).toFixed(2)} %)
                    </span>
                  ) : (
                    <span className={classes.green}>N/A</span>
                  )}
                </div>
              </GridItem>
            </GridContainer>
          </CardHeader>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default Etapas
