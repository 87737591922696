import React, { useState, useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  ButtonGroup
} from '@material-ui/core'

import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CardHeader from 'components/Card/Header'
import CardBody from 'components/Card/Body'

import userAccess from 'userAccess'
import withAccessGranted from 'components/withAuth/index'
import CatalogoService from 'services/catalogos/index'
import ProyectoService from 'services/proyecto/index'
import HuellaService from 'services/Huella/index'
import DashboardService from 'services/Dashboard/index'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import Alcance from './Alcance'
import Fases from './Fases'
import Etapas from './Etapas'
import Factor from './Factor'
import FactorImplementacion from './FactorImplementacion'
import ListFuentes from 'components/ListFuentes'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Dashboard = ({ token }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [tipoProyecto, setTipoProyecto] = useState([])
  const [paises, setPaiese] = useState([])
  const [tipo, setTipo] = useState(0)
  const [pais, setPais] = useState(0)
  const [proyectos, setProyectos] = useState([])
  const [proyecto, setProyecto] = useState()
  const [anios, setAnios] = useState([])
  const [aniosSelected, setAniosSelected] = useState([])
  const [familia, setFamilia] = useState([])

  // dash

  const [title, setTitle] = useState('')
  const [alcance, setAlcance] = useState()
  const [fase, setFase] = useState()
  const [etapa, setEtapa] = useState()
  const [monto, setMonto] = useState(0)
  const [actividad, setActividad] = useState()

  useEffect(async () => {
    try {
      setLoading(true)
      try {
        const respTipoProyecto = await CatalogoService.getTipoProyecto(token)
        if (respTipoProyecto.status === 200) {
          setTipoProyecto(respTipoProyecto.data)
        }
        const paises = await CatalogoService.getPaises(token)
        if (paises.status === 200) {
          setPaiese(paises.data)
        }
        const resp = await ProyectoService.getAll(token)
        if (resp.status === 200) {
          setProyectos(resp.data)
        }

        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    } catch (error) {}
  }, [])

  const handleTipo = async (event) => {
    setTipo(event)
    setLoading(true)
    setAnios([])
    try {
      const resp = await ProyectoService.getAll(token)
      if (resp.status === 200) {
        if (event === 0) {
          if (pais > 0) {
            const proy = resp.data.filter((p) => p.pais.id === pais)
            setProyectos(proy)
          } else {
            setProyectos(resp.data)
          }
        } else {
          const proyectos = resp.data.filter((item) => {
            return item.tipo_proyecto.id === event
          })
          if (pais > 0) {
            const proy = proyectos.filter((p) => p.pais.id === pais)
            setProyectos(proy)
          } else {
            setProyectos(proyectos)
          }
        }
        setLoading(false)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handlePais = async (event) => {
    setPais(event)
    setLoading(true)
    try {
      const resp = await ProyectoService.getAll(token)
      if (resp.status === 200) {
        if (event === 0) {
          if (tipo > 0) {
            const proyectos = resp.data.filter((item) => {
              return item.tipo_proyecto.id === tipo
            })
            setProyectos(proyectos)
          } else {
            setProyectos(resp.data)
          }
        } else {
          const proyectos = resp.data.filter((item) => {
            return item.pais.id === event
          })
          console.log(tipo, proyectos)
          if (tipo > 0) {
            const proy = proyectos.filter((item) => {
              return item.tipo_proyecto.id === tipo
            })
            setProyectos(proy)
          } else {
            setProyectos(proyectos)
          }
        }
        setLoading(false)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleData = async (anio, proyecto) => {
    setAniosSelected(anio)
    const data = await DashboardService.emisionesAlcance(proyecto, anio, token)
    if (data.status === 200) {
      setAlcance(data.data)
      setFamilia(data.data.familia)
    }
    const fase = await DashboardService.emisionesFases(proyecto, anio, token)
    if (fase.status === 200) {
      setFase(fase.data)
    }
    const faseAlcance = await DashboardService.emisionesFasesAlcance(
      proyecto,
      anio,
      token
    )

    if (faseAlcance.status === 200) {
      setEtapa(faseAlcance.data)
    }
    const actividad = await DashboardService.getEmisionesActividad(
      proyecto,
      anio,
      token
    )

    if (actividad.status === 200) {
      setActividad(actividad.data)
    }
  }

  const handleProyecto = async (value) => {
    setLoading(true)
    const proy = proyectos.find((item) => {
      return item.id === value
    })
    setMonto(proy.monto)

    setTitle(proy.nombre)
    setProyecto(value)
    const aniosResponse = await HuellaService.getAnios(value, token)

    if (aniosResponse.status === 200) {
      setAnios(aniosResponse.data)

      try {
        if (aniosResponse.data.length > 0) {
          setAniosSelected(aniosResponse.data[0].valor)
          handleData(aniosResponse.data[0].valor, value)
        } else {
          const year = new Date().getYear()
          setAniosSelected(year)
          handleData(year, value)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardHeader color="success">
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <h4> Tipo de proyecto</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="tipo_proyecto">Tipo de Proyecto</InputLabel>
              <Select
                labelId="tipo_proyecto"
                id="demo-simple-select"
                value={tipo}
                label="Tipo de Proyecto"
                onChange={(e) => handleTipo(e.target.value)}
              >
                <MenuItem value={0}>Todos</MenuItem>
                {tipoProyecto.map((item, i) => (
                  <MenuItem key={`tipo_id_${i.toString()}`} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="tipo_proyecto">País</InputLabel>
              <Select
                labelId="pais"
                value={pais}
                label="País"
                onChange={(e) => handlePais(e.target.value)}
              >
                <MenuItem value={0}>Todos</MenuItem>

                {paises.map((item, i) => (
                  <MenuItem key={`paise_id_${i.toString()}`} value={item.id}>
                    {item.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <h5>Actividades emisoras de Gases de Efecto Invernadero (GEI)</h5>
        <ListFuentes data={familia} />
      </CardBody>
      <CardHeader>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="proyecto">Proyecto</InputLabel>
              <Select
                labelId="proyecto"
                value={proyecto}
                label="Proyecto"
                onChange={(e) => handleProyecto(e.target.value)}
              >
                {proyectos.map((item, i) => (
                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="tipo_proyecto">Año</InputLabel>
              <Select
                labelId="tipo_proyecto"
                id="demo-simple-select"
                value={aniosSelected}
                label="Año"
                onChange={(e) => handleData(e.target.value)}
              >
                {anios.map((item, i) => (
                  <MenuItem value={item.valor}>{item.valor}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        {!alcance && !actividad && !actividad && !fase && !etapa && (
          <div style={{ textAlign: 'center' }}>
            <h4>
              De los proyectos seleccionados no tenemos datos para graficar
            </h4>
          </div>
        )}
        {alcance && (
          <Alcance data={alcance} title={title} anio={aniosSelected} />
        )}
        {actividad && (
          <Factor
            anio={aniosSelected}
            title={title}
            data={actividad.construccion}
          />
        )}
        {actividad && (
          <FactorImplementacion
            anio={aniosSelected}
            title={title}
            data={actividad.operativa}
          />
        )}
        {fase && <Fases data={fase} title={title} anio={aniosSelected} />}
        {etapa && (
          <Etapas
            data={etapa}
            title={title}
            anio={aniosSelected}
            monto={monto}
          />
        )}
      </CardBody>
    </div>
  )
}

export default withAccessGranted(Dashboard, [
  userAccess.CONSULTA.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL,
  userAccess.ADMINUSER.ACCESS_LEVEL,
  userAccess.ADMIN.ACCESS_LEVEL
])
