import React, { useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import UserService from 'services/user'
import { useToasts } from 'react-toast-notifications'
import Error from 'components/Error/index'
import { useLocation } from 'react-router'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import {
  Backdrop,
  makeStyles,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Link,
  Paper
} from '@material-ui/core'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import CatalogoService from 'services/catalogos/index'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { Style } from '../style'
import { Description } from '@material-ui/icons/index'

const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))
const useStyles = makeStyles(Style)

const UserProfile = ({ history, token, user }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [hasError, setHasError] = useState(false)
  const [errors, setErros] = useState({
    name: '',
    email: '',
    tipoUsuario: ''
  })
  const location = useLocation()
  const { addToast } = useToasts()
  const [tipoUsuario, setTipoUsuario] = useState('')
  const [tiposUsuarios, setTiposUsuarios] = useState([])
  const [tipoProyectos, setTipoProyectos] = useState([])
  const [tipoProyecto, setTipoProyecto] = useState('')
  const [sectedTodos, setSectedTodos] = useState(false)
  const [id, setId] = useState(0)

  useEffect(async () => {
    setLoading(true)
    await listTipoUsuario()

    if (location.state !== undefined) {
      let id = location.state.id
      const resul = await UserService.getById(id, token)
      if (resul.status === 200) {
        setLoading(false)
        setId(resul.data.id)
        setName(resul.data.name)
        setEmail(resul.data.email)
        setTipoUsuario(resul.data.tipo_usuario.id)
        const tiposProyectos = resul.data.tipo_proyecto.map((tipo) => {
          return { label: tipo.nombre, value: tipo.id, estado: tipo.estado }
        })
        setTipoProyectos(tiposProyectos)
      }
    } else {
      await listTipoProyecto()
    }
  }, [])
  const listTipoUsuario = async () => {
    const tipoUsuario = await UserService.tipoUsuario(token)
    if (tipoUsuario.status === 200) {
      const tipos = tipoUsuario.data.map((tipo) => {
        return { label: tipo.nombre, value: tipo.id }
      })

      setTiposUsuarios(tipos)
    }
  }

  const listTipoProyecto = async () => {
    const tipoProyecto = await CatalogoService.getTipoProyecto(token)

    if (tipoProyecto.status === 200) {
      const tiposProyectos = tipoProyecto.data.map((tipo) => {
        return { label: tipo.nombre, value: tipo.id, estado: false }
      })
      setTipoProyectos(tiposProyectos)
    }
  }

  const handleChange = (e) => {
    const selected = !sectedTodos
    setSectedTodos(selected)
    const resul = tipoProyectos.map((tipo) => {
      tipo.estado = selected
      return tipo
    })
    setTipoProyectos(resul)
  }

  const handleDownload = async () => {
    window.open(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/usuario/reporte/?user=${id}&token=${token}`
    )
    window.open(
      `https://www.neutralbank.app/api/api/v1/usuario/reporte/?token=${token}`
    )
  }

  const handleSelected = (data) => {
    const tipoProyecto = tipoProyectos.map((tipo) => {
      if (tipo.value === data.value) {
        tipo.estado = !data.estado
      }
      return tipo
    })
    setTipoProyectos(tipoProyecto)
  }

  const onSubmit = async () => {
    setLoading(true)
    if (!name) {
      errors.name = 'El nombre es requerido'
      setHasError(true)
    }
    if (!email) {
      errors.email = 'El email es requerido'
      setHasError(true)
    }
    if (!tipoUsuario) {
      errors.tipoUsuario = 'El tipo de usuario es requerido'
      setHasError(true)
    }
    if (hasError) {
      setErros(errors)
      return
    }
    const data = {
      name,
      email,
      tipo_usuario: tipoUsuario,
      tipo_proyecto: tipoProyectos
    }

    try {
      if (id > 0) {
        await UserService.edit(id, data, token)
        addToast('Operación realizada con exito', { appearance: 'success' })
      } else {
        await UserService.create(data, token)
        addToast('Operación realizada con exito', { appearance: 'success' })
      }
      history.push(`userlist`)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }

  return (
    <div>
      <GridContainer>
        <Backdrop className={load.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4>Gestion de usuario</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    required
                    value={name}
                    disabled={false}
                    defaultValue={name}
                    labelText="Nombre"
                    onChange={(e) => setName(e.target.value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={errors.name ? true : false}
                  />
                  {hasError && errors.name && (
                    <Error variant="inherit" color="danger">
                      {errors.name}
                    </Error>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    required
                    disabled={false}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    labelText="Correo"
                    defaultValue={email}
                    margin="dense"
                    error={errors.email ? true : false}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  {hasError && errors.email && (
                    <Error>Campo es requerido</Error>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomSelect
                    labelText="Tipo de usuario"
                    required
                    disabled={false}
                    defaultValue={tipoUsuario}
                    value={tipoUsuario}
                    data={tiposUsuarios}
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange={(e) => setTipoUsuario(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <CardBody>
                  <GridContainer>
                    <GridItem md={10} />

                    <GridItem md={2}>
                      <div style={{ marginTop: '15px' }}>
                        <Link
                          onClick={() => handleDownload()}
                          variant="contained"
                          style={{ paddingTop: '10px', cursor: 'pointer' }}
                        >
                          <Description />{' '}
                          <span
                            style={{ position: 'absolute', marginTop: '-2px' }}
                          >
                            {' '}
                            Descargar log de usuarios
                          </span>
                        </Link>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <Card>
                    <CardHeader color="success">
                      Lista de proyectos de inversión
                    </CardHeader>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  Seleccione los proyectos en los cuales
                                  gestionarán las emisiones de GEI
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{ marginRight: '10px' }}
                                >
                                  <label style={{ paddingRight: '10px' }}>
                                    Todos
                                  </label>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={sectedTodos}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                      />
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tipoProyectos.map((row, i) => (
                                <TableRow key={`paies_id${i.toString()}`}>
                                  <TableCell component="th" scope="row">
                                    {row.label}
                                  </TableCell>
                                  <TableCell align="right">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={row.estado}
                                          onClick={() => handleSelected(row)}
                                          name="checkedB"
                                          color="primary"
                                        />
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </GridItem>
                    </GridContainer>
                  </Card>
                </CardBody>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button type="button" onClick={onSubmit} color="success">
                Guardar
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
export default withAccessGranted(UserProfile, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.ADMINUSER.ACCESS_LEVEL
])
