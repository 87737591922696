import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import { useToasts } from 'react-toast-notifications'
import logo from 'assets/img/neutral_logo.png'
import logoBCIE from 'assets/img/bcie_logo.png'
import ProyectoService from 'services/proyecto/index'
import RegistroModal from './registro'
import Button from 'components/CustomButtons'
import {
  CircularProgress,
  TableContainer,
  Backdrop,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'
import styles from './style'

const useStyles = makeStyles(styles)

const Invitacion = ({ location }) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [registro, setRegistro] = useState([])
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState('')
  const [proyectName, setProyectName] = useState('')
  const [etapa, setEtapa] = useState('')
  const [fase, setFase] = useState('')
  const [origenEmision, setOrigenEmision] = useState('')
  const [anio, setAnio] = useState('')
  const [name, setName] = useState('')
  const loadRegistro = async (token) => {
    try {
      const reg = await ProyectoService.openInvitation(token)
      setRegistro(reg.data.register)
      setProyectName(reg.data.pro_name)
      setName(reg.data.nombre_persona)
      setAnio(reg.data.anio)
      setLoading(false)
      setEtapa(reg.data.fase.cat_fase.nombre)
      setOrigenEmision(reg.data.fase.origen_emision.nombre)
      setFase(reg.data.fase.nombre)

      if (reg.data.register.length === 0) {
        console.log('no hay registros')
        window.location.href = '/'
      }
    } catch (err) {
      console.log(err)
      addToast('Herror en el proceso', {
        appearance: 'error',
        autoDismiss: true
      })
      window.location.href = '/'
    }
  }
  useEffect(() => {
    const token = location.search.split('=')[1]
    setToken(token)
    loadRegistro(token)
  }, [])
  const updateData = (i, data) => {
    registro[i].data = data
    setRegistro(registro)
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const dat = await ProyectoService.saveInvitation(registro, token)

      if (dat.status === 200 || dat.status === 201) {
        addToast('Registro guardado', {
          appearance: 'success',
          autoDismiss: true
        })
        window.location.href = `/gracias?name=${encodeURI(name)}`
      } else {
        addToast('Error al guardar el registro', {
          appearance: 'error'
        })
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer>
        <div className={classes.container}>
          <img src={logoBCIE} alt="logo" className={classes.img} />
          <div className={classes.separator} />
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={8} style={{ margin: 'auto' }}>
          {registro && registro.length > 0 && (
            <Card chart>
              <CardHeader color="primary" style={{ textAlign: 'center' }}>
                <h4>Formulario de Datos de Actividad</h4>
              </CardHeader>
              <CardBody className={classes.login}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <h4>Estimado: {name}</h4>
                  <h6>
                    El Banco Centroamericano de Integración Económica (BCIE) le
                    solicita completar la siguiente información sobre las
                    fuentes emisoras de CO<small>2e</small> relacionadas con su
                    proyecto: <b>{proyectName}</b>.
                  </h6>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  Periodo a registrar: <b>{anio}</b>
                  <br />
                  Etapa: <b>{etapa}</b>
                  <br />
                  Fase: <b>{fase}</b>
                  <br />
                  Origen de las emisiones: <b>{origenEmision}</b>
                  <br /> <br /> <br />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell component="th">Categoría</TableCell>
                          <TableCell component="th">
                            Fuente de emisión de GEI
                          </TableCell>
                          <TableCell component="th">
                            Dato de actividad
                          </TableCell>
                          <TableCell component="th">Unidad de medida</TableCell>
                          <TableCell component="th" align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      {registro && (
                        <TableBody>
                          {registro.map((row, i) => (
                            <TableRow key={`row_key_value${i.toString()}`}>
                              <TableCell component="td">
                                {row.familia}
                              </TableCell>
                              <TableCell component="td">{row.nombre}</TableCell>
                              <TableCell component="td" scope="row">
                                <RegistroModal
                                  data={row}
                                  index={i}
                                  updateData={updateData}
                                />
                              </TableCell>
                              <TableCell component="td" scope="row">
                                {row.u_medida}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </GridItem>
              </CardBody>
              <CardFooter>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ margin: '15px auto', textAlign: 'center' }}
                >
                  <Button onClick={() => handleSubmit()} color="success">
                    Enviar
                  </Button>
                </GridItem>
              </CardFooter>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default Invitacion
