import React, { useState } from 'react'
import NumberInput from 'components/NumberInput/index'
import { AddCircleOutline } from '@material-ui/icons/'
import Button from 'components/CustomButtons'
import ProyectoService from 'services/proyecto/index'

import {
  Backdrop,
  IconButton,
  Modal,
  Fade,
  FormControl,
  makeStyles
} from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'

const useStyles = makeStyles(theme => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

const Anio = ({ handleAnio , fase, token}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [anio, setAnio] = useState('')
  const handlenewAnio = () => {
    handleAnio(anio)
    const resul = ProyectoService.nuevoAnio(fase.id,anio, token)

    setOpen(false)
  }

  const handleClose = () => setOpen(false)
  return (
    <div>
      <Button type='button' color='transparent' onClick={() => setOpen(true)}>
        <IconButton
          color='primary'
          aria-label='upload picture'
          component='span'
        >
          <AddCircleOutline />
        </IconButton>
        Agregar nuevo año
      </Button>
      <Modal
        aria-labelledby='spring-modal-title'
        aria-describedby='spring-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <GridItem xs={12} sm={12} md={6}>
              <h5>Nuevo año</h5>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8} style={{ margin: '0 auto' }}>
                <FormControl fullWidth>
                  <NumberInput
                    defaultValue={anio}
                    value={anio}
                    required
                    fullWidth
                    labelText='Nuevo año'
                    onChange={e => setAnio(e.target.value)}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ margin: '10px auto' }}>
                <Button onClick={() => handlenewAnio()} color='success'>
                  Guardar
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
export default Anio
