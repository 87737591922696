import API from './../Api'

class CompanyService {
  static getAll (token) {
    return API({
      method: 'GET',
      url: '/api/v1/company/',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static getById (id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/company/${id}/`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static save (data, token) {
    return API({
      method: 'POST',
      url: '/api/v1/company/',
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static update (id,data,token) {
    return API({
      method: 'PUT',
      url: `/api/v1/company/${id}/`,
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export default CompanyService
