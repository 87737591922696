import React, { useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { useLocation } from 'react-router'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import { useToasts } from 'react-toast-notifications'
import ProyectoService from '../../../services/proyecto'

import {
  CloudDownload,
  Check,
  Edit,
  ArrowBackIos,
  Delete
} from '@material-ui/icons/'

import {
  CircularProgress,
  makeStyles,
  TableContainer,
  Backdrop,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  Tooltip,
  FormControl
} from '@material-ui/core'

import CardFooter from 'components/Card/Footer/index'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import FactoresRatio from './factoresRatio'
import RegistroModal from './registro'
import moment from 'moment'
import { MenuItem, Select } from '@material-ui/core/index'
import { Warning } from '../../../../node_modules/@material-ui/icons/index'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

const RegistroData = ({ history, token }) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [faseSelect, setFaselect] = useState()
  const [factores, setFactores] = useState([])
  const [ratios, setRatios] = useState([])

  const [anioSelected, setAnioSelected] = useState()
  const [proyecto, setProyecto] = useState({})
  const [allRegistro, setAllRegistro] = useState([])
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const [alcance, setAlcance] = useState('')

  const handlenewAnio = (anio) => {
    setAnioSelected(anio)
  }

  const loadRegistros = async () => {
    const { anio, etapa, fase } = location.state
    const reg = await ProyectoService.listRegistro(etapa, anio, token)
    setAllRegistro(reg.data)
    setFactores(reg.data.template.factores)
    setRatios(reg.data.template.ratios)
    setProyecto(reg.data.proyecto)
    setAlcance(reg.data.fase.alcance)
    setLoading(false)
  }
  const handleAlcance = (e) => {
    setAlcance(e.target.value)
  }
  useEffect(async () => {
    try {
      const { anio, fase } = location.state
      setFaselect(fase)
      setLoading(true)
      setAnioSelected(anio)

      await loadRegistros()
    } catch (e) {
      /*history.push({
        pathname: '/lista-registro'
      })*/
    }
  }, [])

  const deleteRegistro = async (id) => {
    setLoading(true)
    const reg = await ProyectoService.deleteRegistro(id, token)
    if (reg.status === 200) {
      await loadRegistros()
      setLoading(false)
      addToast('Registro eliminado', {
        appearance: 'success',
        autoDismiss: true
      })
    }
  }

  const handleLoad = (val) => {
    setLoading(val)
  }

  const { fase, registro } = allRegistro
  const { pais, tipo_proyecto, nombre, ultimo_registro } = proyecto

  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridItem xs={12} sm={12} md={12}>
        <CardHeader color="success">
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <h4>Tipo de proyecto</h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              {tipo_proyecto && (
                <FormControl fullWidth>
                  <InputLabel>{tipo_proyecto.nombre}</InputLabel>
                </FormControl>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                {pais && <InputLabel id="label_pais">{pais.nombre}</InputLabel>}
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              {tipo_proyecto && (
                <FormControl fullWidth>
                  {anioSelected && (
                    <InputLabel id="label_pais">{anioSelected}</InputLabel>
                  )}
                </FormControl>
              )}
            </GridItem>
          </GridContainer>
        </CardHeader>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.goBack()
                }}
              >
                <IconButton aria-label="atras" size="small">
                  <ArrowBackIos fontSize="inherit" />
                </IconButton>
                <label> Atras</label>
              </div>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {nombre && <h4>{nombre}</h4>}
                <p>
                  {' '}
                  Última actualización:{' '}
                  {moment(ultimo_registro).format('DD-MM-yyyy  (HH:MM:ss)')}
                </p>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Fase</TableCell>
                      <TableCell component="th" align="center">
                        Actividad de las emisiones
                      </TableCell>
                      <TableCell component="th" align="center">
                        Origen de las emisiones
                      </TableCell>
                      <TableCell component="th" align="center">
                        Clasificación de las emisiones
                      </TableCell>
                      <TableCell component="th" align="center">
                        Datos de entrada
                      </TableCell>
                      <TableCell component="th" align="center">
                        Alcance de fuente de GEI
                      </TableCell>
                      <TableCell component="th" align="center">
                        Emisión actual (tCO<small>2e</small>)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fase && (
                      <TableRow>
                        <TableCell component="td" scope="row">
                          {fase && fase.nombre}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {fase.familia && fase.familia.nombre}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {fase.origen_emision && fase.origen_emision.nombre}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {fase.calificacion && fase.calificacion.nombre}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {fase.datos_recomplilar}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          <Select
                            fullWidth
                            labelId="label_alacance"
                            value={alcance}
                            defaultValue={alcance}
                            label="Tipo de proyecto"
                            onChange={(e) => handleAlcance(e)}
                          >
                            <MenuItem value={1}>Alcance 1</MenuItem>
                            <MenuItem value={2}>Alcance 2</MenuItem>
                            <MenuItem value={3}>Alcance 3</MenuItem>
                          </Select>
                        </TableCell>

                        <TableCell component="td" align="right">
                          <Tooltip title={fase.total_emisiones}>
                            <p>
                              {parseFloat(
                                fase.total_emisiones.toFixed(2)
                              ).toLocaleString('en')}
                            </p>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </CardBody>

          <CardHeader color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <h5>{tipo_proyecto && tipo_proyecto.nombre}</h5>
              </GridItem>
            </GridContainer>
            <GridContainer
              style={{
                padding: '10px 20px',
                backgroundColor: '#fff3cd',
                border: 'solid 1px #ffecb5',
                color: '#664d03'
              }}
            >
              <small style={{ color: '#664d03' }}>
                <Warning style={{ marginTop: '2px' }} />
                <span
                  style={{
                    marginTop: '3px',
                    position: 'absolute',
                    marginLeft: '8px'
                  }}
                >
                  Recuerde registrar únicamente valores anuales de datos de
                  actividad relacionados con el periodo y la etapa en
                  evaluación.
                </span>
              </small>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">
                        Cod. fuente o ratio de GEI
                      </TableCell>
                      <TableCell component="th">Categoría</TableCell>
                      <TableCell component="th">
                        Fuente de emisión de GEI
                      </TableCell>
                      <TableCell component="th">Dato de actividad</TableCell>
                      <TableCell component="th">Unidad de medida</TableCell>
                      <TableCell component="th">
                        Factor (Kg. CO<small>2e</small>)
                      </TableCell>
                      <TableCell component="th" align="right">
                        Emisión actual (tCO<small>2e</small>)
                      </TableCell>
                      <TableCell component="th" align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  {registro && (
                    <TableBody>
                      {registro.map((row, i) => (
                        <TableRow key={`row_key_value${i.toString()}`}>
                          <TableCell component="td" scope="row">
                            <div style={{ width: 'max-content' }}>
                              <IconButton
                                aria-label="upload picture"
                                component="span"
                                color="primary"
                              >
                                {row.edit ? (
                                  <Edit style={{ fill: '#15bd85' }} />
                                ) : (
                                  <Check style={{ fill: '#15bd85' }} />
                                )}
                              </IconButton>
                              {row.codigo}
                              {row.url_verificable !== '' && (
                                <a href={row.url_verificable} target="_blank">
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <CloudDownload
                                      style={{ fill: '#15bd85' }}
                                    />
                                  </IconButton>
                                </a>
                              )}
                            </div>
                          </TableCell>
                          <TableCell component="td">
                            {row.factor && row.factor.fuente.familia.nombre}
                            {row.ratio && row.ratio.familia.nombre}
                          </TableCell>

                          <TableCell component="td">
                            {row.factor && row.factor.fuente.nombre}
                            {row.ratio && row.ratio.nombre}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.factor && (
                              <RegistroModal
                                anio={anioSelected}
                                id={row.id}
                                token={token}
                                alcance={alcance}
                                handleLoading={loadRegistros}
                                loadRegistros={loadRegistros}
                                valor={row.valor_registrado}
                                ratio={0}
                                fuente={row.factor.fuente}
                                valores={row.valores}
                              />
                            )}

                            {row.ratio && (
                              <RegistroModal
                                anio={anioSelected}
                                fase={fase.id}
                                id={row.id}
                                alcance={alcance}
                                proyecto_id={proyecto.id}
                                token={token}
                                ratio={1}
                                handleLoading={loadRegistros}
                                loadRegistros={loadRegistros}
                                valor={row.valor_registrado}
                                factor={row.ratio.id}
                                fuente={row.ratio}
                                valores={row.valores}
                              />
                            )}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.factor &&
                              row.factor.fuente.unidad_medida.nombre}

                            {row.ratio && row.ratio.unidad_medida.nombre}
                          </TableCell>
                          <TableCell component="td">
                            <Tooltip
                              title={row.valor_factor ? row.valor_factor : 0.0}
                            >
                              <span>
                                {row.valor_factor
                                  ? parseFloat(
                                      row.valor_factor.toFixed(2)
                                    ).toLocaleString('en')
                                  : 0.0}
                              </span>
                            </Tooltip>
                          </TableCell>
                          <TableCell component="td" scope="row" align="right">
                            <Tooltip title={row.valor_calculado}>
                              <span>
                                {parseFloat(
                                  row.valor_calculado.toFixed(2)
                                ).toLocaleString('en')}
                              </span>
                            </Tooltip>
                          </TableCell>
                          <TableCell component="td" scope="row" align="right">
                            {row.valor_registrado === 0 && (
                              <IconButton
                                onClick={() => deleteRegistro(row.id)}
                                aria-label="upload picture"
                                component="span"
                                color="primary"
                              >
                                <Delete color="#A3A3A3" />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </GridItem>
          </CardBody>
          <CardFooter>
            {fase && (
              <FactoresRatio
                anio={anioSelected}
                proyecto_id={proyecto.id}
                token={token}
                fase={fase.id}
                handleLoad={handleLoad}
                loadRegistros={loadRegistros}
                factores={factores}
                registro={registro}
                ratios={ratios}
              />
            )}
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withAccessGranted(RegistroData, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
