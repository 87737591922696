import React, { useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'

import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import ProyectoService from '../../../services/proyecto'

import {
  CircularProgress,
  makeStyles,
  TableContainer,
  Backdrop,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@material-ui/core'
import userAccess from 'userAccess'
import withAccessGranted from 'components/withAuth/index'
import moment from 'moment'
import { ArrowBackIos } from '@material-ui/icons/index'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))
const createData = (name, calories, fat, carbs, protein) => {
  return { name, calories, fat, carbs, protein }
}
const rows = [createData('Frozen yoghurt', 159)]

const RegistroHistorico = ({ history, token, ultimo_registro }) => {
  const classes = useStyles()
  const [nombre, setNombre] = useState('')
  const [ultimaActializacion, setUltimaActializacion] = useState('')
  const [historico, setHistorico] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    setLoading(true)
    const { etapa, nombre, ultimo_registro } = history.location.state
    setNombre(nombre)
    setUltimaActializacion(ultimo_registro)
    const resp = await ProyectoService.registroHistorico(etapa, token)
    setLoading(false)
    if (resp.status === 200) {
      setHistorico(resp.data)
    }
  }, [])

  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.goBack()
                }}
              >
                <IconButton aria-label="atras" size="small">
                  <ArrowBackIos fontSize="inherit" />
                </IconButton>
                <label> Atras</label>
              </div>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <h4>{nombre}</h4>

                <p>
                  {' '}
                  Última actualización:{' '}
                  {moment(ultimaActializacion).format('DD-MM-yyyy  (HH:MM:ss)')}
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}></GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Fecha</TableCell>
                      <TableCell component="th">Nombre</TableCell>
                      <TableCell component="th">Usuario</TableCell>
                      <TableCell component="th">
                        Cod. fuente o ratio de GEI
                      </TableCell>
                      <TableCell component="th">Valor Registrado </TableCell>
                      <TableCell component="th">Valor Calculado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historico.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="td" scope="row">
                          {row.fecha}
                        </TableCell>

                        <TableCell component="td" scope="row">
                          {row.usuario.name}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row.usuario.email}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row.codigo}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row.valor_registrado}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {row.valor_calculado}
                        </TableCell>

                        <TableCell></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withAccessGranted(RegistroHistorico, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
