import React, { useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import UserService from 'services/user'
import { useToasts } from 'react-toast-notifications'
import Error from 'components/Error/index'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { Backdrop, makeStyles, CircularProgress } from '@material-ui/core'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'

const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  table: {
    minWidth: 650
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin: '20px 0'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const Profile = ({ history, token, user }) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const [hasError, setHasError] = useState(false)
  const [errors, setErros] = useState({
    name: '',
    email: '',
    tipoUsuario: ''
  })
  const { addToast } = useToasts()
  const [tipoUsuario, setTipoUsuario] = useState('')
  const [tipos, setTipos] = useState([])
  const [id, setId] = useState(0)
  useEffect(async () => {
    setLoading(true)

    try {
      const tipoUsuario = await UserService.tipoUsuario(token)
      if (tipoUsuario.status === 200) {
        const tipos = tipoUsuario.data.map((tipo) => {
          return { label: tipo.nombre, value: tipo.id }
        })
        setTipos(tipos)
      }
      const resul = await UserService.getById(user.id, token)
      if (resul.status === 200) {
        setLoading(false)
        setId(resul.data.id)
        setName(resul.data.name)
        setEmail(resul.data.email)
        setTipoUsuario(resul.data.tipo_usuario.id)
      }
    } catch (error) {
      setLoading(false)
    }
  }, [])

  const onSubmit = async () => {
    setLoading(true)
    if (!name) {
      errors.name = 'El nombre es requerido'
      setHasError(true)
    }
    if (!email) {
      errors.email = 'El email es requerido'
      setHasError(true)
    }
    if (!tipoUsuario) {
      errors.tipoUsuario = 'El tipo de usuario es requerido'
      setHasError(true)
    }
    if (!password2) {
      errors.password2 = 'La contraseña es requerida'
      setHasError(true)
    }
    if (!password) {
      errors.password = 'La contraseña es requerida'
      setHasError(true)
    }
    if (password2 !== password) {
      errors.password = 'La contraseña no coinciden'
      errors.password2 = 'La contraseña es coinciden'
    }
    if (hasError) {
      setErros(errors)
      return
    }

    const data = {
      name,
      email,
      password,
      tipo_usuario: tipoUsuario
    }

    try {
      await UserService.edit(id, data, token)
      addToast('Operación realizada con exito', { appearance: 'success' })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }

  return (
    <div>
      <Backdrop className={load.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4>Gestion de usuario</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  required
                  value={name}
                  disabled
                  defaultValue={name}
                  labelText="Nombre"
                  onChange={(e) => setName(e.target.value)}
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={errors.name ? true : false}
                />
                {hasError && errors.name && (
                  <Error variant="inherit" color="danger">
                    {errors.name}
                  </Error>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  required
                  value={email}
                  disabled
                  onChange={(e) => setEmail(e.target.value)}
                  labelText="Correo"
                  defaultValue={email}
                  margin="dense"
                  error={errors.email ? true : false}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {hasError && errors.email && <Error>{errors.email}</Error>}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomSelect
                  labelText="Tipo de usuario"
                  required
                  disabled
                  defaultValue={tipoUsuario}
                  value={tipoUsuario}
                  data={tipos}
                  top
                  formControlProps={{
                    fullWidth: true
                  }}
                  onChange={(e) => setTipoUsuario(e.target.value)}
                />
                {hasError && errors.tipoUsuario && (
                  <Error>{errors.tipoUsuario}</Error>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={6} hidden>
                <CustomInput
                  required
                  disabled={loading}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  labelText="Contraseña"
                  defaultValue={password}
                  margin="dense"
                  type="password"
                  error={errors.email ? true : false}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {hasError && errors.password && (
                  <Error>{errors.password}</Error>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={6} hidden>
                <CustomInput
                  required
                  disabled={loading}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  labelText="Confirmar contraseña"
                  defaultValue={password2}
                  margin="dense"
                  type="password"
                  error={errors.email ? true : false}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
                {hasError && errors.password2 && (
                  <Error>{errors.password2}</Error>
                )}
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter style={{ display: 'none' }}>
            <Button
              type="button"
              onClick={onSubmit}
              disabled={loading}
              color="success"
            >
              Guardar
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </div>
  )
}
export default withAccessGranted(Profile, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL,
  userAccess.ADMINUSER.ACCESS_LEVEL,
  userAccess.CONSULTA.ACCESS_LEVEL
])
