/*eslint-disable*/
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
// core components
import styles from 'assets/jss/components/footerStyle.js'

const useStyles = makeStyles(styles)
const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div style={{ margin: 'auto', textAlign: 'center' }}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://neutralbank.s3.sa-east-1.amazonaws.com/manual_usuarios.pdf"
                target="_blanck"
                className={classes.block}
              >
                Manual
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://neutralbank.s3.sa-east-1.amazonaws.com/datos_referencia.xlsx"
                target="_blanck"
                className={classes.block}
              >
                Datos de referencia
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="mailto:soporte@greenlook.org" className={classes.block}>
                soporte@greenlook.org
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{' '}
            <a
              href="https://www.creative-tim.com?ref=mdr-footer"
              target="_blank"
              className={classes.a}
            >
              NeutralBank
            </a>
          </span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
