import React, { useState, useEffect } from 'react'
import {
  Backdrop,
  IconButton,
  Modal,
  Fade,
  makeStyles,
  Paper,
  TableBody,
  TableContainer,
  Table,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import Buscar from 'assets/img/buscar.svg'

import GridItem from 'components/Grid/GridItem'
import { AddCircleOutline } from '@material-ui/icons/'
import CardFooter from 'components/Card/Footer/index'
import Button from 'components/CustomButtons'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/index'
import { useToasts } from 'react-toast-notifications'
import ProyectoService from '../../../services/proyecto'
import FactorService from '../../../services/factor'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

const FactoresRatio = ({
  factores,
  ratios,
  anio,
  loadRegistros,
  fase,
  proyecto_id,
  factor,
  registro,
  token
}) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [tipo, setTipo] = useState(1)
  const [selected, setSelected] = useState([])
  const [factoresFiltrados, setFactoresFiltrados] = useState([])
  const [ratiosFiltrados, setRatiosFiltrados] = useState([])
  useEffect(() => {
    const reg = registro.map((p) => {
      try {
        return p.ratio.id
      } catch (e) {}
      try {
        return p.factor.id
      } catch (e) {}
    })

    const fact = factores.filter((p) => {
      return !reg.includes(p.id)
    })
    setFactoresFiltrados(fact)
    const rati = ratios.filter((p) => {
      return !reg.includes(p.id)
    })
    setRatiosFiltrados(rati)

  }, [factores, registro])

  const handleSearch = async () => {
    if (search!=='') {    
      if(tipo === 1){
        const factResul = await FactorService.buscarFactores(search,proyecto_id, token)
        if (factResul.status === 200) {
          setFactoresFiltrados(factResul.data)
        }
      }else{
        const ratResul = await FactorService.buscarRatios(search,proyecto_id, token)
        if (ratResul.status === 200) {
          setRatiosFiltrados(ratResul.data)
        }
      }
    }
    else{
      const reg = registro.map((p) => {
        try {
          return p.ratio.id
        } catch (e) {}
        try {
          return p.factor.id
        } catch (e) {}
      })
      const fact = factores.filter((p) => {
        return !reg.includes(p.id)
      })
      setFactoresFiltrados(fact)
      const rati = ratios.filter((p) => {
        return !reg.includes(p.id)
      })
      setRatiosFiltrados(rati)
    }
  }
  const handleSelect = (val) => {
    val.selected = !val.selected
  }
  const handleTipo = (e) => {
    setTipo(e)
    handleSearch()
  }
  const handleClose = () => {
    setOpen(false)
  }
  
  const handleSubmit = async () => {
    try {
      handleClose()
      
      try {
        const data = {
          anio: anio,
          fase_id: fase,
          proyecto_id: proyecto_id,
          factores :tipo === 1 ?factoresFiltrados:[],
          ratios : tipo === 2 ?ratiosFiltrados:[]
        }
        const reg = await ProyectoService.saveRegistro(data, token)
      } catch (error) {
        console.log(error)
      }
      addToast('Registro creado', { appearance: 'success' })
      loadRegistros()
    } catch (e) {
      addToast('Error en el proceso', { appearance: 'error' })
      handleClose()
    }
  }

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <AddCircleOutline />
        </IconButton>
        Agregar nueva fuente o ratio
      </div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        maxWidth="sm"
        style={{ width: '80%', margin: 'auto' }}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        sx={{
          display: 'flex',
          p: 1,
          alignItems: 'center',
          justifyContent: 'center'
        }}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  value={search}
                  top
                  onChange={(e) => setSearch(e.target.value)}
                  labelText="Buscador de Factores / Ratios"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem md={1}>
                <a onClick={() => handleSearch()} style={{ cursor: 'pointer' }}>
                  <img
                    src={Buscar}
                    alt="Buscar"
                    style={{ marginTop: '15px' }}
                  />
                </a>
              </GridItem>
              <GridItem md={3}>
                <FormControl component="fieldset">
                  <div style={{ marginTop: '10px' }}>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      value={tipo}
                      defaultValue={1}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Factor"
                        onClick={() => setTipo(1)}
                        labelPlacement="start"
                      />

                      <FormControlLabel
                        value={2}
                        control={<Radio color="primary" />}
                        label="Ratio"
                        onClick={() => handleTipo(2)}
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </div>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              style={{ overflow: 'scroll', height: '400px' }}
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">
                        Cod. fuente o ratio de GEI
                      </TableCell>
                      <TableCell component="th">
                        Actividad emisora de GEI
                      </TableCell>
                      <TableCell component="th">
                        Nombre de fuente o ratio
                      </TableCell>
                      <TableCell component="th">Categoría</TableCell>
                      <TableCell component="th">Unidad de medida</TableCell>
                      <TableCell component="th">Factor (Kg. CO<small>2e</small>)</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tipo === 2 && (
                      <>
                        {ratiosFiltrados.map((row, i) => (
                          <TableRow key={`id_ratio_${i.toString()}`}>
                            <TableCell component="td" scope="row">
                              {row.codigo}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.familia.nombre}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.nombre}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.categoria.nombre}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.unidad_medida.codigo}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {parseFloat(row.fco2.toFixed(2)).toLocaleString(
                                'en'
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Checkbox
                                defaultChecked={false}
                                color="primary"
                                onClick={() => handleSelect(row)}
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                    {tipo === 1 && (
                      <>
                        {factoresFiltrados.map((row, i) => (
                          <TableRow key={`id_fact_${i.toString()}`}>
                            <TableCell component="td" scope="row">
                              {row.codigo}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.fuente.familia.nombre}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.fuente.nombre}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.fuente.categoria.nombre}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.fuente.unidad_medida.codigo}
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {parseFloat(row.fco2.toFixed(2)).toLocaleString(
                                'en'
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <Checkbox
                                defaultChecked={false}
                                onClick={() => handleSelect(row)}
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox'
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CardFooter>
                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  color="success"
                >
                  Guardar
                </Button>
              </CardFooter>
            </GridItem>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default FactoresRatio
