import React, { useEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { IconButton, Tooltip } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'
import CardHeader from 'components/Card/Header/index'

Highcharts.setOptions({
  chart: {
    backgroundColor: {
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(255, 255, 255)']
      ]
    },
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    showTable: false,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG'
  }
})

const Adicionalidad = ({ data, anio, categories }) => {
  const [planeacionOptions, setPlaneacionOptions] = useState({})
  const [empty, setEmpty] = useState(true)
  useEffect(() => {
    const dat = []
    const cat = data.map((item) => {
      dat.push({
        name: item.nombre,
        y: parseFloat(item.mitigacion.toFixed(2)),
        color: item.mitigacion < 0 ? '#15BD85' : '#A3A3A3'
      })
      return item.nombre
    })
    let subtitle =
      categories === 0 ? ': Año base' : `${anio ? ': ' + anio : ''}`

    const planeacion = {
      chart: {
        type: 'bar'
      },
      title: {
        text: 'Proyectos'
      },
      subtitle: {
        text: `tCO2e${subtitle}`
      },
      xAxis: {
        categories: cat
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      yAxis: {
        title: {
          text: 'tCO2e'
        }
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [
        {
          name: 'Mitigación',
          color: '#15BD85',
          data: dat
        }
      ]
    }
    setPlaneacionOptions(planeacion)
  }, [data, categories, anio])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>
                  C) Emisiones evitadas consolidadas de proyectos (media anual)
                  - tCO
                  <small>2e</small>
                </h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se pueden comparar distintos proyectos de inversión en cuanto al potencial de reducción de emisiones de GEI durante el ciclo de vida de cada uno de los proyectos, ponderando los mismos a una media anual."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div style={{ minHeight: '500px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={planeacionOptions}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default Adicionalidad
