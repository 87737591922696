import axios from 'axios'

const BAD_REQUEST = 401

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL
})

axiosInstance.interceptors.request.use(
  (config) => config,
  (error) => {
    console.log(`Interceptor Request Error${error}`)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === BAD_REQUEST) {
      localStorage.removeItem('user')
      localStorage.removeItem('login')
      window.location = '/'
    }

    return Promise.reject(error)
  }
)
export default axiosInstance
