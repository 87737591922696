import React, { useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import NumberInput from 'components/NumberInput'
import Button from 'components/CustomButtons'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import CardFooter from 'components/Card/Footer'
import styles from './style'
import CompanyService from 'services/company/index'
import CustomSelect from 'components/CustomSelect/index'
import CatalogoService from 'services/catalogos/index'
import { useToasts } from 'react-toast-notifications'

import {
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Backdrop,
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody
} from '@material-ui/core'

import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'

const useStyles = makeStyles(styles)
const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  table: {
    minWidth: 650
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    margin: '20px 0'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const CompanyForm = ({ user, token }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const [errors, setError] = useState({
    nombre: '',
    tipo: ''
  })

  const [paises, setPaises] = useState([])

  const [tipoBanca, setTipoBanca] = useState([])
  const [tipo, setTipo] = useState('')
  const [nombre, setNombre] = useState('')
  const [emailContacto, setEmailContacto] = useState('')
  const [telefonoContacto, setTelefonoContacto] = useState('')
  const [tipoProyecto, setTipoProyecto] = useState([])
  const [licencias, setLicencias] = useState('')
  const [pais, setPais] = useState([])
  const [company, setCompany] = useState({})
  const [selectAll, setSelectAll] = useState(false)

  const [planeacion, setPlaneacion] = useState('')
  const [implementacion, setImplementacion] = useState('')

  useEffect(async () => {
    setLoading(true)

    const tipoBanca = await CatalogoService.getTipoBanca(token)

    const responseBaca = tipoBanca.data.map((item) => {
      return { label: item.nombre, value: item.id }
    })
    setTipoBanca(responseBaca)

    const company = await CompanyService.getById(user.company_id, token)
    if (company.status === 200) {
      setCompany(company.data)
      setNombre(company.data.nombre)
      setTipo(company.data.tipo_banca)
      setPais(company.data.pais)
      setLicencias(company.data.licencias)
      setEmailContacto(company.data.email_contacto)
      setTelefonoContacto(company.data.telefono_contacto)
      setPaises(company.data.paises_opera)

      const tipoP = company.data.tipo_proyecto.map((item) => {
        return { nombre: item.nombre, id: item.id, selected: item.selected }
      })
      setTipoProyecto(tipoP)
    }
    try {
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [])

  const onSubmit = async () => {
    const data = {
      nombre,
      tipo_banca: tipo,
      telefono_contacto: telefonoContacto,
      email_contacto: emailContacto,
      pais: pais,
      licencias: licencias,
      tipo_proyecto: tipoProyecto, 
      paises_opera: paises,
    }
    try {
      const resul = await CompanyService.update(user.company_id, data, token)
      addToast('Organización modificada con éxito', { appearance: 'success' })
    } catch (e) {
      addToast('Error en el proceso', { appearance: 'error' })
      console.log(e)
    }
  }

  const handleChange = (event) => {
    const select = !selectAll
    setSelectAll(select)
    const tipoP = tipoProyecto.map((item) => {
      item.selected = select
      return item
    })
    setTipoProyecto(tipoP)
  }

  const handlePaises = (event) => {
    const select = !selectAll
    setSelectAll(select)
    const tipoP = paises.map((item) => {
      item.selected = select
      return item
    })
    setPaises(tipoP)
  }
  const selectPais = (data) => {
    const pais = paises.map((item) => {
      if (item.id === data.id) {
        item.selected = !item.selected
      }
      return item
    })
    setPaises(pais)
  }

  const selectTipoProyecto = (data) => {
    const tipoP = tipoProyecto.map((item) => {
      if (item.id === data.id) {
        item.selected = !item.selected
      }
      return item
    })
    setTipoProyecto(tipoP)
  }

  const createData = (name, calories, fat, carbs, protein) => {
    return { name, calories, fat, carbs, protein }
  }

  return (
    <div>
      <Backdrop className={load.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} xl={12}>
                  <h4>Perfil de la organización</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nombre"
                      defaultValue={nombre}
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {errors.name && <span>Campo es requerido</span>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Tipo de banca"
                      defaultValue={tipo}
                      data={tipoBanca}
                      value={tipo}
                      onChange={(e) => setTipo(e.target.value)}
                      required
                      name="tipo"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                    {errors.tipo && <span>Campo es requerido</span>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <NumberInput
                      defaultValue={licencias}
                      value={licencias}
                      required
                      labelText="No. de usuarios"
                      onChange={(e) => setLicencias(e.target.value)}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {errors.name && <span>Campo es requerido</span>}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      defaultValue={emailContacto}
                      value={emailContacto}
                      onChange={(e) => setEmailContacto(e.target.value)}
                      labelText="Email de usuario (responsable de la cuenta)"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {errors.name && <span>Campo es requerido</span>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="País"
                      defaultValue={pais}
                      data={paises}
                      value={pais}
                      onChange={(e) => setPais(e.target.value)}
                      required
                      name="pais"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {errors.name && <span>Campo es requerido</span>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      defaultValue={company.telefono_contacto}
                      labelText="Teléfono de contacto"
                      onChange={(e) => setTelefonoContacto(e.target.value)}
                      value={telefonoContacto}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {errors.name && <span>Campo es requerido</span>}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </CardBody>

            <CardBody>
              <Card>
                <CardHeader color="success">Etapas (Alias)</CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        defaultValue={planeacion}
                        value={planeacion}
                        onChange={(e) => setPlaneacion(e.target.value)}
                        labelText="Planeación"
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        defaultValue={implementacion}
                        value={implementacion}
                        onChange={(e) => setImplementacion(e.target.value)}
                        labelText="Implementación"
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </CardBody>
            <CardBody>
              <Card>
                <CardHeader color="success">Tipo de proyectos</CardHeader>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Seleccione los tipos de proyectos que podrá
                              consultar y/o registrar el usuario
                            </TableCell>
                            <TableCell align="right">
                              Todos {'    '}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectAll}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tipoProyecto.map((row, i) => (
                            <TableRow key={`t_proyecto_${i.toString()}`}>
                              <TableCell component="th" scope="row">
                                {row.nombre}
                              </TableCell>

                              <TableCell align="right">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={row.selected}
                                      onClick={() => selectTipoProyecto(row)}
                                      name="checkedB"
                                      color="primary"
                                    />
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                </GridContainer>
              </Card>
            </CardBody>

            <CardBody>
              <Card>
                <CardHeader color="success">Países</CardHeader>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Seleccione los países</TableCell>
                            <TableCell align="right">
                              Todos {'    '}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectAll}
                                    onChange={handlePaises}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paises.map((row, i) => (
                            <TableRow key={`t_proyecto_${i.toString()}`}>
                              <TableCell component="th" scope="row">
                                {row.nombre}
                              </TableCell>

                              <TableCell align="right">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={row.selected}
                                      onClick={() => selectPais(row)}
                                      name="checkedB"
                                      color="primary"
                                    />
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                </GridContainer>
              </Card>
            </CardBody>
            <CardFooter>
              <GridItem>
                <Button
                  type="button"
                  onClick={() => onSubmit()}
                  color="success"
                >
                  Guardar
                </Button>
              </GridItem>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
export default withAccessGranted(CompanyForm, [userAccess.ADMIN.ACCESS_LEVEL])
