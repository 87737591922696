import { blackColor } from "assets/jss/style";

export const Style = {
  login:{
    margin: 'auto' 
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  card: {
    color: blackColor,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',

    marginBottom: '3px',
    textDecoration: 'none'
  }
}
