import React, { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card/index'
import CardHeader from 'components/Card/Header/index'
import CardBody from 'components/Card/Body/index'
import { useLocation } from 'react-router'
import Logo from 'assets/img/neutral_logo.png'

import {
  CircularProgress,
  makeStyles,
  Backdrop,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody
} from '@material-ui/core'
import CardFooter from 'components/Card/Footer/index'
import HuellaService from 'services/Huella/index'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import { CloudDownload } from '@material-ui/icons/index'

const load = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const Huella = ({ history, token }) => {
  const classes = useStyles()
  const location = useLocation()
  const [proyecto, setProyecto] = useState('')
  const [loading, setLoading] = useState(false)
  const [proyectoID, setProyectoID] = useState('')
  const loadHuella = async () => {
    try {
      const { id } = location.state
      setProyectoID(id)

      const huella = await HuellaService.getHuellaReferencia(id, token)
      setProyecto(huella.data.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(async () => {
    try {
      setLoading(true)
      await loadHuella()
    } catch (error) {
      setLoading(false)
      history.push(`registro-referencia`)
    }
  }, [])

  const {
    huella,
    nombre,
    total_huella,
    anio,
    referencia,
    total_referencia,
    total_implementacion,
    total_huella_proyectada_referencia,
    total_huella_proyectata,
    promedio_referencia,
    promedio_huella,
    diferencia,
    porciento,
    total_anio,
    mitigacion_anio,
    anios_proyecto,
    total_adicionalidad
  } = proyecto
  return (
    <GridContainer>
      <Backdrop className={load.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader style={{ textAlign: 'center' }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                <img src={Logo} alt="logo" width="100%" />
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <h3>Situación de Referencia</h3>
                <h3>Inventario de GEI del proyecto</h3>
                <p>
                  del 1 de enero al 31 de diciembre del año {anio ? anio : ''}
                </p>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <GridItem xs={12} sm={12} md={12}>
            <h5 style={{ marginLeft: '30px' }}>{nombre}</h5>
            <p style={{ marginLeft: '30px' }}>
              Emisiones de la situación de referencia
            </p>
          </GridItem>
          {referencia && referencia.alcance1 && (
            <CardHeader style={{ textAlign: 'center' }}>
              <h3> Alcance 1</h3>
            </CardHeader>
          )}
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                {referencia && referencia.alcance1 && (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell}>
                          Fase
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Actividad emisora de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Origen de las emisiones
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Categoría
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Fuente de emisión de GEI
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Alcance de fuente de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Emisión actual (tCO<small>2e</small>)
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {referencia.alcance1.valor && (
                      <TableBody>
                        {referencia.alcance1.valor.map((p, key) => (
                          <TableRow
                            key={`key_table${key.toString()}`}
                            className={classes.tableBodyRow}
                          >
                            <TableCell className={classes.tableCell}>
                              {p.fase}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.origen_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.fuente_emisora}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Alcance {p.ambito}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {parseFloat(p.huella.toFixed(2)).toLocaleString(
                                'en'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </GridItem>
          </CardBody>
          {referencia && referencia.alcance2 && (
            <CardHeader style={{ textAlign: 'center' }}>
              <h3> Alcance 2</h3>
            </CardHeader>
          )}
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                {referencia && referencia.alcance2 && (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell}>
                          Fase
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Actividad emisora de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Origen de las emisiones
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Categoría
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Fuente de emisión de GEI
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Alcance de fuente de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Emisión actual (tCO<small>2e</small>)
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {referencia.alcance2.valor && (
                      <TableBody>
                        {referencia.alcance2.valor.map((p, key) => (
                          <TableRow
                            key={`key_table${key.toString()}`}
                            className={classes.tableBodyRow}
                          >
                            <TableCell className={classes.tableCell}>
                              {p.fase}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.origen_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.fuente_emisora}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Alcance {p.ambito}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {parseFloat(p.huella.toFixed(2)).toLocaleString(
                                'en'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </GridItem>
          </CardBody>
          {referencia && referencia.alcance3 && (
            <CardHeader style={{ textAlign: 'center' }}>
              <h3> Alcance 3</h3>
            </CardHeader>
          )}
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                {referencia && referencia.alcance3 && (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell}>
                          Fase
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Actividad emisora de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Origen de las emisiones
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Categoría
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Fuente de emisión de GEI
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Alcance de fuente de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Emisión actual (tCO<small>2e</small>)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {referencia.alcance3.valor && (
                      <TableBody>
                        {referencia.alcance3.valor.map((p, key) => (
                          <TableRow
                            key={`key_table${key.toString()}`}
                            className={classes.tableBodyRow}
                          >
                            <TableCell className={classes.tableCell}>
                              {p.fase}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.origen_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.fuente_emisora}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Alcance {p.ambito}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {parseFloat(p.huella.toFixed(2)).toLocaleString(
                                'en'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </GridItem>
          </CardBody>
          <CardFooter>
            <div style={{ textAlign: 'right', width: '90%' }}>
              <h6 style={{ textTransform: 'none' }}>
                Total de emisiones (tCO<small>2e</small>) primer año:{' '}
                {total_referencia
                  ? parseFloat(total_referencia.toFixed(2)).toLocaleString('en')
                  : '0'}
              </h6>
              <h6 style={{ textTransform: 'none' }}>
                Total de emisiones (tCO<small>2e</small>) en{' '}
                {total_implementacion} años:{' '}
                {total_huella_proyectada_referencia
                  ? parseFloat(
                      total_huella_proyectada_referencia.toFixed(2)
                    ).toLocaleString('en')
                  : 0}
              </h6>
              <h6 style={{ textTransform: 'none' }}>
                Total de emisiones (tCO<small>2e</small>) promedio anual (
                {total_anio} años):{' '}
                {promedio_referencia
                  ? parseFloat(promedio_referencia.toFixed(2)).toLocaleString(
                      'en'
                    )
                  : 0}
              </h6>
            </div>
          </CardFooter>
          <GridItem xs={12} sm={12} md={12}>
            <h5 style={{ marginLeft: '30px' }}>{nombre}</h5>
            <p style={{ marginLeft: '30px' }}>
              Emisiones potenciales del proyecto
            </p>
          </GridItem>
          {huella && huella.alcance1 && (
            <CardHeader style={{ textAlign: 'center' }}>
              <h3> Alcance 1</h3>
            </CardHeader>
          )}
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                {huella && huella.alcance1 && (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell}>
                          Fase
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Actividad emisora de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Origen de las emisiones
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Categoría
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Fuente de emisión de GEI
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Alcance de fuente de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Emisión actual (tCOtCO<small>2e</small>2e)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {huella.alcance1.valor && (
                      <TableBody>
                        {huella.alcance1.valor.map((p, key) => (
                          <TableRow
                            key={`key_table${key.toString()}`}
                            className={classes.tableBodyRow}
                          >
                            <TableCell className={classes.tableCell}>
                              {p.fase}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.origen_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.fuente_emisora}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Alcance {p.ambito}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {parseFloat(p.huella.toFixed(2)).toLocaleString(
                                'en'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </GridItem>
          </CardBody>
          {huella && huella.alcance2 && (
            <CardHeader style={{ textAlign: 'center' }}>
              <h3> Alcance 2</h3>
            </CardHeader>
          )}
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                {huella && huella.alcance2 && (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell}>
                          Fase
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Actividad emisora de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Origen de las emisiones
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Categoría
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Fuente de emisión de GEI
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Alcance de fuente de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Emisión actual (tCO<small>2e</small>)
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {huella.alcance2.valor && (
                      <TableBody>
                        {huella.alcance2.valor.map((p, key) => (
                          <TableRow
                            key={`key_table${key.toString()}`}
                            className={classes.tableBodyRow}
                          >
                            <TableCell className={classes.tableCell}>
                              {p.fase}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.origen_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.actividad_emision}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {p.fuente_emisora}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              Alcance {p.ambito}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {parseFloat(p.huella.toFixed(2)).toLocaleString(
                                'en'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </GridItem>
          </CardBody>
          {huella && huella.alcance3 && (
            <CardHeader style={{ textAlign: 'center' }}>
              <h3> Alcance 3</h3>
            </CardHeader>
          )}
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                {huella && huella.alcance3 && (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableHeadCell}>
                          Fase
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Actividad emisora de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Origen de las emisiones
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Categoría
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Fuente de emisión de GEI
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Alcance de fuente de GEI
                        </TableCell>
                        <TableCell className={classes.tableHeadCell}>
                          Emisión actual (tCO<small>2e</small>)
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {huella.alcance3.valor.map((p, key) => (
                        <TableRow
                          key={`key_table${key.toString()}`}
                          className={classes.tableBodyRow}
                        >
                          <TableCell className={classes.tableCell}>
                            {p.fase}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.actividad_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.origen_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.actividad_emision}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {p.fuente_emisora}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            Alcance {p.ambito}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {parseFloat(p.huella.toFixed(2)).toLocaleString(
                              'en'
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </GridItem>
          </CardBody>

          <CardFooter>
            <div style={{ textAlign: 'right', width: '90%' }}>
              <h6 style={{ textTransform: 'none' }}>
                Total de emisiones (tCO<small>2e</small>) primer año:{' '}
                {total_huella
                  ? parseFloat(total_huella.toFixed(2)).toLocaleString('en')
                  : '0'}{' '}
                tCO<small>2e</small>
              </h6>
              <h6 style={{ textTransform: 'none' }}>
                Total de emisiones (tCO<small>2e</small>) en ({anios_proyecto}{' '}
                años):{' '}
                {total_huella_proyectata
                  ? parseFloat(
                      total_huella_proyectata.toFixed(2)
                    ).toLocaleString('en')
                  : 0}{' '}
                tCO<small>2e</small>
              </h6>
              <h6 style={{ textTransform: 'none' }}>
                Total de emisiones (tCO<small>2e</small>) promedio anual (
                {total_anio} años):{' '}
                {promedio_huella
                  ? parseFloat(promedio_huella.toFixed(2)).toLocaleString('en')
                  : 0}
              </h6>
            </div>
          </CardFooter>

          <CardFooter>
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{
                  textAlign: 'left',
                  width: '100%',
                  paddingLeft: '20px'
                }}
              >
                <h6 style={{ textTransform: 'none' }}>
                  Adicionalidad (tCO<small>2e</small>) del proyecto en{' '}
                  {total_adicionalidad} años:{' '}
                  {parseFloat(parseFloat(diferencia).toFixed(2)).toLocaleString(
                    'en'
                  )}
                </h6>
                <h6 style={{ textTransform: 'none' }}>
                  Mitigación (tCO<small>2e</small>) del proyecto promedio anual
                  ({total_anio} años):{' '}
                  {mitigacion_anio
                    ? parseFloat(mitigacion_anio.toFixed(2)).toLocaleString(
                        'en'
                      )
                    : 0}{' '}
                  ({' '}
                  <span
                    style={{ color: `${porciento > 0 ? 'red' : '#15BD85'} ` }}
                  >
                    {mitigacion_anio
                      ? parseFloat(porciento.toFixed(2)).toLocaleString('en')
                      : 0}{' '}
                    {' %'}
                  </span>
                  )
                </h6>
              </div>
            </GridItem>
          </CardFooter>
          <CardFooter>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <a
                target="_blank"
                href={`${process.env.REACT_APP_SERVER_URL}api/v1/huella/huellareferencia/?pdf=1&proyecto=${proyectoID}&anio=${anio}&token=${token}`}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: 'right'
                  }}
                >
                  + Descargar huella consolidada
                  <IconButton
                    color="success"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloudDownload />
                  </IconButton>
                </div>
              </a>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
export default withAccessGranted(Huella, [
  userAccess.CONSULTA.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL,
  userAccess.ADMINUSER.ACCESS_LEVEL,
  userAccess.ADMIN.ACCESS_LEVEL
])
