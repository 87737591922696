import React, { useEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

import Card from 'components/Card/Card'
import CardBody from 'components/Card/Body/index.js'
import CardHeader from 'components/Card/Header/index'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { IconButton, Tooltip } from '@material-ui/core/index'
import { Help } from '@material-ui/icons/index'

Highcharts.setOptions({
  chart: {
    backgroundColor: {
      stops: [
        [0, 'rgb(255, 255, 255)'],
        [1, 'rgb(255, 255, 255)']
      ]
    },
    height: 500,
    borderWidth: 0,
    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
    plotShadow: true,
    plotBorderWidth: 1
  },
  tooltip: {
    valueDecimals: 2
  },
  loading: {
    hideDuration: 50,
    showDuration: 70
  },
  exporting: {
    filename: 'Grafica Neutralbank',
    scale: 4,
    showTable: false,
    sourceWidth: 1000
  },
  lang: {
    decimalPoint: '.',
    printChart: 'Imprimir',
    downloadJPEG: 'Descargar JPEG image',
    downloadPDF: 'Descargar PDF',
    downloadPNG: 'Descargar PNG',
    downloadSVG: 'Descargar SVG'
  }
})

const Alcance = ({ data, title, anio }) => {
  const [planeacionOptions, setPlaneacionOptions] = useState({})
  const [implementacionOptions, setImplementacionOptions] = useState({})
  useEffect(() => {
    const { planeacion, implementacion } = data
    const plan = {
      chart: {
        type: 'bar'
      },
      text: 'GreenCloud',
      credits: {
        href: 'https://www.neutralbank.io',
        text: 'www.neutralbank.app'
      },
      title: {
        text: `${title}: Planeación (tCO2e)`
      },
      subtitle: {
        text: `tCO2e Planeación: ${anio}`
      },
      yAxis: {
        title: {
          text: 'Planeacion tCO2e',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      tooltip: {
        valueSuffix: ' tCO2e'
      },
      xAxis: {
        categories: ['Alcance 1', 'Alcance 2', 'Alcance 3'],
        crosshair: true
      },
      series: [
        {
          name: 'tCO2e',
          color: '#A3A3A3',
          data: [
            parseFloat(planeacion.alcance1.toFixed(2)),
            parseFloat(planeacion.alcance2.toFixed(2)),
            parseFloat(planeacion.alcance3.toFixed(2))
          ]
        }
      ]
    }
    setPlaneacionOptions(plan)

    const implementacionOptions = {
      chart: {
        type: 'bar'
      },
      title: {
        text: `${title}: Implementación (tCO2e)`
      },
      subtitle: {
        text: `tCO2e Implementación: ${anio}`
      },
      credits: {
        href: 'https://www.neutralbank.app',
        text: 'www.neutralbank.app'
      },
      yAxis: {
        title: {
          text: `Implementación tCO2e`,
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      tooltip: {
        valueSuffix: ' tCO2e'
      },
      xAxis: {
        categories: ['Alcance 1', 'Alcance 2', 'Alcance 3'],
        crosshair: true
      },
      series: [
        {
          name: 'tCO2e',
          color: '#A3A3A3',
          data: [
            parseFloat(implementacion.alcance1.toFixed(2)),
            parseFloat(implementacion.alcance2.toFixed(2)),
            parseFloat(implementacion.alcance3.toFixed(2))
          ]
        }
      ]
    }
    setImplementacionOptions(implementacionOptions)
  }, [data, title])

  return (
    <CardBody>
      <GridItem xs={12} sm={12} md={12}>
        <Card chart>
          <CardHeader plain color="primary">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={11}>
                <h4>A) Emisiones por Alcance (GHG Protocol) - tCO2e</h4>
              </GridItem>
              <GridItem lg={1} style={{ float: 'right', textAlign: 'right' }}>
                <Tooltip
                  title="En esta gráfica se muestran las emisiones de GEI por alcance I, II y III,  comparando una etapa de planificación y operaciones para un mismo año en evaluación. La planificación representa un supuesto de las emisiones antes de iniciar operaciones en un año de actividades del proyecto."
                  placement="top-start"
                >
                  <IconButton
                    aria-label="delete"
                    color="success"
                    style={{ marginTop: '27px' }}
                  >
                    <Help style={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardHeader>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <div style={{ minHeight: '200px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={planeacionOptions}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <div style={{ minHeight: '200px' }}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'chart'}
                    options={implementacionOptions}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </CardHeader>
        </Card>
      </GridItem>
    </CardBody>
  )
}

export default Alcance
