import React, { useEffect, useState } from 'react'
import { Mail } from '@material-ui/icons/'
import Button from 'components/CustomButtons'
import { Help } from '@material-ui/icons/'
import ProyectoService from 'services/proyecto/index'
import {
  Backdrop,
  Modal,
  Tooltip,
  IconButton,
  Fade,
  FormControl,
  makeStyles,
  Card,
  CardHeader,
  Divider,
  ListItem,
  List,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Grid
} from '@material-ui/core'
import ButtonItem from '@material-ui/core/Button'

import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/index'
import { useToasts } from 'react-toast-notifications'

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

const Invitacion = ({ fase, anio, token, registro }) => {
  const classes = useStyles()
  const { addToast } = useToasts()

  const [open, setOpen] = useState(false)
  const [emailContacto, setEmailContacto] = useState('')
  const [name, setName] = useState('')

  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])
  const [block, setBlock] = useState(false)
  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const loadData = () => {
    const data = registro.map((p) => {
      return {
        id: p.id,
        name: p.codigo
      }
    })
    setRight([])
    setLeft(data)
    setName('')
    setEmailContacto('')
  }

  useEffect(() => {
    try {
      loadData()
    } catch (e) {
      console.log(e)
    }
  }, [registro])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const numberOfChecked = (items) => intersection(checked, items).length

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected'
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionados`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto'
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${
            value ? value.id : 'sds'
          }-label`

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`Fuente o ratio ${value ? value.name : ''}`}
              />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )

  const handleSubmit = async () => {
    setBlock(true)
    try {
      if (emailContacto === '' || name === '') {
        addToast('Todos los campos son obligatorios', {
          appearance: 'error',
          autoDismiss: true
        })
        setBlock(false)
        return
      }
      if (right.length === 0) {
        addToast('Seleccione al menos una fuente o ratio', {
          appearance: 'error',
          autoDismiss: true
        })
        setBlock(false)
        return
      }
      // validate email
      if (emailContacto.indexOf('@') === -1) {
        addToast('El email no es valido', {
          appearance: 'error',
          autoDismiss: true
        })
        setBlock(false)
        return
      }
      handleClose()

      const idreg = right.map((p) => p.id)

      const data = {
        name: name,
        fase: fase.id,
        anio: anio,
        email: emailContacto,
        ids_registro: idreg
      }
      const resp = await ProyectoService.sendInvitation(data, token)
      if (resp.status === 200 || resp.status === 201) {
        addToast('Correo enviado correctamente', {
          appearance: 'success',
          autoDismiss: true
        })
        setBlock(false)
        handleClose()
      } else {
        handleClose()
        addToast('Error al enviar el correo', {
          appearance: 'error'
        })
        setBlock(false)
      }
    } catch (err) {
      handleClose()
      setBlock(false)
      addToast('Error al enviar el correo', { appearance: 'error' })
    }
  }
  const handleOpen = () => {
    setOpen(true)
    setBlock(false)
    loadData()
  }
  const handleClose = () => setOpen(false)
  return (
    <div>
      <Button type="button" color="transparent" onClick={() => handleOpen()}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <Mail />
        </IconButton>
        Invitación
      </Button>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <GridItem xs={12} sm={12} md={12}>
              <h6>
                Enviar correo de notificación{' '}
                <Tooltip title={'Con este componente puede solicitar información a personas externas relacionadas con la administración del proyecto. Debe tomar en cuenta el código de los factores o ratios de CO2e previo al envío del formulario a las partes involucradas.'} placement="top-start">
                  <Help style={{ cursor: 'help' }} />
                </Tooltip>
              </h6>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth>
                  <CustomInput
                    defaultValue=""
                    value={name}
                    required={true}
                    onChange={(e) => setName(e.target.value)}
                    labelText="Nombre de la persona"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <CustomInput
                    defaultValue=""
                    value={emailContacto}
                    type="email"
                    required={true}
                    onChange={(e) => setEmailContacto(e.target.value)}
                    labelText="Email a notificar"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>

            <Grid
              container
              spacing={2}
              style={{ margin: '10px 0' }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>{customList('Seleccionar Todos', left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <ButtonItem
                    sx={{ my: 0.1 }}
                    variant="outlined"
                    size="small"
                    color="success"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </ButtonItem>
                  <ButtonItem
                    sx={{ my: 0.1 }}
                    variant="outlined"
                    size="small"
                    style={{ marginTop: '10px ' }}
                    color="success"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </ButtonItem>
                </Grid>
              </Grid>
              <Grid item>{customList('Eliminar Todos', right)}</Grid>
            </Grid>
            <GridItem
              sm={12}
              md={12}
              style={{ margin: '15px auto', textAlign: 'center' }}
            >
              <Button
                disabled={block}
                onClick={() => handleSubmit()}
                color="success"
              >
                Enviar
              </Button>
            </GridItem>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
export default Invitacion
