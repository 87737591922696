import React, { useEffect, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { useLocation } from 'react-router'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/Header/index.js'
import CardBody from 'components/Card/Body/index.js'
import { useToasts } from 'react-toast-notifications'
import ProyectoService from '../../../services/proyecto'
import { RemoveRedEye, Edit } from '@material-ui/icons/'
import {
  CircularProgress,
  makeStyles,
  TableContainer,
  Backdrop,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  Tooltip,
  FormControl
} from '@material-ui/core'

import CardFooter from 'components/Card/Footer/index'
import withAccessGranted from 'components/withAuth/index'
import userAccess from 'userAccess'
import { ArrowBackIos } from '@material-ui/icons/index'
import moment from 'moment/moment'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

const RegistroItem = ({ history, token }) => {
  const { addToast } = useToasts()
  const classes = useStyles()
  const [fase, setFase] = useState(2)
  const [anioSelected, setAnioSelected] = useState()
  const [proyecto, setProyecto] = useState({})
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [fases, setFases] = useState([])

  const handleEdit = (etapa) => {
    const { id } = location.state
    history.push({
      pathname: '/referencia-data',
      state: { id: id, fase: fase, etapa: etapa, anio: anioSelected }
    })
  }

  const handleHuella = () => {
    const { id } = location.state
    // if (fases.length > 0) {
    history.push({
      pathname: '/huella-referencia',
      state: { id: id, fase: fase, anio: anioSelected }
    })
    //}
  }

  const loadProyectoRegistro = async (id, fase) => {
    try {
      const res = await ProyectoService.getProyectoReferencia(id, token)
      setProyecto(res.data)
      const { anio } = res.data
      const fases = res.data.fases.map((p) => {
        return {
          ...p,
          total: parseFloat(p.total_emisiones.toFixed(2)).toLocaleString('en')
        }
      })
      setFases(fases)
      setAnioSelected(anio[0])
      setLoading(false)
    } catch (err) {
      setLoading(false)
      addToast('Error en el proceso', { appearance: 'error' })
    }
  }

  useEffect(async () => {
    setLoading(true)
    const { id } = location.state
    loadProyectoRegistro(id, fase)
  }, [])

  const { tipo_proyecto, pais, nombre, anio, ultimo_registro } = proyecto

  return (
    <GridContainer>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GridItem xs={12} sm={12} md={12}>
        <CardHeader color="success">
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <h4>Tipo de proyecto</h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                {tipo_proyecto && (
                  <InputLabel>{tipo_proyecto.nombre}</InputLabel>
                )}
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl fullWidth>
                {pais && <InputLabel id="label_pais">{pais.nombre}</InputLabel>}
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="label_anio">
                  Año de inicio: {anioSelected}
                </InputLabel>
              </FormControl>
            </GridItem>
          </GridContainer>
        </CardHeader>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <GridItem xs={12} sm={12} md={12}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.goBack()
                }}
              >
                <IconButton aria-label="atras" size="small">
                  <ArrowBackIos fontSize="inherit" />
                </IconButton>
                <label> Atras</label>
              </div>
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                {nombre && <h4>{nombre}</h4>}
                <p>
                  {' '}
                  Última actualización:{' '}
                  {moment(ultimo_registro).format('DD-MM-yyyy  (HH:MM:ss)')}
                </p>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Fase</TableCell>
                      <TableCell component="th" align="center">
                        Actividad de las emisiones
                      </TableCell>
                      <TableCell component="th" align="center">
                        Origen de las emisiones
                      </TableCell>
                      <TableCell component="th" align="center">
                        Clasificación de las emisiones
                      </TableCell>
                      <TableCell component="th" align="center">
                        Datos de entrada
                      </TableCell>
                      <TableCell component="th" align="center">
                        Alcance de fuente de GEI
                      </TableCell>
                      <TableCell component="th" align="center">
                        Emisión actual (tCO<small>2e</small>)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {fases && (
                    <TableBody>
                      {fases.map((row) => (
                        <TableRow key={`fases_id_${row.id}`}>
                          <TableCell component="td" scope="row">
                            <div style={{ whiteSpace: 'nowrap' }}>
                              <IconButton
                                size="small"
                                type="button"
                                color="default"
                                onClick={() => handleEdit(row.id)}
                              >
                                <Edit style={{ fill: '#15bd85' }} />
                              </IconButton>

                              {row.nombre}
                              {row.obligatorio && (
                                <span
                                  style={{ color: '#15BD85', fontSize: '14px' }}
                                >
                                  *
                                </span>
                              )}
                            </div>
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.familia && row.familia.nombre}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.origen_emision && row.origen_emision.nombre}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.calificacion && row.calificacion.nombre}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {row.datos_recomplilar}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            Alcance {row.alcance}
                          </TableCell>
                          <TableCell component="td" align="right">
                            <Tooltip title={row.total_emisiones}>
                              <p>{row.total ? row.total : 0}</p>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </GridItem>
          </CardBody>

          <CardFooter>
            <div
              style={{ cursor: 'pointer', width: '100%', textAlign: 'right' }}
              onClick={() => {
                handleHuella()
              }}
            >
              + Ver huella comparativa
              <IconButton
                color="success"
                aria-label="upload picture"
                component="span"
              >
                <RemoveRedEye />
              </IconButton>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
export default withAccessGranted(RegistroItem, [
  userAccess.ADMIN.ACCESS_LEVEL,
  userAccess.REGISTRO.ACCESS_LEVEL
])
