import { drawerWidth, transition, container } from 'assets/jss/style.js'

const appStyle = (theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh'
  },
  title: {
    fontFamily: 'Gazpacho-Black',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '21px',
    paddingTop: '20px',
    lineHeight: '25px',
    marginLeft: '15px',
    color: '#454545'
  },
  subtitle: {
    width: '90%',
    fontSize: '12px',
    margin: '0 15px',
    marginTop: '0px',
    marginBottom: '20px'
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch'
  },
  mainPanelfull: {
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch'
  },
  content: {
    marginTop: '70px',
    padding: '30px 15px',
    backgroundColor: '#F9F9F9',
    minHeight: 'calc(100vh - 123px)'
  },
  container,
  map: {
    marginTop: '70px'
  }
})

export default appStyle
