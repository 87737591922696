const Style = {
  info: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '16px',
    padding: '10px 0',
    color: '#454545',
    '& span': {
      fontWeight: '200'
    }
  },
  green: {
    color: '#15BD85'
  },
  ratio: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    marginTop: '20px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#999999'
  },
  dowload: {
    width: '100%',
    textAlign: 'right',
    float: 'right'
  },
  foother: {
    width: '100%',
    marginTop: '10px',
    fontSize: '14px',
    textAlign: 'center'
  }
}

export default Style
