import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor
} from 'assets/jss/style.js'

const headerStyle = theme => ({
  appBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: '1029',
    color: grayColor[7],
    border: '0',
    borderRadius: '3px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block'
  },
  img: {
    margin: '0 30px',
    width: '27px',
    height: '27px',
    cursor: 'pointer'
  },
  separator: {
    height: '32px',
    width: '1px',
    backgroundColor: '#F0F0F0'
  },
  container: {
    ...container,
    minHeight: '50px'
  },
  flex: {
    flex: 1,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  title: {
    ...defaultFont,
    letterSpacing: 'unset',
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    textAling: 'left',
    margin: '0',
    fontFamily: 'Gazpacho-Black',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '21px',
    lineHeight: '25px',
    color: '##454545',
    '&:hover,&:focus': {
      background: 'transparent'
    }
  },
  appResponsive: {
    top: '8px'
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  }
})

export default headerStyle
