import API from '../Api'

class DashboardService {
  static emisionesAlcance(id, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/emisiones_alcanse/?proyecto=${id}&anio=${anio}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static emisionesFases(id, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/emisiones_fase/?proyecto=${id}&anio=${anio}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
  static emisionesFasesAlcance(id, anio, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/emisiones_fase_alcance/?proyecto=${id}&anio=${anio}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static emisionesRefenciaAlcance(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/emisiones_referencia_alcanse/?proyecto=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static emisionesRefenciaFase(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/emisiones_referencia_fase/?proyecto=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static emisionesReferenciaFasesAlcance(id, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/emisiones_referencia_fase_alcance/?proyecto=${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static emisionesConsolidada(anio, proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/acumunado_fase_etapa/?anio=${anio}&proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static emisionesFaseTipo(anio, proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/acumunado_fase_tipo/?anio=${anio}&proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static getEmisionesActividad(proyecto, anio, token) {
    return API({
      method: 'GET',
      url: `api/v1/huella/emisiones_actividad/?proyecto=${proyecto}&anio=${anio}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static getEmisionesReferemciaActividad(proyecto, token) {
    return API({
      method: 'GET',
      url: `api/v1/huella/emisiones_referencia_actividad/?proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static getEmisionesReferemciaActividadProyectada(proyecto, token) {
    return API({
      method: 'GET',
      url: `api/v1/huella/emisiones_referencia_actividad_proyectada/?proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static getEmisionesReferenciaProyectada(proyecto, token) {
    return API({
      method: 'GET',
      url: `api/v1/huella/emisiones_referencia_proyectada/?proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static acumunadoAmbito(anio, proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/acumunado_ambito/?anio=${anio}&proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static proyectoAdicionalidad(anio, proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/acumunado_adicionalidad/?anio=${anio}&proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  static proyectoMitigacion(anio, proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/acumunado_mitigacion/?anio=${anio}&proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  
  static getEmisionesReferenciaProyectadaGeneral(proyecto, token) {
    return API({
      method: 'GET',
      url: `/api/v1/huella/emisiones_referencia_proyectada_general/?proyecto=${proyecto}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export default DashboardService
