const ListFuentes = {
  container: {
    
    margin: '15px',
    '& img': {
      margin: '0 10px',
      cursor: 'help',
      width: '76px',
      height: '39px'
    }
  }
}

export default ListFuentes
